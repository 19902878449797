export const PFDuos = [
  {
    char: 'ruan-mei',
    app: 75.82,
    char_1: 'trailblazer-harmony',
    app_rate_1: '49.23%',
    avg_round_1: 32890,
    app_flat_1: 1763,
    char_2: 'Himeko',
    app_rate_2: '36.92%',
    avg_round_2: 33683,
    app_flat_2: 2015,
    char_3: 'Firefly',
    app_rate_3: '36.36%',
    avg_round_3: 29917,
    app_flat_3: 796,
    char_4: 'Lingsha',
    app_rate_4: '31.88%',
    avg_round_4: 35275,
    app_flat_4: 1132,
    char_5: 'Gallagher',
    app_rate_5: '31.13%',
    avg_round_5: 32034,
    app_flat_5: 1535,
    char_6: 'Herta',
    app_rate_6: '30.58%',
    avg_round_6: 33848,
    app_flat_6: 1770,
    char_7: 'tingyun-fugue',
    app_rate_7: '20.83%',
    avg_round_7: 38659,
    app_flat_7: 817,
    char_8: 'Rappa',
    app_rate_8: '18.86%',
    avg_round_8: 35460,
    app_flat_8: 1057,
    char_9: 'Aventurine',
    app_rate_9: '6.98%',
    avg_round_9: 32955,
    app_flat_9: 337,
    char_10: 'Huohuo',
    app_rate_10: '5.18%',
    avg_round_10: 31439,
    app_flat_10: 184,
    char_11: 'fu-xuan',
    app_rate_11: '4.32%',
    avg_round_11: 31613,
    app_flat_11: 198,
    char_12: 'Sunday',
    app_rate_12: '3.61%',
    avg_round_12: 34299,
    app_flat_12: 124,
    char_13: 'Jade',
    app_rate_13: '3.32%',
    avg_round_13: 38393,
    app_flat_13: 170,
    char_14: 'Luocha',
    app_rate_14: '2.99%',
    avg_round_14: 31556,
    app_flat_14: 135,
    char_15: 'Robin',
    app_rate_15: '2.77%',
    avg_round_15: 33748,
    app_flat_15: 119,
    char_16: 'Kafka',
    app_rate_16: '2.37%',
    avg_round_16: 28067,
    app_flat_16: 49,
    char_17: 'black-swan',
    app_rate_17: '2.12%',
    avg_round_17: 28018,
    app_flat_17: 38,
    char_18: 'jing-yuan',
    app_rate_18: '1.98%',
    avg_round_18: 34152,
    app_flat_18: 63,
    char_19: 'Acheron',
    app_rate_19: '1.62%',
    avg_round_19: 34840,
    app_flat_19: 37,
    char_20: 'Jiaoqiu',
    app_rate_20: '1.1%',
    avg_round_20: 35808,
    app_flat_20: 33,
    char_21: 'Argenti',
    app_rate_21: '0.85%',
    avg_round_21: 34508,
    app_flat_21: 37,
    char_22: 'Sparkle',
    app_rate_22: '0.82%',
    avg_round_22: 31862,
    app_flat_22: 25,
    char_23: 'Bronya',
    app_rate_23: '0.73%',
    avg_round_23: 31688,
    app_flat_23: 21,
    char_24: 'Pela',
    app_rate_24: '0.53%',
    avg_round_24: 33742,
    app_flat_24: 24,
    char_25: 'Jingliu',
    app_rate_25: '0.44%',
    avg_round_25: 27483,
    app_flat_25: 15,
    char_26: 'Tingyun',
    app_rate_26: '0.36%',
    avg_round_26: 32257,
    app_flat_26: 14,
    char_27: 'Yunli',
    app_rate_27: '0.31%',
    avg_round_27: 33764,
    app_flat_27: 10,
    char_28: 'topaz',
    app_rate_28: '0.21%',
    avg_round_28: 26824,
    app_flat_28: 9,
    char_29: 'Clara',
    app_rate_29: '0.19%',
    avg_round_29: 27940,
    app_flat_29: 10,
    char_30: 'imbibitor-lunae',
    app_rate_30: '0.19%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Herta',
    app: 62.33,
    char_1: 'Himeko',
    app_rate_1: '71.59%',
    avg_round_1: 32661,
    app_flat_1: 3563,
    char_2: 'Robin',
    app_rate_2: '49.36%',
    avg_round_2: 33836,
    app_flat_2: 2278,
    char_3: 'ruan-mei',
    app_rate_3: '37.2%',
    avg_round_3: 33848,
    app_flat_3: 1770,
    char_4: 'Aventurine',
    app_rate_4: '26.15%',
    avg_round_4: 33638,
    app_flat_4: 1225,
    char_5: 'Gallagher',
    app_rate_5: '20.49%',
    avg_round_5: 32935,
    app_flat_5: 1125,
    char_6: 'Jade',
    app_rate_6: '19.72%',
    avg_round_6: 38069,
    app_flat_6: 896,
    char_7: 'fu-xuan',
    app_rate_7: '13.54%',
    avg_round_7: 31726,
    app_flat_7: 552,
    char_8: 'Lingsha',
    app_rate_8: '12.6%',
    avg_round_8: 35925,
    app_flat_8: 635,
    char_9: 'Huohuo',
    app_rate_9: '10.17%',
    avg_round_9: 32255,
    app_flat_9: 457,
    char_10: 'tingyun-fugue',
    app_rate_10: '6.17%',
    avg_round_10: 38060,
    app_flat_10: 282,
    char_11: 'Sparkle',
    app_rate_11: '4.9%',
    avg_round_11: 31397,
    app_flat_11: 162,
    char_12: 'Sunday',
    app_rate_12: '4.6%',
    avg_round_12: 32442,
    app_flat_12: 188,
    char_13: 'Luocha',
    app_rate_13: '3.54%',
    avg_round_13: 31949,
    app_flat_13: 161,
    char_14: 'Feixiao',
    app_rate_14: '3.11%',
    avg_round_14: 32940,
    app_flat_14: 112,
    char_15: 'trailblazer-harmony',
    app_rate_15: '2.28%',
    avg_round_15: 31144,
    app_flat_15: 118,
    char_16: 'Yunli',
    app_rate_16: '1.52%',
    avg_round_16: 35251,
    app_flat_16: 57,
    char_17: 'Jiaoqiu',
    app_rate_17: '1.42%',
    avg_round_17: 32214,
    app_flat_17: 50,
    char_18: 'Pela',
    app_rate_18: '1.42%',
    avg_round_18: 28930,
    app_flat_18: 69,
    char_19: 'Clara',
    app_rate_19: '1.27%',
    avg_round_19: 31883,
    app_flat_19: 51,
    char_20: 'topaz',
    app_rate_20: '1.2%',
    avg_round_20: 27596,
    app_flat_20: 44,
    char_21: 'Firefly',
    app_rate_21: '1.12%',
    avg_round_21: 28462,
    app_flat_21: 25,
    char_22: 'Bronya',
    app_rate_22: '1.01%',
    avg_round_22: 31627,
    app_flat_22: 42,
    char_23: 'Acheron',
    app_rate_23: '0.93%',
    avg_round_23: 33646,
    app_flat_23: 26,
    char_24: 'Tingyun',
    app_rate_24: '0.59%',
    avg_round_24: 29888,
    app_flat_24: 26,
    char_25: 'Jingliu',
    app_rate_25: '0.46%',
    avg_round_25: 28410,
    app_flat_25: 14,
    char_26: 'Bailu',
    app_rate_26: '0.45%',
    avg_round_26: 29798,
    app_flat_26: 21,
    char_27: 'march-7th-swordmaster',
    app_rate_27: '0.41%',
    avg_round_27: 29419,
    app_flat_27: 17,
    char_28: 'Asta',
    app_rate_28: '0.41%',
    avg_round_28: 30905,
    app_flat_28: 21,
    char_29: 'Argenti',
    app_rate_29: '0.4%',
    avg_round_29: 33372,
    app_flat_29: 17,
    char_30: 'jing-yuan',
    app_rate_30: '0.4%',
    avg_round_30: 30938,
    app_flat_30: 13
  },
  {
    char: 'Robin',
    app: 58.0,
    char_1: 'Herta',
    app_rate_1: '53.04%',
    avg_round_1: 33836,
    app_flat_1: 2278,
    char_2: 'Aventurine',
    app_rate_2: '41.1%',
    avg_round_2: 34153,
    app_flat_2: 1492,
    char_3: 'Himeko',
    app_rate_3: '33.15%',
    avg_round_3: 32299,
    app_flat_3: 1502,
    char_4: 'Sunday',
    app_rate_4: '30.41%',
    avg_round_4: 35891,
    app_flat_4: 954,
    char_5: 'jing-yuan',
    app_rate_5: '23.13%',
    avg_round_5: 36011,
    app_flat_5: 736,
    char_6: 'Huohuo',
    app_rate_6: '17.98%',
    avg_round_6: 34167,
    app_flat_6: 624,
    char_7: 'Jade',
    app_rate_7: '16.03%',
    avg_round_7: 39150,
    app_flat_7: 604,
    char_8: 'Gallagher',
    app_rate_8: '10.33%',
    avg_round_8: 34152,
    app_flat_8: 458,
    char_9: 'fu-xuan',
    app_rate_9: '9.96%',
    avg_round_9: 32446,
    app_flat_9: 345,
    char_10: 'Feixiao',
    app_rate_10: '7.47%',
    avg_round_10: 31462,
    app_flat_10: 188,
    char_11: 'Yunli',
    app_rate_11: '7.11%',
    avg_round_11: 34352,
    app_flat_11: 256,
    char_12: 'Lingsha',
    app_rate_12: '7.1%',
    avg_round_12: 36323,
    app_flat_12: 306,
    char_13: 'Luocha',
    app_rate_13: '4.39%',
    avg_round_13: 33948,
    app_flat_13: 145,
    char_14: 'Acheron',
    app_rate_14: '4.0%',
    avg_round_14: 33830,
    app_flat_14: 67,
    char_15: 'Clara',
    app_rate_15: '3.74%',
    avg_round_15: 32265,
    app_flat_15: 138,
    char_16: 'ruan-mei',
    app_rate_16: '3.62%',
    avg_round_16: 33748,
    app_flat_16: 119,
    char_17: 'topaz',
    app_rate_17: '3.32%',
    avg_round_17: 28264,
    app_flat_17: 75,
    char_18: 'Argenti',
    app_rate_18: '3.3%',
    avg_round_18: 39375,
    app_flat_18: 102,
    char_19: 'Sparkle',
    app_rate_19: '3.04%',
    avg_round_19: 32363,
    app_flat_19: 73,
    char_20: 'Tingyun',
    app_rate_20: '2.63%',
    avg_round_20: 33836,
    app_flat_20: 86,
    char_21: 'Kafka',
    app_rate_21: '2.45%',
    avg_round_21: 28800,
    app_flat_21: 36,
    char_22: 'black-swan',
    app_rate_22: '2.14%',
    avg_round_22: 28758,
    app_flat_22: 25,
    char_23: 'Jiaoqiu',
    app_rate_23: '2.07%',
    avg_round_23: 35888,
    app_flat_23: 33,
    char_24: 'march-7th-swordmaster',
    app_rate_24: '1.7%',
    avg_round_24: 29309,
    app_flat_24: 57,
    char_25: 'Bronya',
    app_rate_25: '1.23%',
    avg_round_25: 34025,
    app_flat_25: 34,
    char_26: 'Pela',
    app_rate_26: '0.92%',
    avg_round_26: 30180,
    app_flat_26: 18,
    char_27: 'Jingliu',
    app_rate_27: '0.51%',
    avg_round_27: 29032,
    app_flat_27: 10,
    char_28: 'tingyun-fugue',
    app_rate_28: '0.47%',
    avg_round_28: 31213,
    app_flat_28: 9,
    char_29: 'Bailu',
    app_rate_29: '0.44%',
    avg_round_29: 33156,
    app_flat_29: 11,
    char_30: 'Serval',
    app_rate_30: '0.43%',
    avg_round_30: 29728,
    app_flat_30: 16
  },
  {
    char: 'Himeko',
    app: 54.96,
    char_1: 'Herta',
    app_rate_1: '81.2%',
    avg_round_1: 32661,
    app_flat_1: 3563,
    char_2: 'ruan-mei',
    app_rate_2: '50.94%',
    avg_round_2: 33683,
    app_flat_2: 2015,
    char_3: 'Robin',
    app_rate_3: '34.99%',
    avg_round_3: 32299,
    app_flat_3: 1502,
    char_4: 'Gallagher',
    app_rate_4: '25.13%',
    avg_round_4: 32344,
    app_flat_4: 1195,
    char_5: 'Aventurine',
    app_rate_5: '19.48%',
    avg_round_5: 32091,
    app_flat_5: 838,
    char_6: 'tingyun-fugue',
    app_rate_6: '16.91%',
    avg_round_6: 37751,
    app_flat_6: 603,
    char_7: 'Lingsha',
    app_rate_7: '15.71%',
    avg_round_7: 35528,
    app_flat_7: 649,
    char_8: 'fu-xuan',
    app_rate_8: '10.95%',
    avg_round_8: 29998,
    app_flat_8: 413,
    char_9: 'trailblazer-harmony',
    app_rate_9: '8.82%',
    avg_round_9: 31740,
    app_flat_9: 303,
    char_10: 'Huohuo',
    app_rate_10: '7.65%',
    avg_round_10: 30319,
    app_flat_10: 310,
    char_11: 'Jade',
    app_rate_11: '6.21%',
    avg_round_11: 33357,
    app_flat_11: 244,
    char_12: 'Firefly',
    app_rate_12: '5.9%',
    avg_round_12: 29924,
    app_flat_12: 106,
    char_13: 'Luocha',
    app_rate_13: '3.02%',
    avg_round_13: 30792,
    app_flat_13: 126,
    char_14: 'Sparkle',
    app_rate_14: '2.99%',
    avg_round_14: 29699,
    app_flat_14: 99,
    char_15: 'Sunday',
    app_rate_15: '1.97%',
    avg_round_15: 30189,
    app_flat_15: 87,
    char_16: 'Pela',
    app_rate_16: '1.36%',
    avg_round_16: 28952,
    app_flat_16: 63,
    char_17: 'Jiaoqiu',
    app_rate_17: '1.32%',
    avg_round_17: 29565,
    app_flat_17: 46,
    char_18: 'topaz',
    app_rate_18: '0.77%',
    avg_round_18: 26545,
    app_flat_18: 25,
    char_19: 'Rappa',
    app_rate_19: '0.6%',
    avg_round_19: 33592,
    app_flat_19: 25,
    char_20: 'Asta',
    app_rate_20: '0.43%',
    avg_round_20: 30905,
    app_flat_20: 21,
    char_21: 'Acheron',
    app_rate_21: '0.42%',
    avg_round_21: 32607,
    app_flat_21: 11,
    char_22: 'Clara',
    app_rate_22: '0.39%',
    avg_round_22: 27618,
    app_flat_22: 16,
    char_23: 'Feixiao',
    app_rate_23: '0.36%',
    avg_round_23: 28646,
    app_flat_23: 13,
    char_24: 'Bronya',
    app_rate_24: '0.34%',
    avg_round_24: 28911,
    app_flat_24: 17,
    char_25: 'Bailu',
    app_rate_25: '0.34%',
    avg_round_25: 28624,
    app_flat_25: 17,
    char_26: 'Yunli',
    app_rate_26: '0.28%',
    avg_round_26: 29360,
    app_flat_26: 11,
    char_27: 'Gepard',
    app_rate_27: '0.28%',
    avg_round_27: 28243,
    app_flat_27: 13,
    char_28: 'Tingyun',
    app_rate_28: '0.19%',
    avg_round_28: 27185,
    app_flat_28: 8,
    char_29: 'Jingliu',
    app_rate_29: '0.19%',
    avg_round_29: 27130,
    app_flat_29: 8,
    char_30: 'march-7th-swordmaster',
    app_rate_30: '0.15%',
    avg_round_30: 27087,
    app_flat_30: 6
  },
  {
    char: 'Aventurine',
    app: 53.97,
    char_1: 'Robin',
    app_rate_1: '44.17%',
    avg_round_1: 34153,
    app_flat_1: 1492,
    char_2: 'Acheron',
    app_rate_2: '36.56%',
    avg_round_2: 34094,
    app_flat_2: 930,
    char_3: 'Herta',
    app_rate_3: '30.21%',
    avg_round_3: 33638,
    app_flat_3: 1225,
    char_4: 'Sunday',
    app_rate_4: '28.54%',
    avg_round_4: 34891,
    app_flat_4: 799,
    char_5: 'Jiaoqiu',
    app_rate_5: '20.67%',
    avg_round_5: 36363,
    app_flat_5: 519,
    char_6: 'Himeko',
    app_rate_6: '19.83%',
    avg_round_6: 32091,
    app_flat_6: 838,
    char_7: 'jing-yuan',
    app_rate_7: '18.71%',
    avg_round_7: 35016,
    app_flat_7: 563,
    char_8: 'Pela',
    app_rate_8: '12.6%',
    avg_round_8: 33840,
    app_flat_8: 417,
    char_9: 'Sparkle',
    app_rate_9: '11.75%',
    avg_round_9: 32540,
    app_flat_9: 170,
    char_10: 'Kafka',
    app_rate_10: '10.17%',
    avg_round_10: 32730,
    app_flat_10: 334,
    char_11: 'black-swan',
    app_rate_11: '10.17%',
    avg_round_11: 32767,
    app_flat_11: 318,
    char_12: 'Jade',
    app_rate_12: '10.07%',
    avg_round_12: 38607,
    app_flat_12: 350,
    char_13: 'ruan-mei',
    app_rate_13: '9.81%',
    avg_round_13: 32955,
    app_flat_13: 337,
    char_14: 'Feixiao',
    app_rate_14: '6.67%',
    avg_round_14: 31180,
    app_flat_14: 157,
    char_15: 'topaz',
    app_rate_15: '3.58%',
    avg_round_15: 28230,
    app_flat_15: 82,
    char_16: 'Tingyun',
    app_rate_16: '3.55%',
    avg_round_16: 33140,
    app_flat_16: 131,
    char_17: 'Yunli',
    app_rate_17: '3.11%',
    avg_round_17: 34341,
    app_flat_17: 112,
    char_18: 'Clara',
    app_rate_18: '2.87%',
    avg_round_18: 31382,
    app_flat_18: 104,
    char_19: 'trailblazer-harmony',
    app_rate_19: '2.55%',
    avg_round_19: 31790,
    app_flat_19: 82,
    char_20: 'Argenti',
    app_rate_20: '2.36%',
    avg_round_20: 36206,
    app_flat_20: 77,
    char_21: 'Bronya',
    app_rate_21: '2.36%',
    avg_round_21: 32138,
    app_flat_21: 39,
    char_22: 'Rappa',
    app_rate_22: '2.08%',
    avg_round_22: 32734,
    app_flat_22: 73,
    char_23: 'march-7th-swordmaster',
    app_rate_23: '1.4%',
    avg_round_23: 28122,
    app_flat_23: 41,
    char_24: 'silver-wolf',
    app_rate_24: '1.39%',
    avg_round_24: 28674,
    app_flat_24: 40,
    char_25: 'tingyun-fugue',
    app_rate_25: '1.14%',
    avg_round_25: 32610,
    app_flat_25: 36,
    char_26: 'Firefly',
    app_rate_26: '0.73%',
    avg_round_26: 26493,
    app_flat_26: 12,
    char_27: 'Welt',
    app_rate_27: '0.73%',
    avg_round_27: 27228,
    app_flat_27: 20,
    char_28: 'Guinaifen',
    app_rate_28: '0.46%',
    avg_round_28: 28472,
    app_flat_28: 19,
    char_29: 'Serval',
    app_rate_29: '0.43%',
    avg_round_29: 27525,
    app_flat_29: 16,
    char_30: 'Moze',
    app_rate_30: '0.35%',
    avg_round_30: 28749,
    app_flat_30: 7
  },
  {
    char: 'Acheron',
    app: 45.08,
    char_1: 'Jiaoqiu',
    app_rate_1: '48.32%',
    avg_round_1: 36198,
    app_flat_1: 977,
    char_2: 'Aventurine',
    app_rate_2: '43.77%',
    avg_round_2: 34094,
    app_flat_2: 930,
    char_3: 'Kafka',
    app_rate_3: '31.28%',
    avg_round_3: 32810,
    app_flat_3: 732,
    char_4: 'Pela',
    app_rate_4: '31.22%',
    avg_round_4: 33427,
    app_flat_4: 861,
    char_5: 'black-swan',
    app_rate_5: '31.05%',
    avg_round_5: 32817,
    app_flat_5: 729,
    char_6: 'fu-xuan',
    app_rate_6: '24.61%',
    avg_round_6: 32661,
    app_flat_6: 413,
    char_7: 'Sparkle',
    app_rate_7: '23.01%',
    avg_round_7: 31933,
    app_flat_7: 98,
    char_8: 'Sunday',
    app_rate_8: '13.51%',
    avg_round_8: 34588,
    app_flat_8: 196,
    char_9: 'Gallagher',
    app_rate_9: '10.44%',
    avg_round_9: 34236,
    app_flat_9: 261,
    char_10: 'Huohuo',
    app_rate_10: '6.91%',
    avg_round_10: 32920,
    app_flat_10: 126,
    char_11: 'Robin',
    app_rate_11: '5.15%',
    avg_round_11: 33830,
    app_flat_11: 67,
    char_12: 'silver-wolf',
    app_rate_12: '4.38%',
    avg_round_12: 28032,
    app_flat_12: 97,
    char_13: 'Luocha',
    app_rate_13: '4.36%',
    avg_round_13: 33436,
    app_flat_13: 91,
    char_14: 'Bronya',
    app_rate_14: '4.04%',
    avg_round_14: 29702,
    app_flat_14: 9,
    char_15: 'Gepard',
    app_rate_15: '2.81%',
    avg_round_15: 31748,
    app_flat_15: 62,
    char_16: 'ruan-mei',
    app_rate_16: '2.72%',
    avg_round_16: 34840,
    app_flat_16: 37,
    char_17: 'Lingsha',
    app_rate_17: '2.45%',
    avg_round_17: 35484,
    app_flat_17: 45,
    char_18: 'Welt',
    app_rate_18: '1.93%',
    avg_round_18: 28038,
    app_flat_18: 36,
    char_19: 'Guinaifen',
    app_rate_19: '1.32%',
    avg_round_19: 29056,
    app_flat_19: 45,
    char_20: 'Herta',
    app_rate_20: '1.28%',
    avg_round_20: 33646,
    app_flat_20: 26,
    char_21: 'tingyun-fugue',
    app_rate_21: '0.98%',
    avg_round_21: 31364,
    app_flat_21: 21,
    char_22: 'Bailu',
    app_rate_22: '0.66%',
    avg_round_22: 30491,
    app_flat_22: 7,
    char_23: 'Himeko',
    app_rate_23: '0.51%',
    avg_round_23: 32607,
    app_flat_23: 11,
    char_24: 'trailblazer-harmony',
    app_rate_24: '0.47%',
    avg_round_24: 29587,
    app_flat_24: 6,
    char_25: 'Yunli',
    app_rate_25: '0.43%',
    avg_round_25: 32286,
    app_flat_25: 14,
    char_26: 'Clara',
    app_rate_26: '0.23%',
    avg_round_26: 27846,
    app_flat_26: 7,
    char_27: 'Rappa',
    app_rate_27: '0.21%',
    avg_round_27: 28800,
    app_flat_27: 4,
    char_28: 'trailblazer-preservation',
    app_rate_28: '0.19%',
    avg_round_28: 31587,
    app_flat_28: 3,
    char_29: 'Sampo',
    app_rate_29: '0.19%',
    avg_round_29: 28486,
    app_flat_29: 7,
    char_30: 'Serval',
    app_rate_30: '0.17%',
    avg_round_30: 30180,
    app_flat_30: 4
  },
  {
    char: 'trailblazer-harmony',
    app: 41.09,
    char_1: 'ruan-mei',
    app_rate_1: '90.85%',
    avg_round_1: 32890,
    app_flat_1: 1763,
    char_2: 'Firefly',
    app_rate_2: '60.14%',
    avg_round_2: 29558,
    app_flat_2: 723,
    char_3: 'Lingsha',
    app_rate_3: '38.81%',
    avg_round_3: 33837,
    app_flat_3: 665,
    char_4: 'Gallagher',
    app_rate_4: '37.05%',
    avg_round_4: 30973,
    app_flat_4: 863,
    char_5: 'Rappa',
    app_rate_5: '32.01%',
    avg_round_5: 34958,
    app_flat_5: 979,
    char_6: 'tingyun-fugue',
    app_rate_6: '12.67%',
    avg_round_6: 39274,
    app_flat_6: 247,
    char_7: 'Himeko',
    app_rate_7: '11.8%',
    avg_round_7: 31740,
    app_flat_7: 303,
    char_8: 'Herta',
    app_rate_8: '3.46%',
    avg_round_8: 31144,
    app_flat_8: 118,
    char_9: 'Aventurine',
    app_rate_9: '3.35%',
    avg_round_9: 31790,
    app_flat_9: 82,
    char_10: 'Huohuo',
    app_rate_10: '1.97%',
    avg_round_10: 32054,
    app_flat_10: 36,
    char_11: 'Luocha',
    app_rate_11: '1.91%',
    avg_round_11: 31336,
    app_flat_11: 41,
    char_12: 'Sunday',
    app_rate_12: '0.91%',
    avg_round_12: 30149,
    app_flat_12: 21,
    char_13: 'Bronya',
    app_rate_13: '0.81%',
    avg_round_13: 29007,
    app_flat_13: 6,
    char_14: 'Acheron',
    app_rate_14: '0.52%',
    avg_round_14: 29587,
    app_flat_14: 6,
    char_15: 'fu-xuan',
    app_rate_15: '0.37%',
    avg_round_15: 28744,
    app_flat_15: 7,
    char_16: 'Jiaoqiu',
    app_rate_16: '0.33%',
    avg_round_16: 28688,
    app_flat_16: 9,
    char_17: 'Robin',
    app_rate_17: '0.31%',
    avg_round_17: 25980,
    app_flat_17: 2,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '0.27%',
    avg_round_18: 28160,
    app_flat_18: 1,
    char_19: 'Boothill',
    app_rate_19: '0.23%',
    avg_round_19: 33880,
    app_flat_19: 4,
    char_20: 'Kafka',
    app_rate_20: '0.23%',
    avg_round_20: 28180,
    app_flat_20: 4,
    char_21: 'Argenti',
    app_rate_21: '0.23%',
    avg_round_21: 31170,
    app_flat_21: 4,
    char_22: 'Tingyun',
    app_rate_22: '0.19%',
    avg_round_22: 28760,
    app_flat_22: 7,
    char_23: 'Sparkle',
    app_rate_23: '0.17%',
    avg_round_23: 24160,
    app_flat_23: 1,
    char_24: 'Pela',
    app_rate_24: '0.17%',
    avg_round_24: 29368,
    app_flat_24: 5,
    char_25: 'Yunli',
    app_rate_25: '0.17%',
    avg_round_25: 38930,
    app_flat_25: 4,
    char_26: 'Jade',
    app_rate_26: '0.17%',
    avg_round_26: 30760,
    app_flat_26: 5,
    char_27: 'Clara',
    app_rate_27: '0.14%',
    avg_round_27: 27170,
    app_flat_27: 4,
    char_28: 'jing-yuan',
    app_rate_28: '0.14%',
    avg_round_28: 31760,
    app_flat_28: 3,
    char_29: 'Bailu',
    app_rate_29: '0.08%',
    avg_round_29: 35640,
    app_flat_29: 3,
    char_30: 'Guinaifen',
    app_rate_30: '0.08%',
    avg_round_30: 28300,
    app_flat_30: 2
  },
  {
    char: 'Sunday',
    app: 38.05,
    char_1: 'jing-yuan',
    app_rate_1: '55.97%',
    avg_round_1: 35127,
    app_flat_1: 1155,
    char_2: 'Robin',
    app_rate_2: '46.36%',
    avg_round_2: 35891,
    app_flat_2: 954,
    char_3: 'Aventurine',
    app_rate_3: '40.47%',
    avg_round_3: 34891,
    app_flat_3: 799,
    char_4: 'Huohuo',
    app_rate_4: '22.25%',
    avg_round_4: 35591,
    app_flat_4: 474,
    char_5: 'Acheron',
    app_rate_5: '16.01%',
    avg_round_5: 34588,
    app_flat_5: 196,
    char_6: 'Tingyun',
    app_rate_6: '11.92%',
    avg_round_6: 35198,
    app_flat_6: 284,
    char_7: 'Jiaoqiu',
    app_rate_7: '10.69%',
    avg_round_7: 37434,
    app_flat_7: 140,
    char_8: 'Sparkle',
    app_rate_8: '10.26%',
    avg_round_8: 34001,
    app_flat_8: 205,
    char_9: 'fu-xuan',
    app_rate_9: '10.22%',
    avg_round_9: 33648,
    app_flat_9: 174,
    char_10: 'Argenti',
    app_rate_10: '9.66%',
    avg_round_10: 39790,
    app_flat_10: 230,
    char_11: 'Luocha',
    app_rate_11: '8.79%',
    avg_round_11: 33933,
    app_flat_11: 184,
    char_12: 'Herta',
    app_rate_12: '7.54%',
    avg_round_12: 32442,
    app_flat_12: 188,
    char_13: 'ruan-mei',
    app_rate_13: '7.2%',
    avg_round_13: 34299,
    app_flat_13: 124,
    char_14: 'Gallagher',
    app_rate_14: '6.82%',
    avg_round_14: 34878,
    app_flat_14: 145,
    char_15: 'Pela',
    app_rate_15: '4.45%',
    avg_round_15: 31143,
    app_flat_15: 52,
    char_16: 'Lingsha',
    app_rate_16: '3.91%',
    avg_round_16: 38197,
    app_flat_16: 83,
    char_17: 'Bronya',
    app_rate_17: '3.89%',
    avg_round_17: 35567,
    app_flat_17: 76,
    char_18: 'Jade',
    app_rate_18: '3.56%',
    avg_round_18: 38149,
    app_flat_18: 76,
    char_19: 'Yunli',
    app_rate_19: '3.56%',
    avg_round_19: 33593,
    app_flat_19: 90,
    char_20: 'Himeko',
    app_rate_20: '2.84%',
    avg_round_20: 30189,
    app_flat_20: 87,
    char_21: 'Clara',
    app_rate_21: '1.92%',
    avg_round_21: 29309,
    app_flat_21: 51,
    char_22: 'imbibitor-lunae',
    app_rate_22: '1.16%',
    avg_round_22: 24000,
    app_flat_22: 1,
    char_23: 'trailblazer-harmony',
    app_rate_23: '0.98%',
    avg_round_23: 30149,
    app_flat_23: 21,
    char_24: 'Kafka',
    app_rate_24: '0.94%',
    avg_round_24: 28976,
    app_flat_24: 15,
    char_25: 'tingyun-fugue',
    app_rate_25: '0.92%',
    avg_round_25: 39593,
    app_flat_25: 6,
    char_26: 'Serval',
    app_rate_26: '0.92%',
    avg_round_26: 31251,
    app_flat_26: 25,
    char_27: 'Jingliu',
    app_rate_27: '0.87%',
    avg_round_27: 27665,
    app_flat_27: 13,
    char_28: 'black-swan',
    app_rate_28: '0.65%',
    avg_round_28: 30936,
    app_flat_28: 5,
    char_29: 'Welt',
    app_rate_29: '0.58%',
    avg_round_29: 27440,
    app_flat_29: 11,
    char_30: 'march-7th-swordmaster',
    app_rate_30: '0.54%',
    avg_round_30: 29287,
    app_flat_30: 11
  },
  {
    char: 'Gallagher',
    app: 38.01,
    char_1: 'ruan-mei',
    app_rate_1: '62.1%',
    avg_round_1: 32034,
    app_flat_1: 1535,
    char_2: 'trailblazer-harmony',
    app_rate_2: '40.05%',
    avg_round_2: 30973,
    app_flat_2: 863,
    char_3: 'Himeko',
    app_rate_3: '36.33%',
    avg_round_3: 32344,
    app_flat_3: 1195,
    char_4: 'Herta',
    app_rate_4: '33.6%',
    avg_round_4: 32935,
    app_flat_4: 1125,
    char_5: 'Firefly',
    app_rate_5: '27.87%',
    avg_round_5: 27659,
    app_flat_5: 407,
    char_6: 'Robin',
    app_rate_6: '15.76%',
    avg_round_6: 34152,
    app_flat_6: 458,
    char_7: 'Rappa',
    app_rate_7: '15.51%',
    avg_round_7: 34399,
    app_flat_7: 504,
    char_8: 'Acheron',
    app_rate_8: '12.38%',
    avg_round_8: 34236,
    app_flat_8: 261,
    char_9: 'tingyun-fugue',
    app_rate_9: '10.99%',
    avg_round_9: 33654,
    app_flat_9: 302,
    char_10: 'Jiaoqiu',
    app_rate_10: '8.08%',
    avg_round_10: 35672,
    app_flat_10: 177,
    char_11: 'Sunday',
    app_rate_11: '6.83%',
    avg_round_11: 34878,
    app_flat_11: 145,
    char_12: 'Pela',
    app_rate_12: '4.88%',
    avg_round_12: 32898,
    app_flat_12: 156,
    char_13: 'Jade',
    app_rate_13: '4.23%',
    avg_round_13: 36305,
    app_flat_13: 134,
    char_14: 'Kafka',
    app_rate_14: '3.96%',
    avg_round_14: 32616,
    app_flat_14: 85,
    char_15: 'black-swan',
    app_rate_15: '3.67%',
    avg_round_15: 32676,
    app_flat_15: 80,
    char_16: 'Sparkle',
    app_rate_16: '3.13%',
    avg_round_16: 32351,
    app_flat_16: 40,
    char_17: 'jing-yuan',
    app_rate_17: '2.78%',
    avg_round_17: 36641,
    app_flat_17: 56,
    char_18: 'Bronya',
    app_rate_18: '1.61%',
    avg_round_18: 30531,
    app_flat_18: 26,
    char_19: 'Argenti',
    app_rate_19: '1.19%',
    avg_round_19: 37116,
    app_flat_19: 34,
    char_20: 'Tingyun',
    app_rate_20: '0.96%',
    avg_round_20: 32457,
    app_flat_20: 26,
    char_21: 'Feixiao',
    app_rate_21: '0.67%',
    avg_round_21: 29697,
    app_flat_21: 19,
    char_22: 'Clara',
    app_rate_22: '0.43%',
    avg_round_22: 27880,
    app_flat_22: 11,
    char_23: 'Yunli',
    app_rate_23: '0.38%',
    avg_round_23: 33058,
    app_flat_23: 9,
    char_24: 'silver-wolf',
    app_rate_24: '0.34%',
    avg_round_24: 27884,
    app_flat_24: 9,
    char_25: 'Guinaifen',
    app_rate_25: '0.31%',
    avg_round_25: 28071,
    app_flat_25: 9,
    char_26: 'Asta',
    app_rate_26: '0.31%',
    avg_round_26: 32430,
    app_flat_26: 8,
    char_27: 'march-7th-swordmaster',
    app_rate_27: '0.29%',
    avg_round_27: 26834,
    app_flat_27: 7,
    char_28: 'Jingliu',
    app_rate_28: '0.22%',
    avg_round_28: 26780,
    app_flat_28: 2,
    char_29: 'Welt',
    app_rate_29: '0.22%',
    avg_round_29: 30507,
    app_flat_29: 5,
    char_30: 'Serval',
    app_rate_30: '0.18%',
    avg_round_30: 28808,
    app_flat_30: 5
  },
  {
    char: 'Lingsha',
    app: 32.3,
    char_1: 'ruan-mei',
    app_rate_1: '74.84%',
    avg_round_1: 35275,
    app_flat_1: 1132,
    char_2: 'trailblazer-harmony',
    app_rate_2: '49.37%',
    avg_round_2: 33837,
    app_flat_2: 665,
    char_3: 'Firefly',
    app_rate_3: '41.94%',
    avg_round_3: 31923,
    app_flat_3: 347,
    char_4: 'Himeko',
    app_rate_4: '26.74%',
    avg_round_4: 35528,
    app_flat_4: 649,
    char_5: 'Herta',
    app_rate_5: '24.32%',
    avg_round_5: 35925,
    app_flat_5: 635,
    char_6: 'tingyun-fugue',
    app_rate_6: '23.74%',
    avg_round_6: 38368,
    app_flat_6: 345,
    char_7: 'Rappa',
    app_rate_7: '16.99%',
    avg_round_7: 36550,
    app_flat_7: 381,
    char_8: 'Robin',
    app_rate_8: '12.75%',
    avg_round_8: 36323,
    app_flat_8: 306,
    char_9: 'Jade',
    app_rate_9: '7.14%',
    avg_round_9: 39549,
    app_flat_9: 164,
    char_10: 'Sunday',
    app_rate_10: '4.61%',
    avg_round_10: 38197,
    app_flat_10: 83,
    char_11: 'Acheron',
    app_rate_11: '3.42%',
    avg_round_11: 35484,
    app_flat_11: 45,
    char_12: 'Sparkle',
    app_rate_12: '1.87%',
    avg_round_12: 34123,
    app_flat_12: 31,
    char_13: 'Jiaoqiu',
    app_rate_13: '1.63%',
    avg_round_13: 37944,
    app_flat_13: 29,
    char_14: 'black-swan',
    app_rate_14: '1.58%',
    avg_round_14: 33031,
    app_flat_14: 22,
    char_15: 'Kafka',
    app_rate_15: '1.48%',
    avg_round_15: 31938,
    app_flat_15: 20,
    char_16: 'Pela',
    app_rate_16: '1.21%',
    avg_round_16: 34244,
    app_flat_16: 28,
    char_17: 'Bronya',
    app_rate_17: '0.97%',
    avg_round_17: 39400,
    app_flat_17: 14,
    char_18: 'Feixiao',
    app_rate_18: '0.71%',
    avg_round_18: 29592,
    app_flat_18: 9,
    char_19: 'Yunli',
    app_rate_19: '0.61%',
    avg_round_19: 29256,
    app_flat_19: 9,
    char_20: 'jing-yuan',
    app_rate_20: '0.55%',
    avg_round_20: 37987,
    app_flat_20: 10,
    char_21: 'topaz',
    app_rate_21: '0.53%',
    avg_round_21: 29091,
    app_flat_21: 7,
    char_22: 'Tingyun',
    app_rate_22: '0.47%',
    avg_round_22: 35100,
    app_flat_22: 8,
    char_23: 'Clara',
    app_rate_23: '0.45%',
    avg_round_23: 30055,
    app_flat_23: 13,
    char_24: 'march-7th-swordmaster',
    app_rate_24: '0.37%',
    avg_round_24: 28309,
    app_flat_24: 7,
    char_25: 'Argenti',
    app_rate_25: '0.34%',
    avg_round_25: 33768,
    app_flat_25: 10,
    char_26: 'Jingliu',
    app_rate_26: '0.32%',
    avg_round_26: 33470,
    app_flat_26: 8,
    char_27: 'Asta',
    app_rate_27: '0.16%',
    avg_round_27: 33267,
    app_flat_27: 3,
    char_28: 'silver-wolf',
    app_rate_28: '0.13%',
    avg_round_28: 31880,
    app_flat_28: 1,
    char_29: 'Gallagher',
    app_rate_29: '0.13%',
    avg_round_29: 28693,
    app_flat_29: 3,
    char_30: 'Boothill',
    app_rate_30: '0.08%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Firefly',
    app: 29.76,
    char_1: 'ruan-mei',
    app_rate_1: '92.62%',
    avg_round_1: 29917,
    app_flat_1: 796,
    char_2: 'trailblazer-harmony',
    app_rate_2: '83.02%',
    avg_round_2: 29558,
    app_flat_2: 723,
    char_3: 'Lingsha',
    app_rate_3: '45.51%',
    avg_round_3: 31923,
    app_flat_3: 347,
    char_4: 'Gallagher',
    app_rate_4: '35.59%',
    avg_round_4: 27659,
    app_flat_4: 407,
    char_5: 'tingyun-fugue',
    app_rate_5: '18.87%',
    avg_round_5: 32219,
    app_flat_5: 118,
    char_6: 'Himeko',
    app_rate_6: '10.89%',
    avg_round_6: 29924,
    app_flat_6: 106,
    char_7: 'Rappa',
    app_rate_7: '3.29%',
    avg_round_7: 31907,
    app_flat_7: 30,
    char_8: 'Herta',
    app_rate_8: '2.34%',
    avg_round_8: 28462,
    app_flat_8: 25,
    char_9: 'Aventurine',
    app_rate_9: '1.32%',
    avg_round_9: 26493,
    app_flat_9: 12,
    char_10: 'Huohuo',
    app_rate_10: '1.2%',
    avg_round_10: 24653,
    app_flat_10: 3,
    char_11: 'Bronya',
    app_rate_11: '1.11%',
    avg_round_11: 31400,
    app_flat_11: 1,
    char_12: 'Luocha',
    app_rate_12: '0.91%',
    avg_round_12: 24263,
    app_flat_12: 7,
    char_13: 'Robin',
    app_rate_13: '0.57%',
    avg_round_13: 25933,
    app_flat_13: 3,
    char_14: 'fu-xuan',
    app_rate_14: '0.49%',
    avg_round_14: 25152,
    app_flat_14: 5,
    char_15: 'Sunday',
    app_rate_15: '0.46%',
    avg_round_15: 23760,
    app_flat_15: 2,
    char_16: 'march-7th-swordmaster',
    app_rate_16: '0.23%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Acheron',
    app_rate_17: '0.2%',
    avg_round_17: 26760,
    app_flat_17: 1,
    char_18: 'Sparkle',
    app_rate_18: '0.17%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Jiaoqiu',
    app_rate_19: '0.14%',
    avg_round_19: 25560,
    app_flat_19: 2,
    char_20: 'Pela',
    app_rate_20: '0.11%',
    avg_round_20: 28640,
    app_flat_20: 2,
    char_21: 'Clara',
    app_rate_21: '0.11%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Yunli',
    app_rate_22: '0.11%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Asta',
    app_rate_23: '0.11%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Gepard',
    app_rate_24: '0.06%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Arlan',
    app_rate_25: '0.06%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Jade',
    app_rate_26: '0.06%',
    avg_round_26: 29960,
    app_flat_26: 1,
    char_27: 'Tingyun',
    app_rate_27: '0.03%',
    avg_round_27: 23920,
    app_flat_27: 1,
    char_28: 'silver-wolf',
    app_rate_28: '0.03%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Welt',
    app_rate_29: '0.03%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'trailblazer-preservation',
    app_rate_30: '0.03%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'fu-xuan',
    app: 23.74,
    char_1: 'Acheron',
    app_rate_1: '46.74%',
    avg_round_1: 32661,
    app_flat_1: 413,
    char_2: 'Herta',
    app_rate_2: '35.56%',
    avg_round_2: 31726,
    app_flat_2: 552,
    char_3: 'Himeko',
    app_rate_3: '25.34%',
    avg_round_3: 29998,
    app_flat_3: 413,
    char_4: 'Sparkle',
    app_rate_4: '25.16%',
    avg_round_4: 30875,
    app_flat_4: 116,
    char_5: 'Robin',
    app_rate_5: '24.34%',
    avg_round_5: 32446,
    app_flat_5: 345,
    char_6: 'Jiaoqiu',
    app_rate_6: '21.22%',
    avg_round_6: 35298,
    app_flat_6: 183,
    char_7: 'Pela',
    app_rate_7: '16.42%',
    avg_round_7: 32193,
    app_flat_7: 212,
    char_8: 'Sunday',
    app_rate_8: '16.38%',
    avg_round_8: 33648,
    app_flat_8: 174,
    char_9: 'black-swan',
    app_rate_9: '14.05%',
    avg_round_9: 32276,
    app_flat_9: 161,
    char_10: 'Kafka',
    app_rate_10: '14.01%',
    avg_round_10: 32491,
    app_flat_10: 152,
    char_11: 'ruan-mei',
    app_rate_11: '13.8%',
    avg_round_11: 31613,
    app_flat_11: 198,
    char_12: 'Jade',
    app_rate_12: '10.9%',
    avg_round_12: 35469,
    app_flat_12: 168,
    char_13: 'jing-yuan',
    app_rate_13: '8.67%',
    avg_round_13: 33113,
    app_flat_13: 104,
    char_14: 'silver-wolf',
    app_rate_14: '3.48%',
    avg_round_14: 26993,
    app_flat_14: 31,
    char_15: 'Bronya',
    app_rate_15: '3.44%',
    avg_round_15: 32013,
    app_flat_15: 18,
    char_16: 'Tingyun',
    app_rate_16: '3.41%',
    avg_round_16: 31178,
    app_flat_16: 45,
    char_17: 'Yunli',
    app_rate_17: '3.05%',
    avg_round_17: 32344,
    app_flat_17: 38,
    char_18: 'Argenti',
    app_rate_18: '2.44%',
    avg_round_18: 34960,
    app_flat_18: 33,
    char_19: 'Clara',
    app_rate_19: '1.97%',
    avg_round_19: 29011,
    app_flat_19: 19,
    char_20: 'tingyun-fugue',
    app_rate_20: '1.29%',
    avg_round_20: 28707,
    app_flat_20: 17,
    char_21: 'Feixiao',
    app_rate_21: '1.15%',
    avg_round_21: 30107,
    app_flat_21: 10,
    char_22: 'Welt',
    app_rate_22: '0.82%',
    avg_round_22: 27058,
    app_flat_22: 9,
    char_23: 'Guinaifen',
    app_rate_23: '0.65%',
    avg_round_23: 29313,
    app_flat_23: 11,
    char_24: 'trailblazer-harmony',
    app_rate_24: '0.65%',
    avg_round_24: 28744,
    app_flat_24: 7,
    char_25: 'Firefly',
    app_rate_25: '0.61%',
    avg_round_25: 25152,
    app_flat_25: 5,
    char_26: 'topaz',
    app_rate_26: '0.61%',
    avg_round_26: 26136,
    app_flat_26: 7,
    char_27: 'Jingliu',
    app_rate_27: '0.57%',
    avg_round_27: 24880,
    app_flat_27: 5,
    char_28: 'Rappa',
    app_rate_28: '0.57%',
    avg_round_28: 30048,
    app_flat_28: 5,
    char_29: 'Blade',
    app_rate_29: '0.5%',
    avg_round_29: 29787,
    app_flat_29: 3,
    char_30: 'Seele',
    app_rate_30: '0.5%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Jiaoqiu',
    app: 23.62,
    char_1: 'Acheron',
    app_rate_1: '92.22%',
    avg_round_1: 36198,
    app_flat_1: 977,
    char_2: 'Aventurine',
    app_rate_2: '47.23%',
    avg_round_2: 36363,
    app_flat_2: 519,
    char_3: 'Sparkle',
    app_rate_3: '26.55%',
    avg_round_3: 35344,
    app_flat_3: 55,
    char_4: 'Pela',
    app_rate_4: '26.33%',
    avg_round_4: 36097,
    app_flat_4: 533,
    char_5: 'fu-xuan',
    app_rate_5: '21.33%',
    avg_round_5: 35298,
    app_flat_5: 183,
    char_6: 'Sunday',
    app_rate_6: '17.22%',
    avg_round_6: 37434,
    app_flat_6: 140,
    char_7: 'Gallagher',
    app_rate_7: '13.0%',
    avg_round_7: 35672,
    app_flat_7: 177,
    char_8: 'black-swan',
    app_rate_8: '7.6%',
    avg_round_8: 35899,
    app_flat_8: 97,
    char_9: 'Kafka',
    app_rate_9: '7.24%',
    avg_round_9: 37556,
    app_flat_9: 87,
    char_10: 'Huohuo',
    app_rate_10: '5.22%',
    avg_round_10: 34769,
    app_flat_10: 53,
    char_11: 'Robin',
    app_rate_11: '5.08%',
    avg_round_11: 35888,
    app_flat_11: 33,
    char_12: 'Luocha',
    app_rate_12: '5.04%',
    avg_round_12: 34188,
    app_flat_12: 59,
    char_13: 'Herta',
    app_rate_13: '3.75%',
    avg_round_13: 32214,
    app_flat_13: 50,
    char_14: 'Bronya',
    app_rate_14: '3.64%',
    avg_round_14: 34220,
    app_flat_14: 6,
    char_15: 'ruan-mei',
    app_rate_15: '3.53%',
    avg_round_15: 35808,
    app_flat_15: 33,
    char_16: 'Himeko',
    app_rate_16: '3.06%',
    avg_round_16: 29565,
    app_flat_16: 46,
    char_17: 'Lingsha',
    app_rate_17: '2.23%',
    avg_round_17: 37944,
    app_flat_17: 29,
    char_18: 'Argenti',
    app_rate_18: '1.51%',
    avg_round_18: 33614,
    app_flat_18: 17,
    char_19: 'silver-wolf',
    app_rate_19: '1.4%',
    avg_round_19: 33636,
    app_flat_19: 22,
    char_20: 'Gepard',
    app_rate_20: '1.04%',
    avg_round_20: 34931,
    app_flat_20: 11,
    char_21: 'jing-yuan',
    app_rate_21: '0.97%',
    avg_round_21: 34240,
    app_flat_21: 6,
    char_22: 'tingyun-fugue',
    app_rate_22: '0.68%',
    avg_round_22: 33930,
    app_flat_22: 8,
    char_23: 'Welt',
    app_rate_23: '0.61%',
    avg_round_23: 35880,
    app_flat_23: 6,
    char_24: 'trailblazer-harmony',
    app_rate_24: '0.58%',
    avg_round_24: 28688,
    app_flat_24: 9,
    char_25: 'Jade',
    app_rate_25: '0.5%',
    avg_round_25: 35015,
    app_flat_25: 8,
    char_26: 'Guinaifen',
    app_rate_26: '0.32%',
    avg_round_26: 33794,
    app_flat_26: 7,
    char_27: 'Yunli',
    app_rate_27: '0.25%',
    avg_round_27: 33880,
    app_flat_27: 3,
    char_28: 'Rappa',
    app_rate_28: '0.22%',
    avg_round_28: 31520,
    app_flat_28: 5,
    char_29: 'Tingyun',
    app_rate_29: '0.18%',
    avg_round_29: 34960,
    app_flat_29: 2,
    char_30: 'Firefly',
    app_rate_30: '0.18%',
    avg_round_30: 25560,
    app_flat_30: 2
  },
  {
    char: 'Huohuo',
    app: 22.67,
    char_1: 'Robin',
    app_rate_1: '46.02%',
    avg_round_1: 34167,
    app_flat_1: 624,
    char_2: 'Sunday',
    app_rate_2: '37.35%',
    avg_round_2: 35591,
    app_flat_2: 474,
    char_3: 'Herta',
    app_rate_3: '27.97%',
    avg_round_3: 32255,
    app_flat_3: 457,
    char_4: 'jing-yuan',
    app_rate_4: '23.91%',
    avg_round_4: 35382,
    app_flat_4: 301,
    char_5: 'Himeko',
    app_rate_5: '18.54%',
    avg_round_5: 30319,
    app_flat_5: 310,
    char_6: 'ruan-mei',
    app_rate_6: '17.34%',
    avg_round_6: 31439,
    app_flat_6: 184,
    char_7: 'Kafka',
    app_rate_7: '15.84%',
    avg_round_7: 30917,
    app_flat_7: 113,
    char_8: 'black-swan',
    app_rate_8: '15.35%',
    avg_round_8: 31048,
    app_flat_8: 108,
    char_9: 'Acheron',
    app_rate_9: '13.74%',
    avg_round_9: 32920,
    app_flat_9: 126,
    char_10: 'Tingyun',
    app_rate_10: '12.95%',
    avg_round_10: 34861,
    app_flat_10: 182,
    char_11: 'Sparkle',
    app_rate_11: '12.73%',
    avg_round_11: 33529,
    app_flat_11: 139,
    char_12: 'Yunli',
    app_rate_12: '10.62%',
    avg_round_12: 33883,
    app_flat_12: 143,
    char_13: 'Jade',
    app_rate_13: '9.57%',
    avg_round_13: 36388,
    app_flat_13: 142,
    char_14: 'Argenti',
    app_rate_14: '8.63%',
    avg_round_14: 39362,
    app_flat_14: 133,
    char_15: 'Jiaoqiu',
    app_rate_15: '5.44%',
    avg_round_15: 34769,
    app_flat_15: 53,
    char_16: 'trailblazer-harmony',
    app_rate_16: '3.57%',
    avg_round_16: 32054,
    app_flat_16: 36,
    char_17: 'Rappa',
    app_rate_17: '2.89%',
    avg_round_17: 32543,
    app_flat_17: 40,
    char_18: 'Clara',
    app_rate_18: '2.82%',
    avg_round_18: 31029,
    app_flat_18: 42,
    char_19: 'Pela',
    app_rate_19: '2.14%',
    avg_round_19: 32326,
    app_flat_19: 29,
    char_20: 'Bronya',
    app_rate_20: '2.06%',
    avg_round_20: 32552,
    app_flat_20: 32,
    char_21: 'tingyun-fugue',
    app_rate_21: '1.73%',
    avg_round_21: 31631,
    app_flat_21: 17,
    char_22: 'Firefly',
    app_rate_22: '1.58%',
    avg_round_22: 24653,
    app_flat_22: 3,
    char_23: 'Jingliu',
    app_rate_23: '1.46%',
    avg_round_23: 26144,
    app_flat_23: 15,
    char_24: 'imbibitor-lunae',
    app_rate_24: '1.16%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Serval',
    app_rate_25: '1.05%',
    avg_round_25: 32753,
    app_flat_25: 12,
    char_26: 'topaz',
    app_rate_26: '0.64%',
    avg_round_26: 28040,
    app_flat_26: 8,
    char_27: 'march-7th-swordmaster',
    app_rate_27: '0.64%',
    avg_round_27: 29880,
    app_flat_27: 12,
    char_28: 'Feixiao',
    app_rate_28: '0.45%',
    avg_round_28: 30140,
    app_flat_28: 4,
    char_29: 'Blade',
    app_rate_29: '0.34%',
    avg_round_29: 27740,
    app_flat_29: 2,
    char_30: 'Asta',
    app_rate_30: '0.26%',
    avg_round_30: 28220,
    app_flat_30: 2
  },
  {
    char: 'jing-yuan',
    app: 21.79,
    char_1: 'Sunday',
    app_rate_1: '97.74%',
    avg_round_1: 35127,
    app_flat_1: 1155,
    char_2: 'Robin',
    app_rate_2: '61.58%',
    avg_round_2: 36011,
    app_flat_2: 736,
    char_3: 'Aventurine',
    app_rate_3: '46.35%',
    avg_round_3: 35016,
    app_flat_3: 563,
    char_4: 'Huohuo',
    app_rate_4: '24.87%',
    avg_round_4: 35382,
    app_flat_4: 301,
    char_5: 'Sparkle',
    app_rate_5: '12.96%',
    avg_round_5: 33065,
    app_flat_5: 165,
    char_6: 'Tingyun',
    app_rate_6: '11.05%',
    avg_round_6: 32561,
    app_flat_6: 132,
    char_7: 'Luocha',
    app_rate_7: '10.27%',
    avg_round_7: 34111,
    app_flat_7: 129,
    char_8: 'fu-xuan',
    app_rate_8: '9.45%',
    avg_round_8: 33113,
    app_flat_8: 104,
    char_9: 'ruan-mei',
    app_rate_9: '6.87%',
    avg_round_9: 34152,
    app_flat_9: 63,
    char_10: 'Gallagher',
    app_rate_10: '4.84%',
    avg_round_10: 36641,
    app_flat_10: 56,
    char_11: 'Bronya',
    app_rate_11: '3.75%',
    avg_round_11: 33615,
    app_flat_11: 39,
    char_12: 'Jade',
    app_rate_12: '2.11%',
    avg_round_12: 34333,
    app_flat_12: 25,
    char_13: 'Herta',
    app_rate_13: '1.13%',
    avg_round_13: 30938,
    app_flat_13: 13,
    char_14: 'Jiaoqiu',
    app_rate_14: '1.05%',
    avg_round_14: 34240,
    app_flat_14: 6,
    char_15: 'Lingsha',
    app_rate_15: '0.82%',
    avg_round_15: 37987,
    app_flat_15: 10,
    char_16: 'march-7th-swordmaster',
    app_rate_16: '0.62%',
    avg_round_16: 27156,
    app_flat_16: 9,
    char_17: 'Clara',
    app_rate_17: '0.55%',
    avg_round_17: 29823,
    app_flat_17: 7,
    char_18: 'Pela',
    app_rate_18: '0.55%',
    avg_round_18: 26970,
    app_flat_18: 4,
    char_19: 'Yunli',
    app_rate_19: '0.51%',
    avg_round_19: 32728,
    app_flat_19: 7,
    char_20: 'Argenti',
    app_rate_20: '0.39%',
    avg_round_20: 31700,
    app_flat_20: 6,
    char_21: 'Bailu',
    app_rate_21: '0.35%',
    avg_round_21: 30627,
    app_flat_21: 3,
    char_22: 'trailblazer-harmony',
    app_rate_22: '0.27%',
    avg_round_22: 31760,
    app_flat_22: 3,
    char_23: 'Acheron',
    app_rate_23: '0.23%',
    avg_round_23: 31600,
    app_flat_23: 1,
    char_24: 'Moze',
    app_rate_24: '0.2%',
    avg_round_24: 29820,
    app_flat_24: 2,
    char_25: 'Welt',
    app_rate_25: '0.2%',
    avg_round_25: 36160,
    app_flat_25: 1,
    char_26: 'Rappa',
    app_rate_26: '0.2%',
    avg_round_26: 25740,
    app_flat_26: 2,
    char_27: 'Hanya',
    app_rate_27: '0.16%',
    avg_round_27: 31860,
    app_flat_27: 4,
    char_28: 'Serval',
    app_rate_28: '0.12%',
    avg_round_28: 30760,
    app_flat_28: 2,
    char_29: 'Gepard',
    app_rate_29: '0.12%',
    avg_round_29: 36920,
    app_flat_29: 2,
    char_30: 'Himeko',
    app_rate_30: '0.12%',
    avg_round_30: 28580,
    app_flat_30: 2
  },
  {
    char: 'Sparkle',
    app: 19.94,
    char_1: 'Acheron',
    app_rate_1: '52.03%',
    avg_round_1: 31933,
    app_flat_1: 98,
    char_2: 'Aventurine',
    app_rate_2: '31.8%',
    avg_round_2: 32540,
    app_flat_2: 170,
    char_3: 'Jiaoqiu',
    app_rate_3: '31.46%',
    avg_round_3: 35344,
    app_flat_3: 55,
    char_4: 'fu-xuan',
    app_rate_4: '29.96%',
    avg_round_4: 30875,
    app_flat_4: 116,
    char_5: 'Sunday',
    app_rate_5: '19.59%',
    avg_round_5: 34001,
    app_flat_5: 205,
    char_6: 'Herta',
    app_rate_6: '15.32%',
    avg_round_6: 31397,
    app_flat_6: 162,
    char_7: 'Huohuo',
    app_rate_7: '14.47%',
    avg_round_7: 33529,
    app_flat_7: 139,
    char_8: 'jing-yuan',
    app_rate_8: '14.17%',
    avg_round_8: 33065,
    app_flat_8: 165,
    char_9: 'Pela',
    app_rate_9: '12.59%',
    avg_round_9: 27544,
    app_flat_9: 33,
    char_10: 'Robin',
    app_rate_10: '8.83%',
    avg_round_10: 32363,
    app_flat_10: 73,
    char_11: 'Himeko',
    app_rate_11: '8.24%',
    avg_round_11: 29699,
    app_flat_11: 99,
    char_12: 'Tingyun',
    app_rate_12: '7.38%',
    avg_round_12: 32546,
    app_flat_12: 82,
    char_13: 'Yunli',
    app_rate_13: '5.98%',
    avg_round_13: 32346,
    app_flat_13: 64,
    char_14: 'Gallagher',
    app_rate_14: '5.98%',
    avg_round_14: 32351,
    app_flat_14: 40,
    char_15: 'Argenti',
    app_rate_15: '5.98%',
    avg_round_15: 35187,
    app_flat_15: 80,
    char_16: 'Luocha',
    app_rate_16: '4.48%',
    avg_round_16: 31212,
    app_flat_16: 41,
    char_17: 'Jade',
    app_rate_17: '3.29%',
    avg_round_17: 35389,
    app_flat_17: 28,
    char_18: 'ruan-mei',
    app_rate_18: '3.12%',
    avg_round_18: 31862,
    app_flat_18: 25,
    char_19: 'Lingsha',
    app_rate_19: '3.03%',
    avg_round_19: 34123,
    app_flat_19: 31,
    char_20: 'Clara',
    app_rate_20: '2.94%',
    avg_round_20: 29458,
    app_flat_20: 29,
    char_21: 'black-swan',
    app_rate_21: '2.73%',
    avg_round_21: 28080,
    app_flat_21: 4,
    char_22: 'silver-wolf',
    app_rate_22: '2.65%',
    avg_round_22: 25340,
    app_flat_22: 4,
    char_23: 'imbibitor-lunae',
    app_rate_23: '2.22%',
    avg_round_23: 24800,
    app_flat_23: 1,
    char_24: 'Kafka',
    app_rate_24: '1.96%',
    avg_round_24: 29390,
    app_flat_24: 4,
    char_25: 'Bronya',
    app_rate_25: '1.66%',
    avg_round_25: 31305,
    app_flat_25: 16,
    char_26: 'Gepard',
    app_rate_26: '1.32%',
    avg_round_26: 28613,
    app_flat_26: 6,
    char_27: 'Welt',
    app_rate_27: '0.9%',
    avg_round_27: 25853,
    app_flat_27: 3,
    char_28: 'Seele',
    app_rate_28: '0.68%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'tingyun-fugue',
    app_rate_29: '0.6%',
    avg_round_29: 27800,
    app_flat_29: 2,
    char_30: 'Jingliu',
    app_rate_30: '0.6%',
    avg_round_30: 24950,
    app_flat_30: 4
  },
  {
    char: 'tingyun-fugue',
    app: 19.81,
    char_1: 'ruan-mei',
    app_rate_1: '79.73%',
    avg_round_1: 38659,
    app_flat_1: 817,
    char_2: 'Himeko',
    app_rate_2: '46.91%',
    avg_round_2: 37751,
    app_flat_2: 603,
    char_3: 'Lingsha',
    app_rate_3: '38.7%',
    avg_round_3: 38368,
    app_flat_3: 345,
    char_4: 'Firefly',
    app_rate_4: '28.35%',
    avg_round_4: 32219,
    app_flat_4: 118,
    char_5: 'trailblazer-harmony',
    app_rate_5: '26.29%',
    avg_round_5: 39274,
    app_flat_5: 247,
    char_6: 'Rappa',
    app_rate_6: '21.78%',
    avg_round_6: 39179,
    app_flat_6: 296,
    char_7: 'Gallagher',
    app_rate_7: '21.09%',
    avg_round_7: 33654,
    app_flat_7: 302,
    char_8: 'Herta',
    app_rate_8: '19.42%',
    avg_round_8: 38060,
    app_flat_8: 282,
    char_9: 'Aventurine',
    app_rate_9: '3.09%',
    avg_round_9: 32610,
    app_flat_9: 36,
    char_10: 'Acheron',
    app_rate_10: '2.23%',
    avg_round_10: 31364,
    app_flat_10: 21,
    char_11: 'Huohuo',
    app_rate_11: '1.98%',
    avg_round_11: 31631,
    app_flat_11: 17,
    char_12: 'Sunday',
    app_rate_12: '1.76%',
    avg_round_12: 39593,
    app_flat_12: 6,
    char_13: 'fu-xuan',
    app_rate_13: '1.55%',
    avg_round_13: 28707,
    app_flat_13: 17,
    char_14: 'Robin',
    app_rate_14: '1.37%',
    avg_round_14: 31213,
    app_flat_14: 9,
    char_15: 'Luocha',
    app_rate_15: '0.9%',
    avg_round_15: 32940,
    app_flat_15: 4,
    char_16: 'Jiaoqiu',
    app_rate_16: '0.82%',
    avg_round_16: 33930,
    app_flat_16: 8,
    char_17: 'Sparkle',
    app_rate_17: '0.6%',
    avg_round_17: 27800,
    app_flat_17: 2,
    char_18: 'Pela',
    app_rate_18: '0.6%',
    avg_round_18: 30227,
    app_flat_18: 10,
    char_19: 'Jade',
    app_rate_19: '0.47%',
    avg_round_19: 33280,
    app_flat_19: 4,
    char_20: 'Bronya',
    app_rate_20: '0.34%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Kafka',
    app_rate_21: '0.34%',
    avg_round_21: 29453,
    app_flat_21: 3,
    char_22: 'Argenti',
    app_rate_22: '0.26%',
    avg_round_22: 40000,
    app_flat_22: 1,
    char_23: 'Boothill',
    app_rate_23: '0.17%',
    avg_round_23: 40000,
    app_flat_23: 1,
    char_24: 'Tingyun',
    app_rate_24: '0.17%',
    avg_round_24: 25920,
    app_flat_24: 1,
    char_25: 'Yunli',
    app_rate_25: '0.13%',
    avg_round_25: 29760,
    app_flat_25: 1,
    char_26: 'Gepard',
    app_rate_26: '0.13%',
    avg_round_26: 26160,
    app_flat_26: 2,
    char_27: 'Asta',
    app_rate_27: '0.09%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Welt',
    app_rate_28: '0.09%',
    avg_round_28: 28440,
    app_flat_28: 1,
    char_29: 'Clara',
    app_rate_29: '0.09%',
    avg_round_29: 31240,
    app_flat_29: 2,
    char_30: 'Bailu',
    app_rate_30: '0.09%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Kafka',
    app: 17.11,
    char_1: 'Acheron',
    app_rate_1: '82.4%',
    avg_round_1: 32810,
    app_flat_1: 732,
    char_2: 'black-swan',
    app_rate_2: '76.53%',
    avg_round_2: 32156,
    app_flat_2: 609,
    char_3: 'Aventurine',
    app_rate_3: '32.07%',
    avg_round_3: 32730,
    app_flat_3: 334,
    char_4: 'Huohuo',
    app_rate_4: '20.98%',
    avg_round_4: 30917,
    app_flat_4: 113,
    char_5: 'fu-xuan',
    app_rate_5: '19.44%',
    avg_round_5: 32491,
    app_flat_5: 152,
    char_6: 'ruan-mei',
    app_rate_6: '10.49%',
    avg_round_6: 28067,
    app_flat_6: 49,
    char_7: 'Jiaoqiu',
    app_rate_7: '10.0%',
    avg_round_7: 37556,
    app_flat_7: 87,
    char_8: 'Gallagher',
    app_rate_8: '8.8%',
    avg_round_8: 32616,
    app_flat_8: 85,
    char_9: 'Robin',
    app_rate_9: '8.3%',
    avg_round_9: 28800,
    app_flat_9: 36,
    char_10: 'Luocha',
    app_rate_10: '7.01%',
    avg_round_10: 31727,
    app_flat_10: 49,
    char_11: 'Pela',
    app_rate_11: '4.28%',
    avg_round_11: 30115,
    app_flat_11: 60,
    char_12: 'Lingsha',
    app_rate_12: '2.78%',
    avg_round_12: 31938,
    app_flat_12: 20,
    char_13: 'Gepard',
    app_rate_13: '2.69%',
    avg_round_13: 32378,
    app_flat_13: 27,
    char_14: 'Sparkle',
    app_rate_14: '2.29%',
    avg_round_14: 29390,
    app_flat_14: 4,
    char_15: 'Sunday',
    app_rate_15: '2.09%',
    avg_round_15: 28976,
    app_flat_15: 15,
    char_16: 'Bailu',
    app_rate_16: '1.89%',
    avg_round_16: 31584,
    app_flat_16: 5,
    char_17: 'Welt',
    app_rate_17: '0.85%',
    avg_round_17: 28168,
    app_flat_17: 5,
    char_18: 'Tingyun',
    app_rate_18: '0.75%',
    avg_round_18: 25660,
    app_flat_18: 2,
    char_19: 'Bronya',
    app_rate_19: '0.65%',
    avg_round_19: 28267,
    app_flat_19: 3,
    char_20: 'Asta',
    app_rate_20: '0.55%',
    avg_round_20: 23720,
    app_flat_20: 1,
    char_21: 'trailblazer-harmony',
    app_rate_21: '0.55%',
    avg_round_21: 28180,
    app_flat_21: 4,
    char_22: 'Guinaifen',
    app_rate_22: '0.5%',
    avg_round_22: 25773,
    app_flat_22: 3,
    char_23: 'Herta',
    app_rate_23: '0.45%',
    avg_round_23: 24620,
    app_flat_23: 4,
    char_24: 'silver-wolf',
    app_rate_24: '0.4%',
    avg_round_24: 27547,
    app_flat_24: 3,
    char_25: 'tingyun-fugue',
    app_rate_25: '0.4%',
    avg_round_25: 29453,
    app_flat_25: 3,
    char_26: 'Sampo',
    app_rate_26: '0.35%',
    avg_round_26: 31240,
    app_flat_26: 2,
    char_27: 'Jade',
    app_rate_27: '0.3%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Serval',
    app_rate_28: '0.3%',
    avg_round_28: 27320,
    app_flat_28: 2,
    char_29: 'Yunli',
    app_rate_29: '0.25%',
    avg_round_29: 31330,
    app_flat_29: 4,
    char_30: 'Lynx',
    app_rate_30: '0.25%',
    avg_round_30: 27453,
    app_flat_30: 3
  },
  {
    char: 'black-swan',
    app: 16.77,
    char_1: 'Acheron',
    app_rate_1: '83.46%',
    avg_round_1: 32817,
    app_flat_1: 729,
    char_2: 'Kafka',
    app_rate_2: '78.08%',
    avg_round_2: 32156,
    app_flat_2: 609,
    char_3: 'Aventurine',
    app_rate_3: '32.72%',
    avg_round_3: 32767,
    app_flat_3: 318,
    char_4: 'Huohuo',
    app_rate_4: '20.75%',
    avg_round_4: 31048,
    app_flat_4: 108,
    char_5: 'fu-xuan',
    app_rate_5: '19.89%',
    avg_round_5: 32276,
    app_flat_5: 161,
    char_6: 'Jiaoqiu',
    app_rate_6: '10.71%',
    avg_round_6: 35899,
    app_flat_6: 97,
    char_7: 'ruan-mei',
    app_rate_7: '9.59%',
    avg_round_7: 28018,
    app_flat_7: 38,
    char_8: 'Gallagher',
    app_rate_8: '8.32%',
    avg_round_8: 32676,
    app_flat_8: 80,
    char_9: 'Robin',
    app_rate_9: '7.41%',
    avg_round_9: 28758,
    app_flat_9: 25,
    char_10: 'Luocha',
    app_rate_10: '6.7%',
    avg_round_10: 32045,
    app_flat_10: 48,
    char_11: 'Pela',
    app_rate_11: '5.38%',
    avg_round_11: 29999,
    app_flat_11: 73,
    char_12: 'Sparkle',
    app_rate_12: '3.25%',
    avg_round_12: 28080,
    app_flat_12: 4,
    char_13: 'Lingsha',
    app_rate_13: '3.04%',
    avg_round_13: 33031,
    app_flat_13: 22,
    char_14: 'Gepard',
    app_rate_14: '2.99%',
    avg_round_14: 32769,
    app_flat_14: 30,
    char_15: 'Bailu',
    app_rate_15: '1.52%',
    avg_round_15: 31584,
    app_flat_15: 5,
    char_16: 'Sunday',
    app_rate_16: '1.47%',
    avg_round_16: 30936,
    app_flat_16: 5,
    char_17: 'Bronya',
    app_rate_17: '0.76%',
    avg_round_17: 26480,
    app_flat_17: 1,
    char_18: 'Guinaifen',
    app_rate_18: '0.61%',
    avg_round_18: 28220,
    app_flat_18: 6,
    char_19: 'Tingyun',
    app_rate_19: '0.56%',
    avg_round_19: 25760,
    app_flat_19: 1,
    char_20: 'Asta',
    app_rate_20: '0.46%',
    avg_round_20: 32320,
    app_flat_20: 1,
    char_21: 'Welt',
    app_rate_21: '0.36%',
    avg_round_21: 26720,
    app_flat_21: 3,
    char_22: 'Herta',
    app_rate_22: '0.25%',
    avg_round_22: 28240,
    app_flat_22: 2,
    char_23: 'silver-wolf',
    app_rate_23: '0.2%',
    avg_round_23: 27160,
    app_flat_23: 3,
    char_24: 'march-7th',
    app_rate_24: '0.2%',
    avg_round_24: 32860,
    app_flat_24: 2,
    char_25: 'Sampo',
    app_rate_25: '0.15%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Lynx',
    app_rate_26: '0.15%',
    avg_round_26: 31100,
    app_flat_26: 2,
    char_27: 'Jade',
    app_rate_27: '0.1%',
    avg_round_27: 28200,
    app_flat_27: 1,
    char_28: 'Natasha',
    app_rate_28: '0.1%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'jing-yuan',
    app_rate_29: '0.1%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'trailblazer-preservation',
    app_rate_30: '0.1%',
    avg_round_30: 31360,
    app_flat_30: 1
  },
  {
    char: 'Rappa',
    app: 16.07,
    char_1: 'ruan-mei',
    app_rate_1: '88.99%',
    avg_round_1: 35460,
    app_flat_1: 1057,
    char_2: 'trailblazer-harmony',
    app_rate_2: '81.84%',
    avg_round_2: 34958,
    app_flat_2: 979,
    char_3: 'Gallagher',
    app_rate_3: '36.69%',
    avg_round_3: 34399,
    app_flat_3: 504,
    char_4: 'Lingsha',
    app_rate_4: '34.15%',
    avg_round_4: 36550,
    app_flat_4: 381,
    char_5: 'tingyun-fugue',
    app_rate_5: '26.84%',
    avg_round_5: 39179,
    app_flat_5: 296,
    char_6: 'Aventurine',
    app_rate_6: '6.99%',
    avg_round_6: 32734,
    app_flat_6: 73,
    char_7: 'Firefly',
    app_rate_7: '6.09%',
    avg_round_7: 31907,
    app_flat_7: 30,
    char_8: 'Huohuo',
    app_rate_8: '4.08%',
    avg_round_8: 32543,
    app_flat_8: 40,
    char_9: 'Luocha',
    app_rate_9: '3.92%',
    avg_round_9: 32560,
    app_flat_9: 38,
    char_10: 'Himeko',
    app_rate_10: '2.06%',
    avg_round_10: 33592,
    app_flat_10: 25,
    char_11: 'Sunday',
    app_rate_11: '1.11%',
    avg_round_11: 30483,
    app_flat_11: 14,
    char_12: 'fu-xuan',
    app_rate_12: '0.85%',
    avg_round_12: 30048,
    app_flat_12: 5,
    char_13: 'Robin',
    app_rate_13: '0.69%',
    avg_round_13: 31293,
    app_flat_13: 3,
    char_14: 'Acheron',
    app_rate_14: '0.58%',
    avg_round_14: 28800,
    app_flat_14: 4,
    char_15: 'Bronya',
    app_rate_15: '0.53%',
    avg_round_15: 29368,
    app_flat_15: 5,
    char_16: 'Tingyun',
    app_rate_16: '0.42%',
    avg_round_16: 28992,
    app_flat_16: 7,
    char_17: 'Yunli',
    app_rate_17: '0.42%',
    avg_round_17: 38573,
    app_flat_17: 5,
    char_18: 'Boothill',
    app_rate_18: '0.37%',
    avg_round_18: 40000,
    app_flat_18: 2,
    char_19: 'Herta',
    app_rate_19: '0.32%',
    avg_round_19: 27520,
    app_flat_19: 1,
    char_20: 'Jiaoqiu',
    app_rate_20: '0.32%',
    avg_round_20: 31520,
    app_flat_20: 5,
    char_21: 'Sparkle',
    app_rate_21: '0.32%',
    avg_round_21: 27580,
    app_flat_21: 2,
    char_22: 'jing-yuan',
    app_rate_22: '0.26%',
    avg_round_22: 25740,
    app_flat_22: 2,
    char_23: 'Argenti',
    app_rate_23: '0.26%',
    avg_round_23: 37280,
    app_flat_23: 3,
    char_24: 'Clara',
    app_rate_24: '0.21%',
    avg_round_24: 30240,
    app_flat_24: 2,
    char_25: 'Pela',
    app_rate_25: '0.21%',
    avg_round_25: 27260,
    app_flat_25: 2,
    char_26: 'Kafka',
    app_rate_26: '0.21%',
    avg_round_26: 29960,
    app_flat_26: 2,
    char_27: 'Jade',
    app_rate_27: '0.16%',
    avg_round_27: 40000,
    app_flat_27: 1,
    char_28: 'Bailu',
    app_rate_28: '0.16%',
    avg_round_28: 35640,
    app_flat_28: 3,
    char_29: 'Welt',
    app_rate_29: '0.16%',
    avg_round_29: 26620,
    app_flat_29: 2,
    char_30: 'march-7th-swordmaster',
    app_rate_30: '0.16%',
    avg_round_30: 28160,
    app_flat_30: 1
  },
  {
    char: 'Jade',
    app: 16.07,
    char_1: 'Herta',
    app_rate_1: '76.5%',
    avg_round_1: 38069,
    app_flat_1: 896,
    char_2: 'Robin',
    app_rate_2: '57.86%',
    avg_round_2: 39150,
    app_flat_2: 604,
    char_3: 'Aventurine',
    app_rate_3: '33.83%',
    avg_round_3: 38607,
    app_flat_3: 350,
    char_4: 'Himeko',
    app_rate_4: '21.23%',
    avg_round_4: 33357,
    app_flat_4: 244,
    char_5: 'fu-xuan',
    app_rate_5: '16.09%',
    avg_round_5: 35469,
    app_flat_5: 168,
    char_6: 'ruan-mei',
    app_rate_6: '15.67%',
    avg_round_6: 38393,
    app_flat_6: 170,
    char_7: 'Lingsha',
    app_rate_7: '14.35%',
    avg_round_7: 39549,
    app_flat_7: 164,
    char_8: 'Huohuo',
    app_rate_8: '13.5%',
    avg_round_8: 36388,
    app_flat_8: 142,
    char_9: 'Gallagher',
    app_rate_9: '10.01%',
    avg_round_9: 36305,
    app_flat_9: 134,
    char_10: 'Sunday',
    app_rate_10: '8.42%',
    avg_round_10: 38149,
    app_flat_10: 76,
    char_11: 'Luocha',
    app_rate_11: '4.76%',
    avg_round_11: 34678,
    app_flat_11: 49,
    char_12: 'Sparkle',
    app_rate_12: '4.08%',
    avg_round_12: 35389,
    app_flat_12: 28,
    char_13: 'Feixiao',
    app_rate_13: '3.76%',
    avg_round_13: 34578,
    app_flat_13: 11,
    char_14: 'Yunli',
    app_rate_14: '3.12%',
    avg_round_14: 38840,
    app_flat_14: 27,
    char_15: 'jing-yuan',
    app_rate_15: '2.86%',
    avg_round_15: 34333,
    app_flat_15: 25,
    char_16: 'Argenti',
    app_rate_16: '1.85%',
    avg_round_16: 38320,
    app_flat_16: 16,
    char_17: 'Bronya',
    app_rate_17: '1.64%',
    avg_round_17: 35570,
    app_flat_17: 16,
    char_18: 'topaz',
    app_rate_18: '1.54%',
    avg_round_18: 30833,
    app_flat_18: 6,
    char_19: 'Clara',
    app_rate_19: '1.48%',
    avg_round_19: 34413,
    app_flat_19: 15,
    char_20: 'Blade',
    app_rate_20: '1.38%',
    avg_round_20: 29150,
    app_flat_20: 6,
    char_21: 'Jiaoqiu',
    app_rate_21: '0.74%',
    avg_round_21: 35015,
    app_flat_21: 8,
    char_22: 'tingyun-fugue',
    app_rate_22: '0.58%',
    avg_round_22: 33280,
    app_flat_22: 4,
    char_23: 'Tingyun',
    app_rate_23: '0.53%',
    avg_round_23: 33366,
    app_flat_23: 7,
    char_24: 'Pela',
    app_rate_24: '0.48%',
    avg_round_24: 33430,
    app_flat_24: 4,
    char_25: 'Bailu',
    app_rate_25: '0.42%',
    avg_round_25: 33260,
    app_flat_25: 4,
    char_26: 'Acheron',
    app_rate_26: '0.42%',
    avg_round_26: 31627,
    app_flat_26: 3,
    char_27: 'trailblazer-harmony',
    app_rate_27: '0.42%',
    avg_round_27: 30760,
    app_flat_27: 5,
    char_28: 'Kafka',
    app_rate_28: '0.32%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Gepard',
    app_rate_29: '0.32%',
    avg_round_29: 31950,
    app_flat_29: 4,
    char_30: 'Lynx',
    app_rate_30: '0.26%',
    avg_round_30: 40000,
    app_flat_30: 2
  },
  {
    char: 'Pela',
    app: 15.32,
    char_1: 'Acheron',
    app_rate_1: '91.84%',
    avg_round_1: 33427,
    app_flat_1: 861,
    char_2: 'Aventurine',
    app_rate_2: '44.36%',
    avg_round_2: 33840,
    app_flat_2: 417,
    char_3: 'Jiaoqiu',
    app_rate_3: '40.59%',
    avg_round_3: 36097,
    app_flat_3: 533,
    char_4: 'fu-xuan',
    app_rate_4: '25.43%',
    avg_round_4: 32193,
    app_flat_4: 212,
    char_5: 'Sparkle',
    app_rate_5: '16.38%',
    avg_round_5: 27544,
    app_flat_5: 33,
    char_6: 'Gallagher',
    app_rate_6: '12.1%',
    avg_round_6: 32898,
    app_flat_6: 156,
    char_7: 'Sunday',
    app_rate_7: '11.05%',
    avg_round_7: 31143,
    app_flat_7: 52,
    char_8: 'black-swan',
    app_rate_8: '5.89%',
    avg_round_8: 29999,
    app_flat_8: 73,
    char_9: 'Herta',
    app_rate_9: '5.77%',
    avg_round_9: 28930,
    app_flat_9: 69,
    char_10: 'silver-wolf',
    app_rate_10: '5.16%',
    avg_round_10: 26960,
    app_flat_10: 61,
    char_11: 'Himeko',
    app_rate_11: '4.89%',
    avg_round_11: 28952,
    app_flat_11: 63,
    char_12: 'Kafka',
    app_rate_12: '4.78%',
    avg_round_12: 30115,
    app_flat_12: 60,
    char_13: 'Bronya',
    app_rate_13: '4.28%',
    avg_round_13: 25987,
    app_flat_13: 6,
    char_14: 'Robin',
    app_rate_14: '3.5%',
    avg_round_14: 30180,
    app_flat_14: 18,
    char_15: 'Gepard',
    app_rate_15: '3.22%',
    avg_round_15: 29565,
    app_flat_15: 31,
    char_16: 'Huohuo',
    app_rate_16: '3.16%',
    avg_round_16: 32326,
    app_flat_16: 29,
    char_17: 'Luocha',
    app_rate_17: '3.16%',
    avg_round_17: 32139,
    app_flat_17: 33,
    char_18: 'ruan-mei',
    app_rate_18: '2.61%',
    avg_round_18: 33742,
    app_flat_18: 24,
    char_19: 'Lingsha',
    app_rate_19: '2.55%',
    avg_round_19: 34244,
    app_flat_19: 28,
    char_20: 'Guinaifen',
    app_rate_20: '2.11%',
    avg_round_20: 28174,
    app_flat_20: 28,
    char_21: 'Welt',
    app_rate_21: '1.5%',
    avg_round_21: 28250,
    app_flat_21: 14,
    char_22: 'jing-yuan',
    app_rate_22: '0.78%',
    avg_round_22: 26970,
    app_flat_22: 4,
    char_23: 'tingyun-fugue',
    app_rate_23: '0.78%',
    avg_round_23: 30227,
    app_flat_23: 10,
    char_24: 'Jade',
    app_rate_24: '0.5%',
    avg_round_24: 33430,
    app_flat_24: 4,
    char_25: 'trailblazer-harmony',
    app_rate_25: '0.44%',
    avg_round_25: 29368,
    app_flat_25: 5,
    char_26: 'trailblazer-preservation',
    app_rate_26: '0.33%',
    avg_round_26: 31000,
    app_flat_26: 2,
    char_27: 'Jingliu',
    app_rate_27: '0.33%',
    avg_round_27: 25173,
    app_flat_27: 3,
    char_28: 'Bailu',
    app_rate_28: '0.33%',
    avg_round_28: 28147,
    app_flat_28: 3,
    char_29: 'Clara',
    app_rate_29: '0.28%',
    avg_round_29: 25293,
    app_flat_29: 3,
    char_30: 'Boothill',
    app_rate_30: '0.22%',
    avg_round_30: 29627,
    app_flat_30: 3
  },
  {
    char: 'Luocha',
    app: 9.06,
    char_1: 'Sunday',
    app_rate_1: '36.9%',
    avg_round_1: 33933,
    app_flat_1: 184,
    char_2: 'Robin',
    app_rate_2: '28.08%',
    avg_round_2: 33948,
    app_flat_2: 145,
    char_3: 'ruan-mei',
    app_rate_3: '24.98%',
    avg_round_3: 31556,
    app_flat_3: 135,
    char_4: 'jing-yuan',
    app_rate_4: '24.69%',
    avg_round_4: 34111,
    app_flat_4: 129,
    char_5: 'Herta',
    app_rate_5: '24.32%',
    avg_round_5: 31949,
    app_flat_5: 161,
    char_6: 'Acheron',
    app_rate_6: '21.69%',
    avg_round_6: 33436,
    app_flat_6: 91,
    char_7: 'Himeko',
    app_rate_7: '18.31%',
    avg_round_7: 30792,
    app_flat_7: 126,
    char_8: 'Kafka',
    app_rate_8: '13.24%',
    avg_round_8: 31727,
    app_flat_8: 49,
    char_9: 'Jiaoqiu',
    app_rate_9: '13.15%',
    avg_round_9: 34188,
    app_flat_9: 59,
    char_10: 'black-swan',
    app_rate_10: '12.39%',
    avg_round_10: 32045,
    app_flat_10: 48,
    char_11: 'Sparkle',
    app_rate_11: '9.86%',
    avg_round_11: 31212,
    app_flat_11: 41,
    char_12: 'Tingyun',
    app_rate_12: '8.92%',
    avg_round_12: 33733,
    app_flat_12: 51,
    char_13: 'trailblazer-harmony',
    app_rate_13: '8.64%',
    avg_round_13: 31336,
    app_flat_13: 41,
    char_14: 'Jade',
    app_rate_14: '8.45%',
    avg_round_14: 34678,
    app_flat_14: 49,
    char_15: 'Rappa',
    app_rate_15: '6.95%',
    avg_round_15: 32560,
    app_flat_15: 38,
    char_16: 'Argenti',
    app_rate_16: '6.85%',
    avg_round_16: 36685,
    app_flat_16: 34,
    char_17: 'Pela',
    app_rate_17: '5.35%',
    avg_round_17: 32139,
    app_flat_17: 33,
    char_18: 'Bronya',
    app_rate_18: '5.26%',
    avg_round_18: 31845,
    app_flat_18: 24,
    char_19: 'Yunli',
    app_rate_19: '4.04%',
    avg_round_19: 32797,
    app_flat_19: 24,
    char_20: 'Firefly',
    app_rate_20: '3.0%',
    avg_round_20: 24263,
    app_flat_20: 7,
    char_21: 'Clara',
    app_rate_21: '2.91%',
    avg_round_21: 31048,
    app_flat_21: 19,
    char_22: 'Jingliu',
    app_rate_22: '2.16%',
    avg_round_22: 25342,
    app_flat_22: 9,
    char_23: 'tingyun-fugue',
    app_rate_23: '1.97%',
    avg_round_23: 32940,
    app_flat_23: 4,
    char_24: 'imbibitor-lunae',
    app_rate_24: '1.69%',
    avg_round_24: 24000,
    app_flat_24: 1,
    char_25: 'Blade',
    app_rate_25: '1.13%',
    avg_round_25: 27460,
    app_flat_25: 4,
    char_26: 'Feixiao',
    app_rate_26: '0.94%',
    avg_round_26: 35400,
    app_flat_26: 3,
    char_27: 'Welt',
    app_rate_27: '0.85%',
    avg_round_27: 24400,
    app_flat_27: 3,
    char_28: 'march-7th-swordmaster',
    app_rate_28: '0.56%',
    avg_round_28: 24800,
    app_flat_28: 3,
    char_29: 'Serval',
    app_rate_29: '0.56%',
    avg_round_29: 24740,
    app_flat_29: 4,
    char_30: 'silver-wolf',
    app_rate_30: '0.47%',
    avg_round_30: 30570,
    app_flat_30: 4
  },
  {
    char: 'Tingyun',
    app: 7.9,
    char_1: 'Sunday',
    app_rate_1: '57.44%',
    avg_round_1: 35198,
    app_flat_1: 284,
    char_2: 'Huohuo',
    app_rate_2: '37.18%',
    avg_round_2: 34861,
    app_flat_2: 182,
    char_3: 'Argenti',
    app_rate_3: '33.62%',
    avg_round_3: 38825,
    app_flat_3: 195,
    char_4: 'jing-yuan',
    app_rate_4: '30.5%',
    avg_round_4: 32561,
    app_flat_4: 132,
    char_5: 'Aventurine',
    app_rate_5: '24.25%',
    avg_round_5: 33140,
    app_flat_5: 131,
    char_6: 'Robin',
    app_rate_6: '19.29%',
    avg_round_6: 33836,
    app_flat_6: 86,
    char_7: 'Yunli',
    app_rate_7: '18.86%',
    avg_round_7: 32419,
    app_flat_7: 90,
    char_8: 'Sparkle',
    app_rate_8: '18.64%',
    avg_round_8: 32546,
    app_flat_8: 82,
    char_9: 'fu-xuan',
    app_rate_9: '10.24%',
    avg_round_9: 31178,
    app_flat_9: 45,
    char_10: 'Luocha',
    app_rate_10: '10.24%',
    avg_round_10: 33733,
    app_flat_10: 51,
    char_11: 'Herta',
    app_rate_11: '4.63%',
    avg_round_11: 29888,
    app_flat_11: 26,
    char_12: 'Gallagher',
    app_rate_12: '4.63%',
    avg_round_12: 32457,
    app_flat_12: 26,
    char_13: 'Clara',
    app_rate_13: '3.88%',
    avg_round_13: 26636,
    app_flat_13: 19,
    char_14: 'Bronya',
    app_rate_14: '3.88%',
    avg_round_14: 34242,
    app_flat_14: 22,
    char_15: 'ruan-mei',
    app_rate_15: '3.45%',
    avg_round_15: 32257,
    app_flat_15: 14,
    char_16: 'imbibitor-lunae',
    app_rate_16: '2.26%',
    avg_round_16: 24400,
    app_flat_16: 2,
    char_17: 'Lingsha',
    app_rate_17: '1.94%',
    avg_round_17: 35100,
    app_flat_17: 8,
    char_18: 'Serval',
    app_rate_18: '1.83%',
    avg_round_18: 31132,
    app_flat_18: 10,
    char_19: 'Kafka',
    app_rate_19: '1.62%',
    avg_round_19: 25660,
    app_flat_19: 2,
    char_20: 'Himeko',
    app_rate_20: '1.29%',
    avg_round_20: 27185,
    app_flat_20: 8,
    char_21: 'black-swan',
    app_rate_21: '1.19%',
    avg_round_21: 25760,
    app_flat_21: 1,
    char_22: 'Jade',
    app_rate_22: '1.08%',
    avg_round_22: 33366,
    app_flat_22: 7,
    char_23: 'trailblazer-harmony',
    app_rate_23: '0.97%',
    avg_round_23: 28760,
    app_flat_23: 7,
    char_24: 'Bailu',
    app_rate_24: '0.97%',
    avg_round_24: 30600,
    app_flat_24: 3,
    char_25: 'Rappa',
    app_rate_25: '0.86%',
    avg_round_25: 28992,
    app_flat_25: 7,
    char_26: 'Seele',
    app_rate_26: '0.65%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Jiaoqiu',
    app_rate_27: '0.54%',
    avg_round_27: 34960,
    app_flat_27: 2,
    char_28: 'Lynx',
    app_rate_28: '0.54%',
    avg_round_28: 31680,
    app_flat_28: 3,
    char_29: 'Jingliu',
    app_rate_29: '0.43%',
    avg_round_29: 26600,
    app_flat_29: 2,
    char_30: 'march-7th-swordmaster',
    app_rate_30: '0.43%',
    avg_round_30: 28533,
    app_flat_30: 3
  },
  {
    char: 'Yunli',
    app: 5.9,
    char_1: 'Robin',
    app_rate_1: '69.88%',
    avg_round_1: 34352,
    app_flat_1: 256,
    char_2: 'Huohuo',
    app_rate_2: '40.78%',
    avg_round_2: 33883,
    app_flat_2: 143,
    char_3: 'Aventurine',
    app_rate_3: '28.39%',
    avg_round_3: 34341,
    app_flat_3: 112,
    char_4: 'Tingyun',
    app_rate_4: '25.22%',
    avg_round_4: 32419,
    app_flat_4: 90,
    char_5: 'Sunday',
    app_rate_5: '22.91%',
    avg_round_5: 33593,
    app_flat_5: 90,
    char_6: 'Sparkle',
    app_rate_6: '20.17%',
    avg_round_6: 32346,
    app_flat_6: 64,
    char_7: 'Herta',
    app_rate_7: '15.99%',
    avg_round_7: 35251,
    app_flat_7: 57,
    char_8: 'fu-xuan',
    app_rate_8: '12.25%',
    avg_round_8: 32344,
    app_flat_8: 38,
    char_9: 'Jade',
    app_rate_9: '8.5%',
    avg_round_9: 38840,
    app_flat_9: 27,
    char_10: 'Clara',
    app_rate_10: '8.21%',
    avg_round_10: 33410,
    app_flat_10: 36,
    char_11: 'Luocha',
    app_rate_11: '6.2%',
    avg_round_11: 32797,
    app_flat_11: 24,
    char_12: 'Feixiao',
    app_rate_12: '5.19%',
    avg_round_12: 31861,
    app_flat_12: 15,
    char_13: 'march-7th-swordmaster',
    app_rate_13: '5.04%',
    avg_round_13: 31134,
    app_flat_13: 25,
    char_14: 'ruan-mei',
    app_rate_14: '4.03%',
    avg_round_14: 33764,
    app_flat_14: 10,
    char_15: 'Lingsha',
    app_rate_15: '3.31%',
    avg_round_15: 29256,
    app_flat_15: 9,
    char_16: 'Acheron',
    app_rate_16: '3.31%',
    avg_round_16: 32286,
    app_flat_16: 14,
    char_17: 'topaz',
    app_rate_17: '3.17%',
    avg_round_17: 34367,
    app_flat_17: 11,
    char_18: 'Himeko',
    app_rate_18: '2.59%',
    avg_round_18: 29360,
    app_flat_18: 11,
    char_19: 'Gallagher',
    app_rate_19: '2.45%',
    avg_round_19: 33058,
    app_flat_19: 9,
    char_20: 'jing-yuan',
    app_rate_20: '1.87%',
    avg_round_20: 32728,
    app_flat_20: 7,
    char_21: 'Rappa',
    app_rate_21: '1.15%',
    avg_round_21: 38573,
    app_flat_21: 5,
    char_22: 'trailblazer-harmony',
    app_rate_22: '1.15%',
    avg_round_22: 38930,
    app_flat_22: 4,
    char_23: 'Lynx',
    app_rate_23: '1.15%',
    avg_round_23: 29827,
    app_flat_23: 5,
    char_24: 'Jiaoqiu',
    app_rate_24: '1.01%',
    avg_round_24: 33880,
    app_flat_24: 3,
    char_25: 'Kafka',
    app_rate_25: '0.72%',
    avg_round_25: 31330,
    app_flat_25: 4,
    char_26: 'Bailu',
    app_rate_26: '0.72%',
    avg_round_26: 32507,
    app_flat_26: 3,
    char_27: 'Firefly',
    app_rate_27: '0.58%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Pela',
    app_rate_28: '0.58%',
    avg_round_28: 31400,
    app_flat_28: 1,
    char_29: 'Serval',
    app_rate_29: '0.43%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'tingyun-fugue',
    app_rate_30: '0.43%',
    avg_round_30: 29760,
    app_flat_30: 1
  },
  {
    char: 'Argenti',
    app: 5.8,
    char_1: 'Sunday',
    app_rate_1: '63.34%',
    avg_round_1: 39790,
    app_flat_1: 230,
    char_2: 'Tingyun',
    app_rate_2: '45.75%',
    avg_round_2: 38825,
    app_flat_2: 195,
    char_3: 'Huohuo',
    app_rate_3: '33.72%',
    avg_round_3: 39362,
    app_flat_3: 133,
    char_4: 'Robin',
    app_rate_4: '32.99%',
    avg_round_4: 39375,
    app_flat_4: 102,
    char_5: 'Aventurine',
    app_rate_5: '21.99%',
    avg_round_5: 36206,
    app_flat_5: 77,
    char_6: 'Sparkle',
    app_rate_6: '20.53%',
    avg_round_6: 35187,
    app_flat_6: 80,
    char_7: 'Bronya',
    app_rate_7: '12.46%',
    avg_round_7: 36312,
    app_flat_7: 53,
    char_8: 'ruan-mei',
    app_rate_8: '11.14%',
    avg_round_8: 34508,
    app_flat_8: 37,
    char_9: 'Luocha',
    app_rate_9: '10.7%',
    avg_round_9: 36685,
    app_flat_9: 34,
    char_10: 'fu-xuan',
    app_rate_10: '9.97%',
    avg_round_10: 34960,
    app_flat_10: 33,
    char_11: 'Gallagher',
    app_rate_11: '7.77%',
    avg_round_11: 37116,
    app_flat_11: 34,
    char_12: 'Jiaoqiu',
    app_rate_12: '6.16%',
    avg_round_12: 33614,
    app_flat_12: 17,
    char_13: 'Jade',
    app_rate_13: '5.13%',
    avg_round_13: 38320,
    app_flat_13: 16,
    char_14: 'Herta',
    app_rate_14: '4.25%',
    avg_round_14: 33372,
    app_flat_14: 17,
    char_15: 'Lingsha',
    app_rate_15: '1.91%',
    avg_round_15: 33768,
    app_flat_15: 10,
    char_16: 'trailblazer-harmony',
    app_rate_16: '1.61%',
    avg_round_16: 31170,
    app_flat_16: 4,
    char_17: 'jing-yuan',
    app_rate_17: '1.47%',
    avg_round_17: 31700,
    app_flat_17: 6,
    char_18: 'Hanya',
    app_rate_18: '1.17%',
    avg_round_18: 29147,
    app_flat_18: 5,
    char_19: 'tingyun-fugue',
    app_rate_19: '0.88%',
    avg_round_19: 40000,
    app_flat_19: 1,
    char_20: 'Acheron',
    app_rate_20: '0.88%',
    avg_round_20: 32947,
    app_flat_20: 3,
    char_21: 'Clara',
    app_rate_21: '0.88%',
    avg_round_21: 30640,
    app_flat_21: 4,
    char_22: 'Himeko',
    app_rate_22: '0.88%',
    avg_round_22: 30440,
    app_flat_22: 4,
    char_23: 'Welt',
    app_rate_23: '0.73%',
    avg_round_23: 26600,
    app_flat_23: 2,
    char_24: 'Bailu',
    app_rate_24: '0.73%',
    avg_round_24: 34520,
    app_flat_24: 2,
    char_25: 'Rappa',
    app_rate_25: '0.73%',
    avg_round_25: 37280,
    app_flat_25: 3,
    char_26: 'Asta',
    app_rate_26: '0.29%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Natasha',
    app_rate_27: '0.29%',
    avg_round_27: 35600,
    app_flat_27: 2,
    char_28: 'Lynx',
    app_rate_28: '0.29%',
    avg_round_28: 37360,
    app_flat_28: 2,
    char_29: 'Yunli',
    app_rate_29: '0.29%',
    avg_round_29: 29520,
    app_flat_29: 1,
    char_30: 'Boothill',
    app_rate_30: '0.15%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Bronya',
    app: 5.15,
    char_1: 'Acheron',
    app_rate_1: '35.37%',
    avg_round_1: 29702,
    app_flat_1: 9,
    char_2: 'Sunday',
    app_rate_2: '28.76%',
    avg_round_2: 35567,
    app_flat_2: 76,
    char_3: 'Aventurine',
    app_rate_3: '24.79%',
    avg_round_3: 32138,
    app_flat_3: 39,
    char_4: 'Jiaoqiu',
    app_rate_4: '16.69%',
    avg_round_4: 34220,
    app_flat_4: 6,
    char_5: 'fu-xuan',
    app_rate_5: '15.87%',
    avg_round_5: 32013,
    app_flat_5: 18,
    char_6: 'jing-yuan',
    app_rate_6: '15.87%',
    avg_round_6: 33615,
    app_flat_6: 39,
    char_7: 'Argenti',
    app_rate_7: '14.05%',
    avg_round_7: 36312,
    app_flat_7: 53,
    char_8: 'Robin',
    app_rate_8: '13.88%',
    avg_round_8: 34025,
    app_flat_8: 34,
    char_9: 'Pela',
    app_rate_9: '12.73%',
    avg_round_9: 25987,
    app_flat_9: 6,
    char_10: 'Herta',
    app_rate_10: '12.23%',
    avg_round_10: 31627,
    app_flat_10: 42,
    char_11: 'Gallagher',
    app_rate_11: '11.9%',
    avg_round_11: 30531,
    app_flat_11: 26,
    char_12: 'ruan-mei',
    app_rate_12: '10.74%',
    avg_round_12: 31688,
    app_flat_12: 21,
    char_13: 'Luocha',
    app_rate_13: '9.26%',
    avg_round_13: 31845,
    app_flat_13: 24,
    char_14: 'Huohuo',
    app_rate_14: '9.09%',
    avg_round_14: 32552,
    app_flat_14: 32,
    char_15: 'Sparkle',
    app_rate_15: '6.45%',
    avg_round_15: 31305,
    app_flat_15: 16,
    char_16: 'Firefly',
    app_rate_16: '6.45%',
    avg_round_16: 31400,
    app_flat_16: 1,
    char_17: 'trailblazer-harmony',
    app_rate_17: '6.45%',
    avg_round_17: 29007,
    app_flat_17: 6,
    char_18: 'Lingsha',
    app_rate_18: '6.12%',
    avg_round_18: 39400,
    app_flat_18: 14,
    char_19: 'Tingyun',
    app_rate_19: '5.95%',
    avg_round_19: 34242,
    app_flat_19: 22,
    char_20: 'Jingliu',
    app_rate_20: '5.29%',
    avg_round_20: 25167,
    app_flat_20: 11,
    char_21: 'Jade',
    app_rate_21: '5.12%',
    avg_round_21: 35570,
    app_flat_21: 16,
    char_22: 'Himeko',
    app_rate_22: '3.64%',
    avg_round_22: 28911,
    app_flat_22: 17,
    char_23: 'Gepard',
    app_rate_23: '2.64%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'black-swan',
    app_rate_24: '2.48%',
    avg_round_24: 26480,
    app_flat_24: 1,
    char_25: 'Kafka',
    app_rate_25: '2.15%',
    avg_round_25: 28267,
    app_flat_25: 3,
    char_26: 'Serval',
    app_rate_26: '2.15%',
    avg_round_26: 29775,
    app_flat_26: 8,
    char_27: 'Blade',
    app_rate_27: '1.98%',
    avg_round_27: 26107,
    app_flat_27: 3,
    char_28: 'silver-wolf',
    app_rate_28: '1.65%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Rappa',
    app_rate_29: '1.65%',
    avg_round_29: 29368,
    app_flat_29: 5,
    char_30: 'tingyun-fugue',
    app_rate_30: '1.32%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Feixiao',
    app: 4.88,
    char_1: 'Robin',
    app_rate_1: '88.83%',
    avg_round_1: 31462,
    app_flat_1: 188,
    char_2: 'Aventurine',
    app_rate_2: '73.82%',
    avg_round_2: 31180,
    app_flat_2: 157,
    char_3: 'Herta',
    app_rate_3: '39.79%',
    avg_round_3: 32940,
    app_flat_3: 112,
    char_4: 'topaz',
    app_rate_4: '26.88%',
    avg_round_4: 27355,
    app_flat_4: 44,
    char_5: 'Jade',
    app_rate_5: '12.39%',
    avg_round_5: 34578,
    app_flat_5: 11,
    char_6: 'march-7th-swordmaster',
    app_rate_6: '10.99%',
    avg_round_6: 27151,
    app_flat_6: 28,
    char_7: 'Yunli',
    app_rate_7: '6.28%',
    avg_round_7: 31861,
    app_flat_7: 15,
    char_8: 'fu-xuan',
    app_rate_8: '5.58%',
    avg_round_8: 30107,
    app_flat_8: 10,
    char_9: 'Clara',
    app_rate_9: '5.58%',
    avg_round_9: 33587,
    app_flat_9: 15,
    char_10: 'Gallagher',
    app_rate_10: '5.24%',
    avg_round_10: 29697,
    app_flat_10: 19,
    char_11: 'Lingsha',
    app_rate_11: '4.71%',
    avg_round_11: 29592,
    app_flat_11: 9,
    char_12: 'Himeko',
    app_rate_12: '4.01%',
    avg_round_12: 28646,
    app_flat_12: 13,
    char_13: 'Sunday',
    app_rate_13: '2.44%',
    avg_round_13: 28900,
    app_flat_13: 4,
    char_14: 'Huohuo',
    app_rate_14: '2.09%',
    avg_round_14: 30140,
    app_flat_14: 4,
    char_15: 'Moze',
    app_rate_15: '2.09%',
    avg_round_15: 27380,
    app_flat_15: 2,
    char_16: 'ruan-mei',
    app_rate_16: '1.92%',
    avg_round_16: 24760,
    app_flat_16: 1,
    char_17: 'Luocha',
    app_rate_17: '1.75%',
    avg_round_17: 35400,
    app_flat_17: 3,
    char_18: 'Sparkle',
    app_rate_18: '1.22%',
    avg_round_18: 31040,
    app_flat_18: 2,
    char_19: 'Bronya',
    app_rate_19: '1.05%',
    avg_round_19: 31880,
    app_flat_19: 1,
    char_20: 'Acheron',
    app_rate_20: '1.05%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Jiaoqiu',
    app_rate_21: '0.7%',
    avg_round_21: 28720,
    app_flat_21: 1,
    char_22: 'jing-yuan',
    app_rate_22: '0.35%',
    avg_round_22: 24080,
    app_flat_22: 1,
    char_23: 'Firefly',
    app_rate_23: '0.17%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'black-swan',
    app_rate_24: '0.17%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Kafka',
    app_rate_25: '0.17%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Tingyun',
    app_rate_26: '0.17%',
    avg_round_26: 29680,
    app_flat_26: 1,
    char_27: 'Lynx',
    app_rate_27: '0.17%',
    avg_round_27: 29640,
    app_flat_27: 1,
    char_28: 'Pela',
    app_rate_28: '0.17%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Clara',
    app: 3.41,
    char_1: 'Robin',
    app_rate_1: '63.59%',
    avg_round_1: 32265,
    app_flat_1: 138,
    char_2: 'Aventurine',
    app_rate_2: '45.39%',
    avg_round_2: 31382,
    app_flat_2: 104,
    char_3: 'Herta',
    app_rate_3: '23.19%',
    avg_round_3: 31883,
    app_flat_3: 51,
    char_4: 'Sunday',
    app_rate_4: '21.45%',
    avg_round_4: 29309,
    app_flat_4: 51,
    char_5: 'Huohuo',
    app_rate_5: '18.7%',
    avg_round_5: 31029,
    app_flat_5: 42,
    char_6: 'Sparkle',
    app_rate_6: '17.21%',
    avg_round_6: 29458,
    app_flat_6: 29,
    char_7: 'Yunli',
    app_rate_7: '14.21%',
    avg_round_7: 33410,
    app_flat_7: 36,
    char_8: 'fu-xuan',
    app_rate_8: '13.72%',
    avg_round_8: 29011,
    app_flat_8: 19,
    char_9: 'Tingyun',
    app_rate_9: '8.98%',
    avg_round_9: 26636,
    app_flat_9: 19,
    char_10: 'Feixiao',
    app_rate_10: '7.98%',
    avg_round_10: 33587,
    app_flat_10: 15,
    char_11: 'Luocha',
    app_rate_11: '7.73%',
    avg_round_11: 31048,
    app_flat_11: 19,
    char_12: 'Jade',
    app_rate_12: '6.98%',
    avg_round_12: 34413,
    app_flat_12: 15,
    char_13: 'Himeko',
    app_rate_13: '6.23%',
    avg_round_13: 27618,
    app_flat_13: 16,
    char_14: 'Gallagher',
    app_rate_14: '4.74%',
    avg_round_14: 27880,
    app_flat_14: 11,
    char_15: 'topaz',
    app_rate_15: '4.49%',
    avg_round_15: 28000,
    app_flat_15: 9,
    char_16: 'march-7th-swordmaster',
    app_rate_16: '4.49%',
    avg_round_16: 27427,
    app_flat_16: 12,
    char_17: 'ruan-mei',
    app_rate_17: '4.24%',
    avg_round_17: 27940,
    app_flat_17: 10,
    char_18: 'Lingsha',
    app_rate_18: '4.24%',
    avg_round_18: 30055,
    app_flat_18: 13,
    char_19: 'jing-yuan',
    app_rate_19: '3.49%',
    avg_round_19: 29823,
    app_flat_19: 7,
    char_20: 'Acheron',
    app_rate_20: '2.99%',
    avg_round_20: 27846,
    app_flat_20: 7,
    char_21: 'trailblazer-harmony',
    app_rate_21: '1.75%',
    avg_round_21: 27170,
    app_flat_21: 4,
    char_22: 'Argenti',
    app_rate_22: '1.5%',
    avg_round_22: 30640,
    app_flat_22: 4,
    char_23: 'Pela',
    app_rate_23: '1.25%',
    avg_round_23: 25293,
    app_flat_23: 3,
    char_24: 'Bronya',
    app_rate_24: '1.25%',
    avg_round_24: 27180,
    app_flat_24: 4,
    char_25: 'dr-ratio',
    app_rate_25: '1.0%',
    avg_round_25: 26360,
    app_flat_25: 2,
    char_26: 'Rappa',
    app_rate_26: '1.0%',
    avg_round_26: 30240,
    app_flat_26: 2,
    char_27: 'Firefly',
    app_rate_27: '1.0%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Jiaoqiu',
    app_rate_28: '1.0%',
    avg_round_28: 30240,
    app_flat_28: 3,
    char_29: 'Bailu',
    app_rate_29: '1.0%',
    avg_round_29: 23820,
    app_flat_29: 2,
    char_30: 'Serval',
    app_rate_30: '1.0%',
    avg_round_30: 25760,
    app_flat_30: 3
  },
  {
    char: 'topaz',
    app: 2.65,
    char_1: 'Aventurine',
    app_rate_1: '72.76%',
    avg_round_1: 28230,
    app_flat_1: 82,
    char_2: 'Robin',
    app_rate_2: '72.44%',
    avg_round_2: 28264,
    app_flat_2: 75,
    char_3: 'Feixiao',
    app_rate_3: '49.36%',
    avg_round_3: 27355,
    app_flat_3: 44,
    char_4: 'Herta',
    app_rate_4: '28.21%',
    avg_round_4: 27596,
    app_flat_4: 44,
    char_5: 'Himeko',
    app_rate_5: '16.03%',
    avg_round_5: 26545,
    app_flat_5: 25,
    char_6: 'Jade',
    app_rate_6: '9.29%',
    avg_round_6: 30833,
    app_flat_6: 6,
    char_7: 'Yunli',
    app_rate_7: '7.05%',
    avg_round_7: 34367,
    app_flat_7: 11,
    char_8: 'Lingsha',
    app_rate_8: '6.41%',
    avg_round_8: 29091,
    app_flat_8: 7,
    char_9: 'ruan-mei',
    app_rate_9: '6.09%',
    avg_round_9: 26824,
    app_flat_9: 9,
    char_10: 'Clara',
    app_rate_10: '5.77%',
    avg_round_10: 28000,
    app_flat_10: 9,
    char_11: 'Huohuo',
    app_rate_11: '5.45%',
    avg_round_11: 28040,
    app_flat_11: 8,
    char_12: 'fu-xuan',
    app_rate_12: '5.45%',
    avg_round_12: 26136,
    app_flat_12: 7,
    char_13: 'Sunday',
    app_rate_13: '3.21%',
    avg_round_13: 28587,
    app_flat_13: 6,
    char_14: 'Gallagher',
    app_rate_14: '2.24%',
    avg_round_14: 24160,
    app_flat_14: 5,
    char_15: 'march-7th-swordmaster',
    app_rate_15: '1.6%',
    avg_round_15: 24340,
    app_flat_15: 2,
    char_16: 'Sparkle',
    app_rate_16: '1.28%',
    avg_round_16: 25560,
    app_flat_16: 1,
    char_17: 'Bronya',
    app_rate_17: '0.96%',
    avg_round_17: 25920,
    app_flat_17: 1,
    char_18: 'Tingyun',
    app_rate_18: '0.96%',
    avg_round_18: 27680,
    app_flat_18: 1,
    char_19: 'Luocha',
    app_rate_19: '0.96%',
    avg_round_19: 28560,
    app_flat_19: 2,
    char_20: 'jing-yuan',
    app_rate_20: '0.96%',
    avg_round_20: 30120,
    app_flat_20: 3,
    char_21: 'Jiaoqiu',
    app_rate_21: '0.64%',
    avg_round_21: 24120,
    app_flat_21: 1,
    char_22: 'Gepard',
    app_rate_22: '0.32%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Acheron',
    app_rate_23: '0.32%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'dr-ratio',
    app_rate_24: '0.32%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Misha',
    app_rate_25: '0.32%',
    avg_round_25: 24800,
    app_flat_25: 1,
    char_26: 'Moze',
    app_rate_26: '0.32%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Firefly',
    app_rate_27: '0.32%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Asta',
    app_rate_28: '0.32%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'trailblazer-harmony',
    app_rate_29: '0.32%',
    avg_round_29: 25440,
    app_flat_29: 1,
    char_30: 'Blade',
    app_rate_30: '0.32%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'silver-wolf',
    app: 2.03,
    char_1: 'Acheron',
    app_rate_1: '97.48%',
    avg_round_1: 28032,
    app_flat_1: 97,
    char_2: 'fu-xuan',
    app_rate_2: '40.76%',
    avg_round_2: 26993,
    app_flat_2: 31,
    char_3: 'Pela',
    app_rate_3: '39.08%',
    avg_round_3: 26960,
    app_flat_3: 61,
    char_4: 'Aventurine',
    app_rate_4: '36.97%',
    avg_round_4: 28674,
    app_flat_4: 40,
    char_5: 'Sparkle',
    app_rate_5: '26.05%',
    avg_round_5: 25340,
    app_flat_5: 4,
    char_6: 'Jiaoqiu',
    app_rate_6: '16.39%',
    avg_round_6: 33636,
    app_flat_6: 22,
    char_7: 'Sunday',
    app_rate_7: '7.14%',
    avg_round_7: 28780,
    app_flat_7: 4,
    char_8: 'Gallagher',
    app_rate_8: '6.3%',
    avg_round_8: 27884,
    app_flat_8: 9,
    char_9: 'Gepard',
    app_rate_9: '6.3%',
    avg_round_9: 30131,
    app_flat_9: 7,
    char_10: 'Bronya',
    app_rate_10: '4.2%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Kafka',
    app_rate_11: '3.36%',
    avg_round_11: 27547,
    app_flat_11: 3,
    char_12: 'Lingsha',
    app_rate_12: '2.1%',
    avg_round_12: 31880,
    app_flat_12: 1,
    char_13: 'Luocha',
    app_rate_13: '2.1%',
    avg_round_13: 30570,
    app_flat_13: 4,
    char_14: 'Huohuo',
    app_rate_14: '2.1%',
    avg_round_14: 26440,
    app_flat_14: 3,
    char_15: 'black-swan',
    app_rate_15: '1.68%',
    avg_round_15: 27160,
    app_flat_15: 3,
    char_16: 'Robin',
    app_rate_16: '1.68%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Rappa',
    app_rate_17: '0.84%',
    avg_round_17: 24000,
    app_flat_17: 1,
    char_18: 'trailblazer-harmony',
    app_rate_18: '0.84%',
    avg_round_18: 24000,
    app_flat_18: 1,
    char_19: 'tingyun-fugue',
    app_rate_19: '0.42%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Firefly',
    app_rate_20: '0.42%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'trailblazer-preservation',
    app_rate_21: '0.42%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Yunli',
    app_rate_22: '0.42%',
    avg_round_22: 31720,
    app_flat_22: 1,
    char_23: 'Seele',
    app_rate_23: '0.42%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'dr-ratio',
    app_rate_24: '0.42%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'march-7th',
    app_rate_25: '0.42%',
    avg_round_25: 26360,
    app_flat_25: 1,
    char_26: 'Guinaifen',
    app_rate_26: '0.42%',
    avg_round_26: 28040,
    app_flat_26: 1,
    char_27: 'Jade',
    app_rate_27: '0.42%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Jingliu',
    app_rate_28: '0.42%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Hanya',
    app_rate_29: '0.42%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Gepard',
    app: 1.52,
    char_1: 'Acheron',
    app_rate_1: '83.24%',
    avg_round_1: 31748,
    app_flat_1: 62,
    char_2: 'black-swan',
    app_rate_2: '32.96%',
    avg_round_2: 32769,
    app_flat_2: 30,
    char_3: 'Pela',
    app_rate_3: '32.4%',
    avg_round_3: 29565,
    app_flat_3: 31,
    char_4: 'Kafka',
    app_rate_4: '30.17%',
    avg_round_4: 32378,
    app_flat_4: 27,
    char_5: 'Sparkle',
    app_rate_5: '17.32%',
    avg_round_5: 28613,
    app_flat_5: 6,
    char_6: 'Jiaoqiu',
    app_rate_6: '16.2%',
    avg_round_6: 34931,
    app_flat_6: 11,
    char_7: 'Herta',
    app_rate_7: '13.41%',
    avg_round_7: 29050,
    app_flat_7: 16,
    char_8: 'Sunday',
    app_rate_8: '11.17%',
    avg_round_8: 32809,
    app_flat_8: 9,
    char_9: 'Himeko',
    app_rate_9: '10.06%',
    avg_round_9: 28243,
    app_flat_9: 13,
    char_10: 'Bronya',
    app_rate_10: '8.94%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'silver-wolf',
    app_rate_11: '8.38%',
    avg_round_11: 30131,
    app_flat_11: 7,
    char_12: 'Robin',
    app_rate_12: '7.82%',
    avg_round_12: 33051,
    app_flat_12: 7,
    char_13: 'ruan-mei',
    app_rate_13: '7.26%',
    avg_round_13: 30057,
    app_flat_13: 7,
    char_14: 'Guinaifen',
    app_rate_14: '4.47%',
    avg_round_14: 29567,
    app_flat_14: 6,
    char_15: 'Jade',
    app_rate_15: '3.35%',
    avg_round_15: 31950,
    app_flat_15: 4,
    char_16: 'Welt',
    app_rate_16: '2.79%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'trailblazer-harmony',
    app_rate_17: '1.68%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'jing-yuan',
    app_rate_18: '1.68%',
    avg_round_18: 36920,
    app_flat_18: 2,
    char_19: 'tingyun-fugue',
    app_rate_19: '1.68%',
    avg_round_19: 26160,
    app_flat_19: 2,
    char_20: 'Firefly',
    app_rate_20: '1.12%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Tingyun',
    app_rate_21: '1.12%',
    avg_round_21: 40000,
    app_flat_21: 1,
    char_22: 'topaz',
    app_rate_22: '0.56%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Yunli',
    app_rate_23: '0.56%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Asta',
    app_rate_24: '0.56%',
    avg_round_24: 32320,
    app_flat_24: 1,
    char_25: 'Jingliu',
    app_rate_25: '0.56%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Argenti',
    app_rate_26: '0.56%',
    avg_round_26: 40000,
    app_flat_26: 1,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th-swordmaster',
    app: 1.51,
    char_1: 'Robin',
    app_rate_1: '65.54%',
    avg_round_1: 29309,
    app_flat_1: 57,
    char_2: 'Aventurine',
    app_rate_2: '50.28%',
    avg_round_2: 28122,
    app_flat_2: 41,
    char_3: 'Feixiao',
    app_rate_3: '35.59%',
    avg_round_3: 27151,
    app_flat_3: 28,
    char_4: 'Yunli',
    app_rate_4: '19.77%',
    avg_round_4: 31134,
    app_flat_4: 25,
    char_5: 'Herta',
    app_rate_5: '16.95%',
    avg_round_5: 29419,
    app_flat_5: 17,
    char_6: 'Sunday',
    app_rate_6: '13.56%',
    avg_round_6: 29287,
    app_flat_6: 11,
    char_7: 'Clara',
    app_rate_7: '10.17%',
    avg_round_7: 27427,
    app_flat_7: 12,
    char_8: 'Huohuo',
    app_rate_8: '9.6%',
    avg_round_8: 29880,
    app_flat_8: 12,
    char_9: 'jing-yuan',
    app_rate_9: '9.04%',
    avg_round_9: 27156,
    app_flat_9: 9,
    char_10: 'fu-xuan',
    app_rate_10: '7.91%',
    avg_round_10: 29733,
    app_flat_10: 6,
    char_11: 'Lingsha',
    app_rate_11: '7.91%',
    avg_round_11: 28309,
    app_flat_11: 7,
    char_12: 'trailblazer-harmony',
    app_rate_12: '7.34%',
    avg_round_12: 28160,
    app_flat_12: 1,
    char_13: 'Gallagher',
    app_rate_13: '7.34%',
    avg_round_13: 26834,
    app_flat_13: 7,
    char_14: 'Sparkle',
    app_rate_14: '5.65%',
    avg_round_14: 29816,
    app_flat_14: 5,
    char_15: 'Himeko',
    app_rate_15: '5.65%',
    avg_round_15: 27087,
    app_flat_15: 6,
    char_16: 'Firefly',
    app_rate_16: '4.52%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Luocha',
    app_rate_17: '3.39%',
    avg_round_17: 24800,
    app_flat_17: 3,
    char_18: 'topaz',
    app_rate_18: '2.82%',
    avg_round_18: 24340,
    app_flat_18: 2,
    char_19: 'Tingyun',
    app_rate_19: '2.26%',
    avg_round_19: 28533,
    app_flat_19: 3,
    char_20: 'ruan-mei',
    app_rate_20: '2.26%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Acheron',
    app_rate_21: '2.26%',
    avg_round_21: 24080,
    app_flat_21: 1,
    char_22: 'Bronya',
    app_rate_22: '1.69%',
    avg_round_22: 24640,
    app_flat_22: 1,
    char_23: 'Lynx',
    app_rate_23: '1.69%',
    avg_round_23: 28680,
    app_flat_23: 1,
    char_24: 'Rappa',
    app_rate_24: '1.69%',
    avg_round_24: 28160,
    app_flat_24: 1,
    char_25: 'Jade',
    app_rate_25: '1.13%',
    avg_round_25: 34600,
    app_flat_25: 1,
    char_26: 'Pela',
    app_rate_26: '1.13%',
    avg_round_26: 24080,
    app_flat_26: 1,
    char_27: 'Kafka',
    app_rate_27: '1.13%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'tingyun-fugue',
    app_rate_28: '0.56%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'dr-ratio',
    app_rate_29: '0.56%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Seele',
    app_rate_30: '0.56%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Welt',
    app: 1.05,
    char_1: 'Acheron',
    app_rate_1: '82.93%',
    avg_round_1: 28038,
    app_flat_1: 36,
    char_2: 'Aventurine',
    app_rate_2: '37.4%',
    avg_round_2: 27228,
    app_flat_2: 20,
    char_3: 'Pela',
    app_rate_3: '21.95%',
    avg_round_3: 28250,
    app_flat_3: 14,
    char_4: 'Sunday',
    app_rate_4: '21.14%',
    avg_round_4: 27440,
    app_flat_4: 11,
    char_5: 'fu-xuan',
    app_rate_5: '18.7%',
    avg_round_5: 27058,
    app_flat_5: 9,
    char_6: 'Sparkle',
    app_rate_6: '17.07%',
    avg_round_6: 25853,
    app_flat_6: 3,
    char_7: 'Kafka',
    app_rate_7: '13.82%',
    avg_round_7: 28168,
    app_flat_7: 5,
    char_8: 'Jiaoqiu',
    app_rate_8: '13.82%',
    avg_round_8: 35880,
    app_flat_8: 6,
    char_9: 'ruan-mei',
    app_rate_9: '9.76%',
    avg_round_9: 30320,
    app_flat_9: 4,
    char_10: 'Gallagher',
    app_rate_10: '8.13%',
    avg_round_10: 30507,
    app_flat_10: 5,
    char_11: 'Luocha',
    app_rate_11: '7.32%',
    avg_round_11: 24400,
    app_flat_11: 3,
    char_12: 'black-swan',
    app_rate_12: '5.69%',
    avg_round_12: 26720,
    app_flat_12: 3,
    char_13: 'Gepard',
    app_rate_13: '4.07%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'jing-yuan',
    app_rate_14: '4.07%',
    avg_round_14: 36160,
    app_flat_14: 1,
    char_15: 'Argenti',
    app_rate_15: '4.07%',
    avg_round_15: 26600,
    app_flat_15: 2,
    char_16: 'Robin',
    app_rate_16: '3.25%',
    avg_round_16: 27720,
    app_flat_16: 1,
    char_17: 'Huohuo',
    app_rate_17: '2.44%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Bronya',
    app_rate_18: '2.44%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Tingyun',
    app_rate_19: '2.44%',
    avg_round_19: 27260,
    app_flat_19: 2,
    char_20: 'trailblazer-harmony',
    app_rate_20: '2.44%',
    avg_round_20: 24800,
    app_flat_20: 1,
    char_21: 'Rappa',
    app_rate_21: '2.44%',
    avg_round_21: 26620,
    app_flat_21: 2,
    char_22: 'Lingsha',
    app_rate_22: '2.44%',
    avg_round_22: 27160,
    app_flat_22: 1,
    char_23: 'Herta',
    app_rate_23: '1.63%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'tingyun-fugue',
    app_rate_24: '1.63%',
    avg_round_24: 28440,
    app_flat_24: 1,
    char_25: 'Bailu',
    app_rate_25: '1.63%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Guinaifen',
    app_rate_26: '1.63%',
    avg_round_26: 22480,
    app_flat_26: 1,
    char_27: 'Serval',
    app_rate_27: '0.81%',
    avg_round_27: 24720,
    app_flat_27: 1,
    char_28: 'Firefly',
    app_rate_28: '0.81%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'dr-ratio',
    app_rate_29: '0.81%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Moze',
    app_rate_30: '0.81%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Jingliu',
    app: 0.95,
    char_1: 'ruan-mei',
    app_rate_1: '34.82%',
    avg_round_1: 27483,
    app_flat_1: 15,
    char_2: 'Sunday',
    app_rate_2: '34.82%',
    avg_round_2: 27665,
    app_flat_2: 13,
    char_3: 'Huohuo',
    app_rate_3: '34.82%',
    avg_round_3: 26144,
    app_flat_3: 15,
    char_4: 'Robin',
    app_rate_4: '31.25%',
    avg_round_4: 29032,
    app_flat_4: 10,
    char_5: 'Herta',
    app_rate_5: '30.36%',
    avg_round_5: 28410,
    app_flat_5: 14,
    char_6: 'Bronya',
    app_rate_6: '28.57%',
    avg_round_6: 25167,
    app_flat_6: 11,
    char_7: 'Luocha',
    app_rate_7: '20.54%',
    avg_round_7: 25342,
    app_flat_7: 9,
    char_8: 'fu-xuan',
    app_rate_8: '14.29%',
    avg_round_8: 24880,
    app_flat_8: 5,
    char_9: 'Sparkle',
    app_rate_9: '12.5%',
    avg_round_9: 24950,
    app_flat_9: 4,
    char_10: 'Himeko',
    app_rate_10: '10.71%',
    avg_round_10: 27130,
    app_flat_10: 8,
    char_11: 'Lingsha',
    app_rate_11: '10.71%',
    avg_round_11: 33470,
    app_flat_11: 8,
    char_12: 'Gallagher',
    app_rate_12: '8.93%',
    avg_round_12: 26780,
    app_flat_12: 2,
    char_13: 'Pela',
    app_rate_13: '5.36%',
    avg_round_13: 25173,
    app_flat_13: 3,
    char_14: 'Tingyun',
    app_rate_14: '3.57%',
    avg_round_14: 26600,
    app_flat_14: 2,
    char_15: 'Blade',
    app_rate_15: '3.57%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Aventurine',
    app_rate_16: '3.57%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Jade',
    app_rate_17: '3.57%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Yunli',
    app_rate_18: '1.79%',
    avg_round_18: 25000,
    app_flat_18: 1,
    char_19: 'Clara',
    app_rate_19: '0.89%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Bailu',
    app_rate_20: '0.89%',
    avg_round_20: 27040,
    app_flat_20: 1,
    char_21: 'Gepard',
    app_rate_21: '0.89%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Guinaifen',
    app_rate_22: '0.89%',
    avg_round_22: 26080,
    app_flat_22: 1,
    char_23: 'Firefly',
    app_rate_23: '0.89%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Jiaoqiu',
    app_rate_24: '0.89%',
    avg_round_24: 29320,
    app_flat_24: 1,
    char_25: 'silver-wolf',
    app_rate_25: '0.89%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Bailu',
    app: 0.87,
    char_1: 'Kafka',
    app_rate_1: '37.25%',
    avg_round_1: 31584,
    app_flat_1: 5,
    char_2: 'Acheron',
    app_rate_2: '34.31%',
    avg_round_2: 30491,
    app_flat_2: 7,
    char_3: 'Herta',
    app_rate_3: '32.35%',
    avg_round_3: 29798,
    app_flat_3: 21,
    char_4: 'black-swan',
    app_rate_4: '29.41%',
    avg_round_4: 31584,
    app_flat_4: 5,
    char_5: 'Robin',
    app_rate_5: '29.41%',
    avg_round_5: 33156,
    app_flat_5: 11,
    char_6: 'Himeko',
    app_rate_6: '21.57%',
    avg_round_6: 28624,
    app_flat_6: 17,
    char_7: 'Sunday',
    app_rate_7: '17.65%',
    avg_round_7: 30349,
    app_flat_7: 7,
    char_8: 'ruan-mei',
    app_rate_8: '16.67%',
    avg_round_8: 33149,
    app_flat_8: 7,
    char_9: 'Sparkle',
    app_rate_9: '10.78%',
    avg_round_9: 26904,
    app_flat_9: 5,
    char_10: 'Tingyun',
    app_rate_10: '8.82%',
    avg_round_10: 30600,
    app_flat_10: 3,
    char_11: 'jing-yuan',
    app_rate_11: '8.82%',
    avg_round_11: 30627,
    app_flat_11: 3,
    char_12: 'Jade',
    app_rate_12: '7.84%',
    avg_round_12: 33260,
    app_flat_12: 4,
    char_13: 'Pela',
    app_rate_13: '5.88%',
    avg_round_13: 28147,
    app_flat_13: 3,
    char_14: 'Argenti',
    app_rate_14: '4.9%',
    avg_round_14: 34520,
    app_flat_14: 2,
    char_15: 'Jiaoqiu',
    app_rate_15: '4.9%',
    avg_round_15: 31360,
    app_flat_15: 1,
    char_16: 'Yunli',
    app_rate_16: '4.9%',
    avg_round_16: 32507,
    app_flat_16: 3,
    char_17: 'trailblazer-harmony',
    app_rate_17: '3.92%',
    avg_round_17: 35640,
    app_flat_17: 3,
    char_18: 'Clara',
    app_rate_18: '3.92%',
    avg_round_18: 23820,
    app_flat_18: 2,
    char_19: 'Bronya',
    app_rate_19: '3.92%',
    avg_round_19: 29440,
    app_flat_19: 3,
    char_20: 'Rappa',
    app_rate_20: '2.94%',
    avg_round_20: 35640,
    app_flat_20: 3,
    char_21: 'Serval',
    app_rate_21: '2.94%',
    avg_round_21: 22760,
    app_flat_21: 1,
    char_22: 'Welt',
    app_rate_22: '1.96%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'tingyun-fugue',
    app_rate_23: '1.96%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Jingliu',
    app_rate_24: '0.98%',
    avg_round_24: 27040,
    app_flat_24: 1,
    char_25: 'Firefly',
    app_rate_25: '0.98%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Lingsha',
    app_rate_26: '0.98%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Guinaifen',
    app: 0.71,
    char_1: 'Acheron',
    app_rate_1: '83.33%',
    avg_round_1: 29056,
    app_flat_1: 45,
    char_2: 'Pela',
    app_rate_2: '45.24%',
    avg_round_2: 28174,
    app_flat_2: 28,
    char_3: 'Aventurine',
    app_rate_3: '34.52%',
    avg_round_3: 28472,
    app_flat_3: 19,
    char_4: 'fu-xuan',
    app_rate_4: '21.43%',
    avg_round_4: 29313,
    app_flat_4: 11,
    char_5: 'Gallagher',
    app_rate_5: '16.67%',
    avg_round_5: 28071,
    app_flat_5: 9,
    char_6: 'black-swan',
    app_rate_6: '14.29%',
    avg_round_6: 28220,
    app_flat_6: 6,
    char_7: 'Kafka',
    app_rate_7: '11.9%',
    avg_round_7: 25773,
    app_flat_7: 3,
    char_8: 'Jiaoqiu',
    app_rate_8: '10.71%',
    avg_round_8: 33794,
    app_flat_8: 7,
    char_9: 'Gepard',
    app_rate_9: '9.52%',
    avg_round_9: 29567,
    app_flat_9: 6,
    char_10: 'ruan-mei',
    app_rate_10: '4.76%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Luocha',
    app_rate_11: '4.76%',
    avg_round_11: 24560,
    app_flat_11: 3,
    char_12: 'Sunday',
    app_rate_12: '4.76%',
    avg_round_12: 27120,
    app_flat_12: 2,
    char_13: 'Himeko',
    app_rate_13: '4.76%',
    avg_round_13: 26740,
    app_flat_13: 4,
    char_14: 'Herta',
    app_rate_14: '4.76%',
    avg_round_14: 27320,
    app_flat_14: 4,
    char_15: 'trailblazer-harmony',
    app_rate_15: '4.76%',
    avg_round_15: 28300,
    app_flat_15: 2,
    char_16: 'Asta',
    app_rate_16: '3.57%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Lingsha',
    app_rate_17: '3.57%',
    avg_round_17: 38880,
    app_flat_17: 1,
    char_18: 'Huohuo',
    app_rate_18: '3.57%',
    avg_round_18: 26080,
    app_flat_18: 1,
    char_19: 'Sparkle',
    app_rate_19: '2.38%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Welt',
    app_rate_20: '2.38%',
    avg_round_20: 22480,
    app_flat_20: 1,
    char_21: 'Bronya',
    app_rate_21: '1.19%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'trailblazer-preservation',
    app_rate_22: '1.19%',
    avg_round_22: 30640,
    app_flat_22: 1,
    char_23: 'Robin',
    app_rate_23: '1.19%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Firefly',
    app_rate_24: '1.19%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Jingliu',
    app_rate_25: '1.19%',
    avg_round_25: 26080,
    app_flat_25: 1,
    char_26: 'Sampo',
    app_rate_26: '1.19%',
    avg_round_26: 27360,
    app_flat_26: 1,
    char_27: 'silver-wolf',
    app_rate_27: '1.19%',
    avg_round_27: 28040,
    app_flat_27: 1,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'imbibitor-lunae',
    app: 0.71,
    char_1: 'Sunday',
    app_rate_1: '61.9%',
    avg_round_1: 24000,
    app_flat_1: 1,
    char_2: 'Sparkle',
    app_rate_2: '61.9%',
    avg_round_2: 24800,
    app_flat_2: 1,
    char_3: 'Huohuo',
    app_rate_3: '36.9%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Tingyun',
    app_rate_4: '25.0%',
    avg_round_4: 24400,
    app_flat_4: 2,
    char_5: 'Robin',
    app_rate_5: '21.43%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Luocha',
    app_rate_6: '21.43%',
    avg_round_6: 24000,
    app_flat_6: 1,
    char_7: 'ruan-mei',
    app_rate_7: '20.24%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Aventurine',
    app_rate_8: '14.29%',
    avg_round_8: 24800,
    app_flat_8: 1,
    char_9: 'fu-xuan',
    app_rate_9: '10.71%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Gallagher',
    app_rate_10: '7.14%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Bronya',
    app_rate_11: '3.57%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Jiaoqiu',
    app_rate_12: '3.57%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Herta',
    app_rate_13: '2.38%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Pela',
    app_rate_14: '1.19%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'tingyun-fugue',
    app_rate_15: '1.19%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Clara',
    app_rate_16: '1.19%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Argenti',
    app_rate_17: '1.19%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Boothill',
    app_rate_18: '1.19%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Rappa',
    app_rate_19: '1.19%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Lingsha',
    app_rate_20: '1.19%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Acheron',
    app_rate_21: '1.19%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Serval',
    app: 0.69,
    char_1: 'Sunday',
    app_rate_1: '50.62%',
    avg_round_1: 31251,
    app_flat_1: 25,
    char_2: 'Robin',
    app_rate_2: '35.8%',
    avg_round_2: 29728,
    app_flat_2: 16,
    char_3: 'Huohuo',
    app_rate_3: '34.57%',
    avg_round_3: 32753,
    app_flat_3: 12,
    char_4: 'Aventurine',
    app_rate_4: '33.33%',
    avg_round_4: 27525,
    app_flat_4: 16,
    char_5: 'Tingyun',
    app_rate_5: '20.99%',
    avg_round_5: 31132,
    app_flat_5: 10,
    char_6: 'Bronya',
    app_rate_6: '16.05%',
    avg_round_6: 29775,
    app_flat_6: 8,
    char_7: 'Sparkle',
    app_rate_7: '13.58%',
    avg_round_7: 22493,
    app_flat_7: 3,
    char_8: 'Acheron',
    app_rate_8: '11.11%',
    avg_round_8: 30180,
    app_flat_8: 4,
    char_9: 'Gallagher',
    app_rate_9: '9.88%',
    avg_round_9: 28808,
    app_flat_9: 5,
    char_10: 'ruan-mei',
    app_rate_10: '8.64%',
    avg_round_10: 26867,
    app_flat_10: 5,
    char_11: 'Kafka',
    app_rate_11: '7.41%',
    avg_round_11: 27320,
    app_flat_11: 2,
    char_12: 'Luocha',
    app_rate_12: '7.41%',
    avg_round_12: 24740,
    app_flat_12: 4,
    char_13: 'fu-xuan',
    app_rate_13: '4.94%',
    avg_round_13: 28580,
    app_flat_13: 2,
    char_14: 'Herta',
    app_rate_14: '4.94%',
    avg_round_14: 31480,
    app_flat_14: 2,
    char_15: 'Clara',
    app_rate_15: '4.94%',
    avg_round_15: 25760,
    app_flat_15: 3,
    char_16: 'trailblazer-harmony',
    app_rate_16: '3.7%',
    avg_round_16: 29373,
    app_flat_16: 3,
    char_17: 'Yunli',
    app_rate_17: '3.7%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Pela',
    app_rate_18: '3.7%',
    avg_round_18: 29840,
    app_flat_18: 1,
    char_19: 'Jade',
    app_rate_19: '3.7%',
    avg_round_19: 32320,
    app_flat_19: 1,
    char_20: 'jing-yuan',
    app_rate_20: '3.7%',
    avg_round_20: 30760,
    app_flat_20: 2,
    char_21: 'Bailu',
    app_rate_21: '3.7%',
    avg_round_21: 22760,
    app_flat_21: 1,
    char_22: 'Jiaoqiu',
    app_rate_22: '2.47%',
    avg_round_22: 34480,
    app_flat_22: 1,
    char_23: 'black-swan',
    app_rate_23: '2.47%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Asta',
    app_rate_24: '2.47%',
    avg_round_24: 33440,
    app_flat_24: 1,
    char_25: 'Rappa',
    app_rate_25: '1.23%',
    avg_round_25: 40000,
    app_flat_25: 1,
    char_26: 'Welt',
    app_rate_26: '1.23%',
    avg_round_26: 24720,
    app_flat_26: 1,
    char_27: 'Lynx',
    app_rate_27: '1.23%',
    avg_round_27: 30280,
    app_flat_27: 1,
    char_28: 'Lingsha',
    app_rate_28: '1.23%',
    avg_round_28: 32320,
    app_flat_28: 1,
    char_29: 'Argenti',
    app_rate_29: '1.23%',
    avg_round_29: 31680,
    app_flat_29: 1,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Asta',
    app: 0.48,
    char_1: 'Herta',
    app_rate_1: '52.63%',
    avg_round_1: 30905,
    app_flat_1: 21,
    char_2: 'Himeko',
    app_rate_2: '49.12%',
    avg_round_2: 30905,
    app_flat_2: 21,
    char_3: 'Gallagher',
    app_rate_3: '24.56%',
    avg_round_3: 32430,
    app_flat_3: 8,
    char_4: 'Kafka',
    app_rate_4: '19.3%',
    avg_round_4: 23720,
    app_flat_4: 1,
    char_5: 'Aventurine',
    app_rate_5: '17.54%',
    avg_round_5: 30307,
    app_flat_5: 6,
    char_6: 'black-swan',
    app_rate_6: '15.79%',
    avg_round_6: 32320,
    app_flat_6: 1,
    char_7: 'Huohuo',
    app_rate_7: '12.28%',
    avg_round_7: 28220,
    app_flat_7: 2,
    char_8: 'ruan-mei',
    app_rate_8: '10.53%',
    avg_round_8: 38020,
    app_flat_8: 2,
    char_9: 'Lingsha',
    app_rate_9: '10.53%',
    avg_round_9: 33267,
    app_flat_9: 3,
    char_10: 'Sunday',
    app_rate_10: '10.53%',
    avg_round_10: 40000,
    app_flat_10: 1,
    char_11: 'fu-xuan',
    app_rate_11: '8.77%',
    avg_round_11: 28520,
    app_flat_11: 1,
    char_12: 'Acheron',
    app_rate_12: '8.77%',
    avg_round_12: 32320,
    app_flat_12: 1,
    char_13: 'Firefly',
    app_rate_13: '7.02%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Guinaifen',
    app_rate_14: '5.26%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'trailblazer-harmony',
    app_rate_15: '5.26%',
    avg_round_15: 31760,
    app_flat_15: 1,
    char_16: 'Luocha',
    app_rate_16: '5.26%',
    avg_round_16: 27680,
    app_flat_16: 2,
    char_17: 'Tingyun',
    app_rate_17: '5.26%',
    avg_round_17: 31120,
    app_flat_17: 2,
    char_18: 'Robin',
    app_rate_18: '3.51%',
    avg_round_18: 31800,
    app_flat_18: 2,
    char_19: 'tingyun-fugue',
    app_rate_19: '3.51%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'jing-yuan',
    app_rate_20: '3.51%',
    avg_round_20: 34400,
    app_flat_20: 2,
    char_21: 'Argenti',
    app_rate_21: '3.51%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Serval',
    app_rate_22: '3.51%',
    avg_round_22: 33440,
    app_flat_22: 1,
    char_23: 'Rappa',
    app_rate_23: '1.75%',
    avg_round_23: 31760,
    app_flat_23: 1,
    char_24: 'Lynx',
    app_rate_24: '1.75%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Pela',
    app_rate_25: '1.75%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Gepard',
    app_rate_26: '1.75%',
    avg_round_26: 32320,
    app_flat_26: 1,
    char_27: 'Jade',
    app_rate_27: '1.75%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'topaz',
    app_rate_28: '1.75%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Blade',
    app_rate_29: '1.75%',
    avg_round_29: 23720,
    app_flat_29: 1,
    char_30: 'Jiaoqiu',
    app_rate_30: '1.75%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Blade',
    app: 0.42,
    char_1: 'Jade',
    app_rate_1: '53.06%',
    avg_round_1: 29150,
    app_flat_1: 6,
    char_2: 'Robin',
    app_rate_2: '34.69%',
    avg_round_2: 26620,
    app_flat_2: 2,
    char_3: 'Sunday',
    app_rate_3: '34.69%',
    avg_round_3: 26200,
    app_flat_3: 3,
    char_4: 'fu-xuan',
    app_rate_4: '28.57%',
    avg_round_4: 29787,
    app_flat_4: 3,
    char_5: 'Bronya',
    app_rate_5: '24.49%',
    avg_round_5: 26107,
    app_flat_5: 3,
    char_6: 'Luocha',
    app_rate_6: '24.49%',
    avg_round_6: 27460,
    app_flat_6: 4,
    char_7: 'Huohuo',
    app_rate_7: '18.37%',
    avg_round_7: 27740,
    app_flat_7: 2,
    char_8: 'ruan-mei',
    app_rate_8: '10.2%',
    avg_round_8: 29320,
    app_flat_8: 2,
    char_9: 'Sparkle',
    app_rate_9: '10.2%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Jingliu',
    app_rate_10: '8.16%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Herta',
    app_rate_11: '8.16%',
    avg_round_11: 30120,
    app_flat_11: 3,
    char_12: 'Clara',
    app_rate_12: '6.12%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Gallagher',
    app_rate_13: '4.08%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Lingsha',
    app_rate_14: '4.08%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Yunli',
    app_rate_15: '4.08%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Lynx',
    app_rate_16: '4.08%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Aventurine',
    app_rate_17: '4.08%',
    avg_round_17: 23720,
    app_flat_17: 1,
    char_18: 'Himeko',
    app_rate_18: '4.08%',
    avg_round_18: 27200,
    app_flat_18: 2,
    char_19: 'Acheron',
    app_rate_19: '2.04%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Jiaoqiu',
    app_rate_20: '2.04%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'jing-yuan',
    app_rate_21: '2.04%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Kafka',
    app_rate_22: '2.04%',
    avg_round_22: 23720,
    app_flat_22: 1,
    char_23: 'Asta',
    app_rate_23: '2.04%',
    avg_round_23: 23720,
    app_flat_23: 1,
    char_24: 'topaz',
    app_rate_24: '2.04%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'tingyun-fugue',
    app_rate_25: '2.04%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Seele',
    app: 0.32,
    char_1: 'Robin',
    app_rate_1: '71.05%',
    avg_round_1: 33080,
    app_flat_1: 5,
    char_2: 'Sunday',
    app_rate_2: '47.37%',
    avg_round_2: 28760,
    app_flat_2: 4,
    char_3: 'Sparkle',
    app_rate_3: '42.11%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'fu-xuan',
    app_rate_4: '36.84%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Herta',
    app_rate_5: '31.58%',
    avg_round_5: 40000,
    app_flat_5: 2,
    char_6: 'Tingyun',
    app_rate_6: '15.79%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Huohuo',
    app_rate_7: '15.79%',
    avg_round_7: 29600,
    app_flat_7: 1,
    char_8: 'Bronya',
    app_rate_8: '10.53%',
    avg_round_8: 27880,
    app_flat_8: 1,
    char_9: 'ruan-mei',
    app_rate_9: '5.26%',
    avg_round_9: 40000,
    app_flat_9: 1,
    char_10: 'Aventurine',
    app_rate_10: '5.26%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Jade',
    app_rate_11: '2.63%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Lingsha',
    app_rate_12: '2.63%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'silver-wolf',
    app_rate_13: '2.63%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Acheron',
    app_rate_14: '2.63%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Gallagher',
    app_rate_15: '2.63%',
    avg_round_15: 27920,
    app_flat_15: 1,
    char_16: 'march-7th-swordmaster',
    app_rate_16: '2.63%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lynx',
    app: 0.31,
    char_1: 'Herta',
    app_rate_1: '35.14%',
    avg_round_1: 30392,
    app_flat_1: 10,
    char_2: 'Robin',
    app_rate_2: '29.73%',
    avg_round_2: 33291,
    app_flat_2: 7,
    char_3: 'Himeko',
    app_rate_3: '21.62%',
    avg_round_3: 27176,
    app_flat_3: 7,
    char_4: 'Sunday',
    app_rate_4: '21.62%',
    avg_round_4: 35080,
    app_flat_4: 5,
    char_5: 'Sparkle',
    app_rate_5: '21.62%',
    avg_round_5: 29287,
    app_flat_5: 6,
    char_6: 'Yunli',
    app_rate_6: '21.62%',
    avg_round_6: 29827,
    app_flat_6: 5,
    char_7: 'Acheron',
    app_rate_7: '18.92%',
    avg_round_7: 27840,
    app_flat_7: 4,
    char_8: 'Kafka',
    app_rate_8: '13.51%',
    avg_round_8: 27453,
    app_flat_8: 3,
    char_9: 'ruan-mei',
    app_rate_9: '13.51%',
    avg_round_9: 29640,
    app_flat_9: 3,
    char_10: 'Tingyun',
    app_rate_10: '13.51%',
    avg_round_10: 31680,
    app_flat_10: 3,
    char_11: 'Jade',
    app_rate_11: '13.51%',
    avg_round_11: 40000,
    app_flat_11: 2,
    char_12: 'black-swan',
    app_rate_12: '8.11%',
    avg_round_12: 31100,
    app_flat_12: 2,
    char_13: 'Bronya',
    app_rate_13: '8.11%',
    avg_round_13: 32500,
    app_flat_13: 2,
    char_14: 'Clara',
    app_rate_14: '8.11%',
    avg_round_14: 25460,
    app_flat_14: 2,
    char_15: 'march-7th-swordmaster',
    app_rate_15: '8.11%',
    avg_round_15: 28680,
    app_flat_15: 1,
    char_16: 'Blade',
    app_rate_16: '5.41%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Argenti',
    app_rate_17: '5.41%',
    avg_round_17: 37360,
    app_flat_17: 2,
    char_18: 'Pela',
    app_rate_18: '5.41%',
    avg_round_18: 26680,
    app_flat_18: 1,
    char_19: 'jing-yuan',
    app_rate_19: '5.41%',
    avg_round_19: 34680,
    app_flat_19: 1,
    char_20: 'Jiaoqiu',
    app_rate_20: '5.41%',
    avg_round_20: 34200,
    app_flat_20: 1,
    char_21: 'Serval',
    app_rate_21: '2.7%',
    avg_round_21: 30280,
    app_flat_21: 1,
    char_22: 'Rappa',
    app_rate_22: '2.7%',
    avg_round_22: 31120,
    app_flat_22: 1,
    char_23: 'trailblazer-harmony',
    app_rate_23: '2.7%',
    avg_round_23: 31120,
    app_flat_23: 1,
    char_24: 'Sampo',
    app_rate_24: '2.7%',
    avg_round_24: 27680,
    app_flat_24: 1,
    char_25: 'Asta',
    app_rate_25: '2.7%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Feixiao',
    app_rate_26: '2.7%',
    avg_round_26: 29640,
    app_flat_26: 1,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Moze',
    app: 0.2,
    char_1: 'Aventurine',
    app_rate_1: '91.67%',
    avg_round_1: 28749,
    app_flat_1: 7,
    char_2: 'Robin',
    app_rate_2: '66.67%',
    avg_round_2: 29112,
    app_flat_2: 5,
    char_3: 'Feixiao',
    app_rate_3: '50.0%',
    avg_round_3: 27380,
    app_flat_3: 2,
    char_4: 'jing-yuan',
    app_rate_4: '20.83%',
    avg_round_4: 29820,
    app_flat_4: 2,
    char_5: 'Sunday',
    app_rate_5: '20.83%',
    avg_round_5: 29820,
    app_flat_5: 2,
    char_6: 'dr-ratio',
    app_rate_6: '12.5%',
    avg_round_6: 24000,
    app_flat_6: 1,
    char_7: 'Yunli',
    app_rate_7: '8.33%',
    avg_round_7: 33400,
    app_flat_7: 2,
    char_8: 'Luocha',
    app_rate_8: '4.17%',
    avg_round_8: 27560,
    app_flat_8: 1,
    char_9: 'Himeko',
    app_rate_9: '4.17%',
    avg_round_9: 23600,
    app_flat_9: 1,
    char_10: 'Herta',
    app_rate_10: '4.17%',
    avg_round_10: 23600,
    app_flat_10: 1,
    char_11: 'Welt',
    app_rate_11: '4.17%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Tingyun',
    app_rate_12: '4.17%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Clara',
    app_rate_13: '4.17%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'topaz',
    app_rate_14: '4.17%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Boothill',
    app: 0.17,
    char_1: 'trailblazer-harmony',
    app_rate_1: '55.0%',
    avg_round_1: 33880,
    app_flat_1: 4,
    char_2: 'ruan-mei',
    app_rate_2: '50.0%',
    avg_round_2: 31840,
    app_flat_2: 3,
    char_3: 'Rappa',
    app_rate_3: '35.0%',
    avg_round_3: 40000,
    app_flat_3: 2,
    char_4: 'Acheron',
    app_rate_4: '20.0%',
    avg_round_4: 28507,
    app_flat_4: 3,
    char_5: 'Pela',
    app_rate_5: '20.0%',
    avg_round_5: 29627,
    app_flat_5: 3,
    char_6: 'tingyun-fugue',
    app_rate_6: '20.0%',
    avg_round_6: 40000,
    app_flat_6: 1,
    char_7: 'Jiaoqiu',
    app_rate_7: '15.0%',
    avg_round_7: 29460,
    app_flat_7: 2,
    char_8: 'Lingsha',
    app_rate_8: '15.0%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Aventurine',
    app_rate_9: '15.0%',
    avg_round_9: 26080,
    app_flat_9: 2,
    char_10: 'Gallagher',
    app_rate_10: '10.0%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Luocha',
    app_rate_11: '10.0%',
    avg_round_11: 26600,
    app_flat_11: 1,
    char_12: 'Argenti',
    app_rate_12: '5.0%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Sunday',
    app_rate_13: '5.0%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'imbibitor-lunae',
    app_rate_14: '5.0%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Bronya',
    app_rate_15: '5.0%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Herta',
    app_rate_16: '5.0%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'dr-ratio',
    app_rate_17: '5.0%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Clara',
    app_rate_18: '5.0%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dr-ratio',
    app: 0.14,
    char_1: 'Aventurine',
    app_rate_1: '76.47%',
    avg_round_1: 26096,
    app_flat_1: 5,
    char_2: 'Robin',
    app_rate_2: '70.59%',
    avg_round_2: 25293,
    app_flat_2: 6,
    char_3: 'Clara',
    app_rate_3: '23.53%',
    avg_round_3: 26360,
    app_flat_3: 2,
    char_4: 'Kafka',
    app_rate_4: '17.65%',
    avg_round_4: 24180,
    app_flat_4: 2,
    char_5: 'Moze',
    app_rate_5: '17.65%',
    avg_round_5: 24000,
    app_flat_5: 1,
    char_6: 'Acheron',
    app_rate_6: '11.76%',
    avg_round_6: 23760,
    app_flat_6: 1,
    char_7: 'Huohuo',
    app_rate_7: '11.76%',
    avg_round_7: 22520,
    app_flat_7: 2,
    char_8: 'Herta',
    app_rate_8: '5.88%',
    avg_round_8: 29160,
    app_flat_8: 1,
    char_9: 'Welt',
    app_rate_9: '5.88%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Tingyun',
    app_rate_10: '5.88%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Jiaoqiu',
    app_rate_11: '5.88%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Yunli',
    app_rate_12: '5.88%',
    avg_round_12: 21280,
    app_flat_12: 1,
    char_13: 'Jade',
    app_rate_13: '5.88%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'topaz',
    app_rate_14: '5.88%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'silver-wolf',
    app_rate_15: '5.88%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Boothill',
    app_rate_16: '5.88%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Luocha',
    app_rate_17: '5.88%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '5.88%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Sunday',
    app_rate_19: '5.88%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th',
    app: 0.14,
    char_1: 'Acheron',
    app_rate_1: '43.75%',
    avg_round_1: 30693,
    app_flat_1: 3,
    char_2: 'Robin',
    app_rate_2: '43.75%',
    avg_round_2: 31500,
    app_flat_2: 2,
    char_3: 'Herta',
    app_rate_3: '43.75%',
    avg_round_3: 30853,
    app_flat_3: 3,
    char_4: 'Himeko',
    app_rate_4: '31.25%',
    avg_round_4: 30600,
    app_flat_4: 2,
    char_5: 'black-swan',
    app_rate_5: '25.0%',
    avg_round_5: 32860,
    app_flat_5: 2,
    char_6: 'Pela',
    app_rate_6: '18.75%',
    avg_round_6: 26360,
    app_flat_6: 1,
    char_7: 'Jade',
    app_rate_7: '18.75%',
    avg_round_7: 29560,
    app_flat_7: 1,
    char_8: 'Kafka',
    app_rate_8: '18.75%',
    avg_round_8: 32860,
    app_flat_8: 2,
    char_9: 'Bronya',
    app_rate_9: '6.25%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Sparkle',
    app_rate_10: '6.25%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Clara',
    app_rate_11: '6.25%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Tingyun',
    app_rate_12: '6.25%',
    avg_round_12: 31360,
    app_flat_12: 1,
    char_13: 'Rappa',
    app_rate_13: '6.25%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'trailblazer-harmony',
    app_rate_14: '6.25%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'ruan-mei',
    app_rate_15: '6.25%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'silver-wolf',
    app_rate_16: '6.25%',
    avg_round_16: 26360,
    app_flat_16: 1,
    char_17: 'tingyun-fugue',
    app_rate_17: '6.25%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Sampo',
    app: 0.12,
    char_1: 'Acheron',
    app_rate_1: '71.43%',
    avg_round_1: 28486,
    app_flat_1: 7,
    char_2: 'Kafka',
    app_rate_2: '50.0%',
    avg_round_2: 31240,
    app_flat_2: 2,
    char_3: 'Pela',
    app_rate_3: '21.43%',
    avg_round_3: 26640,
    app_flat_3: 3,
    char_4: 'Aventurine',
    app_rate_4: '21.43%',
    avg_round_4: 27880,
    app_flat_4: 2,
    char_5: 'black-swan',
    app_rate_5: '21.43%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Huohuo',
    app_rate_6: '21.43%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'fu-xuan',
    app_rate_7: '21.43%',
    avg_round_7: 25760,
    app_flat_7: 2,
    char_8: 'ruan-mei',
    app_rate_8: '14.29%',
    avg_round_8: 34800,
    app_flat_8: 1,
    char_9: 'Lynx',
    app_rate_9: '7.14%',
    avg_round_9: 27680,
    app_flat_9: 1,
    char_10: 'Guinaifen',
    app_rate_10: '7.14%',
    avg_round_10: 27360,
    app_flat_10: 1,
    char_11: 'Himeko',
    app_rate_11: '7.14%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Herta',
    app_rate_12: '7.14%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Jiaoqiu',
    app_rate_13: '7.14%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Sparkle',
    app_rate_14: '7.14%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Bronya',
    app_rate_15: '7.14%',
    avg_round_15: 29640,
    app_flat_15: 1,
    char_16: 'Gallagher',
    app_rate_16: '7.14%',
    avg_round_16: 29640,
    app_flat_16: 1,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Hanya',
    app: 0.11,
    char_1: 'Argenti',
    app_rate_1: '61.54%',
    avg_round_1: 29147,
    app_flat_1: 5,
    char_2: 'Sunday',
    app_rate_2: '46.15%',
    avg_round_2: 31860,
    app_flat_2: 4,
    char_3: 'jing-yuan',
    app_rate_3: '30.77%',
    avg_round_3: 31860,
    app_flat_3: 4,
    char_4: 'Huohuo',
    app_rate_4: '30.77%',
    avg_round_4: 29293,
    app_flat_4: 3,
    char_5: 'fu-xuan',
    app_rate_5: '15.38%',
    avg_round_5: 32400,
    app_flat_5: 2,
    char_6: 'Luocha',
    app_rate_6: '15.38%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Aventurine',
    app_rate_7: '15.38%',
    avg_round_7: 30320,
    app_flat_7: 1,
    char_8: 'Jiaoqiu',
    app_rate_8: '7.69%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Sparkle',
    app_rate_9: '7.69%',
    avg_round_9: 29080,
    app_flat_9: 1,
    char_10: 'Herta',
    app_rate_10: '7.69%',
    avg_round_10: 31200,
    app_flat_10: 1,
    char_11: 'Natasha',
    app_rate_11: '7.69%',
    avg_round_11: 31200,
    app_flat_11: 1,
    char_12: 'Welt',
    app_rate_12: '7.69%',
    avg_round_12: 27160,
    app_flat_12: 1,
    char_13: 'Lingsha',
    app_rate_13: '7.69%',
    avg_round_13: 27160,
    app_flat_13: 1,
    char_14: 'Robin',
    app_rate_14: '7.69%',
    avg_round_14: 26480,
    app_flat_14: 1,
    char_15: 'Acheron',
    app_rate_15: '7.69%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'silver-wolf',
    app_rate_16: '7.69%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Bronya',
    app_rate_17: '7.69%',
    avg_round_17: 40000,
    app_flat_17: 1,
    char_18: 'Tingyun',
    app_rate_18: '7.69%',
    avg_round_18: 40000,
    app_flat_18: 1,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-preservation',
    app: 0.1,
    char_1: 'Acheron',
    app_rate_1: '83.33%',
    avg_round_1: 31587,
    app_flat_1: 3,
    char_2: 'Pela',
    app_rate_2: '50.0%',
    avg_round_2: 31000,
    app_flat_2: 2,
    char_3: 'Sparkle',
    app_rate_3: '41.67%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Kafka',
    app_rate_4: '16.67%',
    avg_round_4: 32760,
    app_flat_4: 1,
    char_5: 'black-swan',
    app_rate_5: '16.67%',
    avg_round_5: 31360,
    app_flat_5: 1,
    char_6: 'Jiaoqiu',
    app_rate_6: '16.67%',
    avg_round_6: 32760,
    app_flat_6: 1,
    char_7: 'Guinaifen',
    app_rate_7: '8.33%',
    avg_round_7: 30640,
    app_flat_7: 1,
    char_8: 'Firefly',
    app_rate_8: '8.33%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'tingyun-fugue',
    app_rate_9: '8.33%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Lingsha',
    app_rate_10: '8.33%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'silver-wolf',
    app_rate_11: '8.33%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Bronya',
    app_rate_12: '8.33%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Himeko',
    app_rate_13: '8.33%',
    avg_round_13: 25520,
    app_flat_13: 1,
    char_14: 'Herta',
    app_rate_14: '8.33%',
    avg_round_14: 25520,
    app_flat_14: 1,
    char_15: 'Gallagher',
    app_rate_15: '8.33%',
    avg_round_15: 25520,
    app_flat_15: 1,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Natasha',
    app: 0.1,
    char_1: 'Herta',
    app_rate_1: '41.67%',
    avg_round_1: 32400,
    app_flat_1: 2,
    char_2: 'Kafka',
    app_rate_2: '33.33%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Acheron',
    app_rate_3: '25.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Jade',
    app_rate_4: '25.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'black-swan',
    app_rate_5: '16.67%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Sunday',
    app_rate_6: '16.67%',
    avg_round_6: 40000,
    app_flat_6: 1,
    char_7: 'Himeko',
    app_rate_7: '16.67%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Bronya',
    app_rate_8: '16.67%',
    avg_round_8: 33600,
    app_flat_8: 1,
    char_9: 'Argenti',
    app_rate_9: '16.67%',
    avg_round_9: 35600,
    app_flat_9: 2,
    char_10: 'Sparkle',
    app_rate_10: '16.67%',
    avg_round_10: 40000,
    app_flat_10: 1,
    char_11: 'ruan-mei',
    app_rate_11: '16.67%',
    avg_round_11: 33760,
    app_flat_11: 1,
    char_12: 'Jiaoqiu',
    app_rate_12: '8.33%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Robin',
    app_rate_13: '8.33%',
    avg_round_13: 33600,
    app_flat_13: 1,
    char_14: 'Rappa',
    app_rate_14: '8.33%',
    avg_round_14: 33760,
    app_flat_14: 1,
    char_15: 'trailblazer-harmony',
    app_rate_15: '8.33%',
    avg_round_15: 33760,
    app_flat_15: 1,
    char_16: 'Hanya',
    app_rate_16: '8.33%',
    avg_round_16: 31200,
    app_flat_16: 1,
    char_17: 'Yunli',
    app_rate_17: '8.33%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Tingyun',
    app_rate_18: '8.33%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Xueyi',
    app: 0.08,
    char_1: 'Herta',
    app_rate_1: '77.78%',
    avg_round_1: 28528,
    app_flat_1: 5,
    char_2: 'Himeko',
    app_rate_2: '66.67%',
    avg_round_2: 28040,
    app_flat_2: 4,
    char_3: 'Robin',
    app_rate_3: '33.33%',
    avg_round_3: 30480,
    app_flat_3: 1,
    char_4: 'Gallagher',
    app_rate_4: '22.22%',
    avg_round_4: 28760,
    app_flat_4: 2,
    char_5: 'Aventurine',
    app_rate_5: '22.22%',
    avg_round_5: 28120,
    app_flat_5: 1,
    char_6: 'Firefly',
    app_rate_6: '11.11%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'tingyun-fugue',
    app_rate_7: '11.11%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'trailblazer-harmony',
    app_rate_8: '11.11%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Sunday',
    app_rate_9: '11.11%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Huohuo',
    app_rate_10: '11.11%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'fu-xuan',
    app_rate_11: '11.11%',
    avg_round_11: 25400,
    app_flat_11: 1,
    char_12: 'ruan-mei',
    app_rate_12: '11.11%',
    avg_round_12: 31600,
    app_flat_12: 1,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Qingque',
    app: 0.05,
    char_1: 'Sparkle',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'fu-xuan',
    app_rate_2: '83.33%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Yunli',
    app_rate_3: '33.33%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Robin',
    app_rate_4: '33.33%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Sunday',
    app_rate_5: '16.67%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Jade',
    app_rate_6: '16.67%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Herta',
    app_rate_7: '16.67%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Arlan',
    app: 0.03,
    char_1: 'Firefly',
    app_rate_1: '66.67%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'trailblazer-harmony',
    app_rate_2: '66.67%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Himeko',
    app_rate_3: '33.33%',
    avg_round_3: 24160,
    app_flat_3: 1,
    char_4: 'Herta',
    app_rate_4: '33.33%',
    avg_round_4: 24160,
    app_flat_4: 1,
    char_5: 'Aventurine',
    app_rate_5: '33.33%',
    avg_round_5: 24160,
    app_flat_5: 1,
    char_6: 'ruan-mei',
    app_rate_6: '33.33%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Lingsha',
    app_rate_7: '33.33%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Misha',
    app: 0.03,
    char_1: 'Argenti',
    app_rate_1: '33.33%',
    avg_round_1: 24000,
    app_flat_1: 1,
    char_2: 'trailblazer-harmony',
    app_rate_2: '33.33%',
    avg_round_2: 24000,
    app_flat_2: 1,
    char_3: 'Gallagher',
    app_rate_3: '33.33%',
    avg_round_3: 24000,
    app_flat_3: 1,
    char_4: 'Himeko',
    app_rate_4: '33.33%',
    avg_round_4: 24800,
    app_flat_4: 1,
    char_5: 'topaz',
    app_rate_5: '33.33%',
    avg_round_5: 24800,
    app_flat_5: 1,
    char_6: 'Lingsha',
    app_rate_6: '33.33%',
    avg_round_6: 24800,
    app_flat_6: 1,
    char_7: 'Rappa',
    app_rate_7: '33.33%',
    avg_round_7: 24240,
    app_flat_7: 1,
    char_8: 'ruan-mei',
    app_rate_8: '33.33%',
    avg_round_8: 24240,
    app_flat_8: 1,
    char_9: 'Luocha',
    app_rate_9: '33.33%',
    avg_round_9: 24240,
    app_flat_9: 1,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yukong',
    app: 0.02,
    char_1: 'Acheron',
    app_rate_1: '50.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'black-swan',
    app_rate_2: '50.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Aventurine',
    app_rate_3: '50.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Himeko',
    app_rate_4: '50.0%',
    avg_round_4: 27920,
    app_flat_4: 1,
    char_5: 'Herta',
    app_rate_5: '50.0%',
    avg_round_5: 27920,
    app_flat_5: 1,
    char_6: 'Gallagher',
    app_rate_6: '50.0%',
    avg_round_6: 27920,
    app_flat_6: 1,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yanqing',
    app: 0.01,
    char_1: 'Jade',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'Herta',
    app_rate_2: '100.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Sparkle',
    app_rate_3: '100.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: '-',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
