import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import '../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lodash from 'lodash';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { IGFLWeapon } from '../../modules/common/model/graphql-types';
import { Row, Col } from 'react-bootstrap';
import { GFLWeaponDB } from '../../modules/exilium/components/exilium-weapon-db';

interface IGFLCharacterNodes {
  nodes: IGFLWeapon[];
}

interface IGFLCharacterEntry {
  allWeapons: IGFLCharacterNodes;
}

interface IProps {
  data: IGFLCharacterEntry;
}

const ExiliumCharaPage: React.FC<IProps> = ({ data }) => {
  const [visibleWeapons, setVisibleWeapons] = useState(data.allWeapons.nodes);

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search Weapons...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: 'SR', value: 'SR' },
          { label: 'SSR', value: 'SSR' }
        ]
      },
      {
        key: 'weapon',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            label: 'AR',
            tooltip: 'Assault Rifle',
            value: 'Assault Rifle'
          },
          {
            label: 'HG',
            tooltip: 'Handgun',
            value: 'Handgun'
          },
          {
            label: 'MG',
            tooltip: 'Machine Gun',
            value: 'LMG'
          },
          {
            label: 'SG',
            tooltip: 'Shotgun',
            value: 'Shotgun'
          },
          {
            label: 'SMG',
            tooltip: 'SMG',
            value: 'SMG'
          },
          {
            label: 'RF',
            tooltip: 'Rifle',
            value: 'Sniper Rifle'
          },
          {
            label: 'BLD',
            tooltip: 'Blade',
            value: 'Sword'
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allWeapons.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.weapon && activeFilters.weapon !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.weapon.toLowerCase() === activeFilters.weapon.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleWeapons(filtered);
  }, [activeFilters, data.allWeapons.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page weapons-gfl'} game="exilium">
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>Weapons List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/exilium/categories/category_weapons.webp"
            alt="Weapons"
          />
        </div>
        <div className="page-details">
          <h1>Girls' Frontline 2: Exilium Weapons List</h1>
          <h2>
            All Weapons you can obtain in Girls' Frontline 2: Exilium (GFL 2).
          </h2>
          <p>
            Last updated: <strong>22/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Weapons"
        subtitle={
          <span className="additional-text">
            Showing {visibleWeapons.length} Weapon
            {visibleWeapons.length !== 1 && 's'}
          </span>
        }
      />
      <div className="employees-filter-bar gfl">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <Row xs={1} xxl={2} className="relic-set-container">
        {visibleWeapons.map((emp, index) => {
          return (
            <Col key={index}>
              <GFLWeaponDB name={emp.name} mode="box" />
            </Col>
          );
        })}
      </Row>
      {visibleWeapons.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../images/exilium/no_results.jpg"
            alt="No results"
          />
          <p>No Weapons found. Try changing your filter.</p>
        </div>
      )}
      <br /> <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ExiliumCharaPage;

export const Head: React.FC = () => (
  <Seo
    title="Weapons | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="All Weapons you can obtain in Girls' Frontline 2: Exilium (GFL 2)."
    game="exilium"
  />
);

export const pageQuery = graphql`
  query {
    allWeapons: allContentfulGflWeapon(sort: { fields: name, order: ASC }) {
      nodes {
        id
        weaponId
        name
        rarity
        weapon
        cnOnly
      }
    }
  }
`;
