import React from 'react';
import { HSRCharacter } from '../common/components/hsr-character';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../common/components/youtube-embed';

interface IProps {
  slug: string;
}

export const ProfileSynergies: React.FC<IProps> = ({ slug }) => {
  return (
    <>
      {slug != 'sunday' && (
        <p>
          Below you can find a list of some of the stronger synergies between
          this character and others.
        </p>
      )}
      {slug === 'test' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="firefly" enablePopover />{' '}
          </li>
          <li>
            <HSRCharacter mode="inline" slug="ruan-mei" enablePopover />
          </li>
          <li>
            <HSRCharacter mode="inline" slug="lingsha" enablePopover />
          </li>
        </ul>
      )}
      {slug === 'acheron' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="jiaoqiu" enablePopover /> is
            Acheron’s premier amplifier and Slashed Dream points generator,
            capable of applying debuffs during enemy actions while also
            fulfilling Acheron’s Nihility teammate requirements. The
            Vulnerability debuff he can apply is something Acheron lacks in her
            own kit, making it valuable as his kit alone can cause the targets
            to take 50% increased DMG from Ultimates.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="silver-wolf" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="pela" enablePopover /> can be used
            as the second Nihility teammate for Acheron with Silver Wolf being
            stronger against Bosses, and Pela being stronger against AoE
            contents.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="sunday" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="sparkle" enablePopover /> can be
            used in place of the second Nihility teammate, enabling Acheron to
            take more actions to generate Slashed Dream points by herself. This
            becomes notable when Acheron has her Eidolon 2 unlocked, allowing
            her to generate 3 Slashed Dream points per turn, and the buffs
            provided by these Harmony Supports are usually stronger than the
            amplification other Nihility units can provide. But, they can be
            considered even for Eidolon 0 Acheron.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="kafka" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="black-swan" enablePopover /> form
            the Dotcheron setup, but sadly its glorious days are over and they
            are way inferior to Jiaoqiu and only viable in Pure Fiction
            nowadays.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="tingyun-fugue" enablePopover />{' '}
            only really has one major synergy with Acheron and that's to act as
            superpowered Debuff battery. Fugue herself can apply debuffs with
            Nihility Cone but can also grant a team member the ability to do the
            same with her Foxian Prayer - buffing an ally that attacks
            frequently with this like Lingsha or Aventurine leads to an
            incredible amount of charges for Acheron. With that said as Fugue
            isn't an intended support for Acheron you may have more success with
            supports who can directly buff her instead,
          </li>
          <li>
            <HSRCharacter mode="inline" slug="fu-xuan" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="aventurine" enablePopover /> - can
            equip the “Trend of the Universal Market” Light Cone for a chance at
            applying debuffs during enemy actions to generate Slashed Dream
            points for Acheron, and both of them can provide minor CRIT bonuses
            for Acheron.
          </li>
        </ul>
      )}
      {slug === 'argenti' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="the-herta" enablePopover /> -
            similar to Serval, Argenti can be used as Ult spammer to provide
            more stacks for The Herta (and he brings more personal damage than
            the Serval).
          </li>
          <li>
            <HSRCharacter mode="inline" slug="sunday" enablePopover /> provides
            a lot of buffs, action advance AND Energy regen. Everything that
            Argenti wants and need, making him the best partner for him moving
            forward.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="tingyun" enablePopover />
            is a straightforward and well-rounded buffer for Argenti as she
            provides a lot Energy as well as buffing both his ATK% and DMG%.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="robin" enablePopover /> works with
            virtually everyone and you can use her with Argenti too, giving him
            even more actions.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="huohuo" enablePopover /> is a
            Sustain with a teamwide Energy Battery Ultimate, basically infinite
            cleanses, and is able to utilize Light Cones like Quid Pro Quo for
            even more energy. This puts her on top in regards to Sustenance,
            however she does have the drawback of being Skill Point Neutral
            unless she is E1.
          </li>
        </ul>
      )}
      {slug === 'arlan' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="aventurine" enablePopover /> &
            <HSRCharacter mode="inline" slug="gepard" enablePopover /> - Arlan’s
            biggest issue is surviving, so with the biggest shields in the game,
            Aventurine or Gepard can take care of the issue for him. Further,
            Aventurine can not only provide a CRIT Dmg debuff to the enemy, but
            also more frequent shielding compared to Gepard, tipping things in
            his favour.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="sunday" enablePopover /> being the
            new primary Hypercarry support works well with everyone - including
            Arlan.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="sparkle" enablePopover /> &
            <HSRCharacter mode="inline" slug="bronya" enablePopover /> -
            Considering Arlan doesn’t use Skill Points to use Skills, this
            allows him to take great advantage of Bronya or Sparkle who can
            always be played Skill Point Negative.
          </li>
        </ul>
      )}
      {slug === 'asta' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="kafka" enablePopover /> has one of
            the best synergies with Asta as she provides a massive speed boost
            and a constant ATK% boost which can be easily kept on max stacks in
            most teams. Asta can also perform Basic Attacks often to take
            advantage of Kafka’s Follow-Up Attacks.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="black-swan" enablePopover /> can
            utilize Asta’s Speed buff very well by stacking more Arcana between
            enemy actions, at the cost of Skill Points compared to having Ruan
            Mei supporting her. Asta also provides Burn on her Basic, which
            allows Black Swan to apply even more Arcana stacks per attack.
          </li>
        </ul>
      )}
      {slug === 'aventurine' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="feixiao" enablePopover /> &
            <HSRCharacter mode="inline" slug="dr-ratio" enablePopover /> &
            <HSRCharacter mode="inline" slug="topaz" enablePopover /> - Being
            the premium Sustain for Follow-up Attackers, Aventurine works very
            well with most characters, but has insane synergy with the current
            Follow-up Attack.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="robin" enablePopover /> -
            Aventurine's frequent Follow-up ATKs can trigger Robin's Additional
            DMG Proc while also regenerating Energy for Robin, while Robin
            offers 45% CRIT DMG and some DMG% on Follow-up ATKs. Aventurine can
            also spam his Basic ATK to make up for Robin's inability to generate
            Skill Points during Concerto.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="acheron" enablePopover /> - With
            the ability to apply a debuff on his Ultimate, his Follow-Up Attack
            if using his Light Cone, or when he gets hit if using the Trend
            Light Cone, allows for a lot of extra Slashed Dream stacks for
            Acheron. For those who have invested in Aventurine, he also provides
            a debuff on every Basic Attack he uses, further increasing his
            debuff application presence.
          </li>
        </ul>
      )}
      {slug === 'bailu' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="blade" enablePopover /> - Being
            the only character who currently benefits from having their max HP
            increased for damage, Bailu provides some of the strongest
            mitigation for Blade who will consistently be below half health, as
            well as energy if put with a Quid Pro Quo Light Cone to get more
            Ultimates.
          </li>
        </ul>
      )}
      {slug === 'black-swan' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="kafka" enablePopover /> - The DoT
            queen who has the best synergies in the game with Black Swan, Kafka
            has the ability to inflict multiple DoTs to increase Black Swan’s
            Arcana count per application, alongside triggering those DoTs to
            further increase the Arcana stacks resulting in massive damage.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="ruan-mei" enablePopover /> -
            Providing all the buffs that Black Swan uses places Ruan Mei at the
            top of the list for Black Swans’ buffers. DMG%, RES PEN%, Speed%,
            and even DEF shred at E1, combined with extra DMG% buff from her
            Light Cone if you have it, makes her the perfect all-rounded partner
            for Black Swan.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="sampo" enablePopover /> &
            <HSRCharacter mode="inline" slug="guinaifen" enablePopover /> are
            the cheaper F2P alternates of Kafka, however they can also just be
            paired with Kafka and Black Swan, with both of them “triggering”
            their respective DoTs on the enemy, Sampo with his Skill at E4, and
            Guinaifen on her Ultimate. Both of them give double the stacks when
            Black Swan has used her Ultimate and the enemy is afflicted with
            Epiphany.
          </li>
        </ul>
      )}
      {slug === 'blade' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="jade" enablePopover /> and Blade
            share incredible synergy. Blade benefits Jade as he possesses: a
            Follow-Up attack capable of hitting up to 5 enemies which triggers
            often, a relatively accessible Ultimate, a Basic Attack that always
            hits 3 targets and a teammate that doubles his total actions
            (Bronya) resulting in a good double damage partner and plenty of
            Charges for Jade. It doesn't stop there though as Jade also benefits
            Blade - her Debt Collector buff causes Blade to take an additional
            instance of damage when using all of his abilities, allowing him to
            gain 2 stacks of his Talent instead of 1 when using his Enhanced
            Basic Attack and Ultimate which feeds more into Jade. Basically,
            it's a feeding loop where both benefit a lot.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="sunday" enablePopover /> &
            <HSRCharacter mode="inline" slug="bronya" enablePopover /> are both
            great Amplifier options that can be played in the -1 Speed setup
            with Blade.
          </li>
        </ul>
      )}
      {slug === 'boothill' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="tingyun-fugue" enablePopover /> -
            With Fugue, Boothill now has the potential to gain all 3 Pocket
            Trickshots in just 1 attack: 1 from the standard Break, 1 from the
            "Cloudflame Luster" Break and 1 from getting the kill. Pulling this
            off will require Boothill to be able to deal enough Toughness damage
            to Break through the standard Toughness bar and extra 40% bar but is
            definitely worth going for. Fugue also doubles his Weakness Break
            DMG as he gets 2 Breaks instead of 1 per target and allows him to
            deal with non-Physical weak enemies without having to rely on his
            Ultimate.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="sunday" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="bronya" enablePopover />{' '}
            Boothill’s biggest weakness is his ramp-up time from being Single
            Target and needing to get his Pocket Trickshots done asap to deal
            the most damage. Both Sunday and Bronya alleviate the ramp-up time
            if built with -1 Speed to Boothill, and can allow for much quicker
            rotations. Out of the two, Sunday is the primary choice if you have
            him.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="ruan-mei" enablePopover /> -
            Boothill loves everything Ruan Mei provides, Weakness Break
            efficiency, Break buff, RES PEN%, and even DEF shred at E1. The DMG%
            buff from her Signature and her Trace don’t provide a lot of value,
            however if built with some crit chance, can boost it enough to
            squeeze in a little bit more damage.
          </li>
          <li>
            <HSRCharacter
              mode="inline"
              slug="trailblazer-harmony"
              enablePopover
            />{' '}
            - While Boothill isn't reliant on outside Super Break sources like
            Firefly, the Harmony MC is still a great option to consider to run
            beside him.
          </li>
        </ul>
      )}
      {slug === 'bronya' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="blade" enablePopover />
            being a DPS who rarely uses any Skill Points allows Bronya to always
            skill on him for maximizing DPS output, while also utilising all of
            her buffs, unlike other Harmonies who are always missing 1 or 2
            buffs compared to Bronya.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="jingliu" enablePopover />
            has an awkward downtime after coming out of her Syzygy state, with a
            -1 Speed Bronya setup, you can skill with Jingliu into Bronya
            skilling her back up to go straight back into her Syzygy state and
            prolong her insane damage output.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="boothill" enablePopover /> biggest
            weakness is his ramp-up time from being Single Target and needing to
            get his Pocket Trickshots done asap to deal the most damage. Bronya
            alleviates the ramp-up time if built with -1 Speed to Boothill, and
            can allow for much quicker rotations, at the cost of additional
            Skill Point consumption.
          </li>
        </ul>
      )}
      {slug === 'clara' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="robin" enablePopover />
            allows Clara to go nuclear with the biggest attack buff in the game.
            Alongside a DMG% buff and a crit damage buff, with an additional
            crit damage buff to her Follow-Up Attacks, she triumphs over every
            other Support currently in when it comes to damage increase.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="sparkle" enablePopover />
            has the added benefit of leaving her CRIT DMG buff on Clara until
            her next turn - unlike Bronya, whose buff falls off immediately.
            This allows Sparkle’s buff to prolong Clara's turn applying to all
            her counters she will be utilising in the meantime.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="tingyun" enablePopover /> buffs
            are the most comfortable for Clara’s playstyle as a Main DPS, with
            her Ultimate refilling Clara’s energy to have near permanent uptime
            on her Enhanced Counters. Additionally she provides a DMG% and ATK%
            buff that have “long” durations, which are useful due to how slow
            Clara is.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="topaz" enablePopover />
            offers a Follow-Up Attack damage taken increase with her mark,
            allowing Clara's Counters to do a lot more damage to those targets,
            while also allowing Topaz to focus down Single Target enemies and
            Clara to take care of the rest of the field.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="huohuo" enablePopover /> is the
            choice for the Sustain spot in Clara teams, giver her great buffs
            and Energy.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="lynx" enablePopover /> is an
            alternative to Huohuo, helping Clara with being attacked more often.
            Just keep in mind Lynx's healing is a bit on the lower side.
          </li>
        </ul>
      )}
      {slug === 'dan-heng' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="sunday" enablePopover /> &
            <HSRCharacter mode="inline" slug="bronya" enablePopover />{' '}
            Generalist buffers that can utilise the -1 Speed setup for maximum
            amount of turns.
          </li>
        </ul>
      )}
      {slug === 'dr-ratio' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="robin" enablePopover /> is the
            premier choice for the Amplifier spot in any FUA team, making her
            perfect for Dr. Ratio.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="topaz" enablePopover /> provides
            one of the necessary debuffs Dr. Ratio needs for his Skill. With
            either E1 or her Light Cone, or with both, she can completely fill
            the debuff needs for him as well as apply some very strong Follow-Up
            Attack centric debuffs on the enemy. Topaz also gets benefits out of
            Dr. Ratio for when his Follow-Up Attack activates on the enemy with
            the Mark, Numby advances forward resulting in even more actions.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="moze" enablePopover /> - Dr. Ratio
            relies on enemies being debuffed at least 3 times (but ideally 5) in
            order to unleash his maximum potential - Moze relies on being paired
            with teammates that can attack as frequently as possible. Each of
            these character's solve each others problems perfectly.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="jiaoqiu" enablePopover /> - Dr.
            Ratio relies on his team to apply 3-5 debuffs to bring his kit fully
            online. This restrict his teammate options, but Jiaoqiu having 2
            semi-permanent debuffs and a 3rd from his Cone can do a lot to help
            with this. With Jiaoqiu bringing tons of debuffs, Ratio has a lot
            more options to choose from for the Amplifier and Sustain slots.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="pela" enablePopover /> is a free
            to play version of Jiaoqiu that provides him with debuffs and DEF
            Shred.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="aventurine" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="lingsha" enablePopover /> - The
            go-to Follow-up sustains that work very well with Dr. Ratio.
          </li>
        </ul>
      )}
      {slug === 'feixiao' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="robin" enablePopover /> is the
            premiere Support for Feixiao (and the Follow-up ATK archetype),
            providing the whole team with absurd buffs, Additional DMG procs,
            and a Teamwide Action Advance that will allow the whole team to go
            through their rotation and generate “Flying Aureus” points for
            Feixiao again.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="topaz" enablePopover />
            Proof of Debt debuff will cause the target to take 50% increased DMG
            from Feixiao’s Ultimate and Follow-up ATKs, while Numby will
            constantly receive Action Advance from Feixiao’s Follow-up ATKs,
            allowing Topaz & Numby to contribute respectable DMG and also
            allowing for the most “Flying Aureus” points generation for Feixiao.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="moze" enablePopover /> is able to
            deliver incredibly frequent Follow-Up attacks which assists Feixiao
            greatly in fueling her ultimate by generating Aureus stacks for her.
            Enemies Moze marks as Prey are also afflicted with a debuff which
            causes them to take 25% additional damage from Follow-Up attacks,
            boosting much of Feixiao's damage against them.
          </li>
          <li>
            <HSRCharacter
              mode="inline"
              slug="march-7th-swordmaster"
              enablePopover
            />{' '}
            - Designating Feixiao as the Shifu will provide both of them with a
            10% SPD buff, and a whooping 60% CRIT DMG buff for Feixiao after
            March 7th uses her Enhanced Basic ATK. March 7th can consistently
            provide “Flying Aureus” points for Feixiao through her Eidolon 2
            Follow-up ATK and her own Action Advance (which also resets the
            Eidolon 2 trigger count), and Feixiao can easily activate all of
            March 7th’s effects through her incredibly frequent attacks - for
            that it is recommended to have March 7th faster than Feixiao to
            reduce the chance of the Action Advance being wasted, since
            Feixiao’s Skill can easily chain up 5 more attacks.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="sunday" enablePopover /> &
            <HSRCharacter mode="inline" slug="bronya" enablePopover /> are both
            great Amplifier options that can be played in the -1 Speed setup
            with Feixiao if you don't have Robin yet.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="aventurine" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="lingsha" enablePopover /> The
            go-to Follow-up sustains that help Feixiao with fueling her Ultimate
            charges.
          </li>
        </ul>
      )}
      {slug === 'firefly' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter
              mode="inline"
              slug="trailblazer-harmony"
              enablePopover
            />{' '}
            is not just the best support for Firefly but borderline mandatory
            for her to perform at a high level. Harmony Trailblazer grants
            Firefly a large amount of Break Effect but more importantly, another
            far more powerful layer of Super Break, boosting her damage
            potential by an insane amount. For reference, Firefly's Super Break
            deals 50% Super Break damage but Harmony Trailblazer can deal up to
            160% Super Break damage.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="tingyun-fugue" enablePopover /> -
            As one of the premiere Break damage dealers, it's no surprise that
            Firefly would be more than happy to have Fugue join their team.
            Fugue Super Break is excellent, Exo Toughness is a nice burst of
            additional damage and Fugue's buffs and debuffs are also a great
            addition. However unlike other characters like Boothill, Rappa and
            Himeko, Firefly doesn't gain transformational benefit from the
            ability to Break enemies twice in quick succession, making Fugue
            useable with her but not revolutionary when a free and very capable
            Harmony Trailblazer exists. When factoring in Eidolons on either
            Fugue or Firefly things can change.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="ruan-mei" enablePopover /> has
            absurd levels of synergy with not just Firefly but also Harmony
            Trailblazer creating a holy trinity of Break decimation with all of
            their kits feeding into each other. What makes Ruan Mei so strong
            with Firefly and Trailblazer is her increase to Break Efficiency
            which directly scales Super Break damage - and it makes up the
            majority of the Firefly x Trailblazer duo's damage. Firefly has some
            Break Efficiency of her own and Ruan's does stack additively with
            that but even so, it's a huge damage increase. On top of this, Ruan
            Mei offers not only Break Effect and Speed to the team but also RES
            SHRED which of course massively increases BREAK and SUPER BREAK
            damage as well. Ruan's Speed buff specifically can make gearing up
            Firefly for the correct Speed breakpoints much easier.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="lingsha" enablePopover /> is an
            outstanding partner for Firefly, offering her excellent assistance
            with whittling enemies Toughness bars down quickly - especially in
            AoE - and providing her a solid Break damage taken multiplier with
            her Ultimate. Thanks to Firefly's Fire Weakness implant, Lingsha
            wont have any concerns about being able to damage enemy Toughness
            bars either. While Lingsha is strong with Firefly and her teammates
            (Ruan Mei and Harmony Trailblazer especially), at E0 Firefly is
            notoriously SP hungry - something Lingsha can struggle to keep up
            with in longer fights without careful planning. When playing this
            combo pay specific attention to Skill Point economy and try to get
            avoid wasting skill points.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="gallagher" enablePopover /> is
            Firefly's second best sustain offering her utility, damage, sustain,
            and consistency. One of Firefly's (and her teams) biggest issues at
            E0 is not having enough Skill Points. Firefly herself is an
            extremely fast character with an action advance built into her kit,
            Harmony Trailblazer would ideally use their skill whenever possible
            to maximize their damage and energy regeneration, and Ruan Mei wants
            to maintain her skill at all times. The result of this is not a lot
            of Skill Points to go around - something Gallagher as a character
            (with the potential to have the most positive skill point rotation
            of any sustain) helps immensely with.
          </li>
        </ul>
      )}
      {slug === 'fu-xuan' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="acheron" enablePopover /> -
            Providing the much needed CRIT Rate that Acheron wants is very
            useful in itself, she can also hold the Trend Light Cone for
            additional debuffs whenever Fu Xuan gets hit, giving a Slashed Dream
            when applied.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="seele" enablePopover /> - Giving
            the much needed CRIT Rate that Seele needs is extremely useful in
            itself. She also provides a 1 time CC ignore for stuff like
            Imprisons and Freezes, which prevents Seele’s pace slowdown, making
            it invaluable.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="silver-wolf" enablePopover /> -
            Being our first 5* Quantum Sustainer has its perk, and Silver Wolf
            takes good advantage of it. This Allows Silver Wolf to have one less
            Element to take into consideration for her Skill’s Implant. Silver
            Wolf can also take advantage of Fu Xuans Crit Rate% buff at E2 to be
            built as a Sub-DPS.
          </li>
        </ul>
      )}
      {slug === 'gallagher' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter
              mode="inline"
              slug="trailblazer-harmony"
              enablePopover
            />{' '}
            allows Gallagher to achieve some ridiculous Super Break numbers with
            his Besotted state from his Ultimate, increasing all Break damage on
            enemies, while also contributing as the Sustain, making him the
            perfect Sustain for any Break-centric teams currently.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="ruan-mei" enablePopover /> gives
            Gallagher an insane boost in content weak against Fire for Breaking,
            and in Super Break teams makes him nearly as strong as Harmony
            Trailblazer itself at E6 in terms of Super Break damage.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="acheron" enablePopover /> -
            Providing debuffs is one of the main things Acheron looks for in
            teammates, and Gallagher just so happens to provide 2 debuffs very
            quickly in short succession; 1 on Ultimate, and 1 on his Enhanced
            Basic, giving Acheron the much-needed debuffs she wants for quicker
            Ultimates.
          </li>
        </ul>
      )}
      {slug === 'gepard' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="acheron" enablePopover /> - Gepard
            has some of the biggest shields in the game and can reasonably get
            them back before they wear off with an inbuilt Taunt on his Trace.
            This works to his favour when wearing the Trend Light Cone, applying
            a debuff whenever an enemy hits him, giving Acheron her Slashed
            Dream stacks.
          </li>
        </ul>
      )}
      {slug === 'guinaifen' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="kafka" enablePopover /> can
            detonate any Burns that are on the enemy with her Skill and
            Ultimate, and if you’ve got E4 on Guinaifen, allows her to get her
            Ultimate 1 turn earlier depending on the rotation you’ve used.
            Guinaifen also acts as another DoT partner for Kafka.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="acheron" enablePopover /> -
            Guinaifen can be used in place of other Nihility characters for
            Acheron, however she only has a small DMG% taken debuff on Fire
            Kiss. This requires a few turns to stack up and can fall off if no
            Burn is on the enemy, compared to her counterparts such as Pela and
            Silver Wolf.
          </li>
        </ul>
      )}
      {slug === 'hanya' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="argenti" enablePopover /> can
            abuse the Burden effect from Hanya regardless of what enemy it is
            on, as Burden checks when the enemy is hit, and Argenti, with his
            AoE Skill and Ultimate, can trigger it easily.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="imbibitor-lunae" enablePopover />{' '}
            - Hanya can be built extremely fast and generate a ton of Skill
            Points for Imbibitor Lunae, which he heavily requires, as well as
            providing a nice speed and ATK% boost on her Ultimate.
          </li>
        </ul>
      )}
      {slug === 'herta' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="the-herta" enablePopover /> -
            "Mini Herta" is a reasonable alternative to Jade for The Herta in
            modes that feature weak and or respawnable enemies for her to
            trigger her Follow-Up talent against such as Pure Fiction.
            Unfortunately Mini Herta's follow-up's only count once for the
            purpose of The Herta's stacks and Energy generation no matter how
            many times she spins back to back, making her mostly suitable for
            Pure Fiction or for those without other erudition options.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="robin" enablePopover /> - Herta’s
            Follow-Up Attacks are very consistent with lots of enemies, however
            not having a secondary DPS in the party can make them hard to
            trigger. Robin alleviates this problem by giving a bunch of extra
            damage whenever anyone attacks, even your Supporters will now be
            able to trigger Herta’s Follow-Up Attack and allow her to chain
            them.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="himeko" enablePopover /> - Solely
            for Pure Fiction or AoE based content in general, Himeko is able to
            chain her own Follow-Up Attacks into triggering Herta’s Follow-Up
            Attacks, who may retrigger Himeko’s in turn, resulting into an
            endless loop of Follow-Up Attacks.
          </li>
        </ul>
      )}
      {slug === 'himeko' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="tingyun-fugue" enablePopover />{' '}
            "Cloudflame Luster" turbo charges Himeko's talent and Charge gain -
            so much so in fact it becomes difficult to make use of all the
            Charges she generates as Charges cannot exceed the limit. It's a
            funny but welcome consequence of too much success. Fugue also offers
            Himeko the break scaling she so depsperately needs via Superbreak on
            top of granting her the ability to finally deal with non-fire weak
            enemies all be it at 50% efficiency.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="robin" enablePopover /> -
            Providing a 100% Advance already makes Robin very strong for Himeko,
            but the insanely high ATK% buff from her Ultimate is one of the
            things Himeko just can’t get enough of.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="ruan-mei" enablePopover />{' '}
            provides the best all-rounded buffs for Himeko in both crit teams
            and Super Break teams. She provides the DMG% buff and Weakness Break
            efficiency to activate her Follow-Up Attacks quicker, as well as RES
            PEN% on Ultimate benefitting both Super Break and crits.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="herta" enablePopover /> - Solely
            for Pure Fiction or AoE based content in general, Herta is able to
            chain her own Follow-Up Attacks into triggering Himeko’s Follow-Up
            Attacks, who may retrigger Herta’s in turn, resulting into an
            endless loop of Follow-Up Attacks.
          </li>
        </ul>
      )}
      {slug === 'hook' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="tingyun" enablePopover /> - Hook’s
            main damage comes from getting quick enough Ultimates to be able to
            use her Enhanced Skill, with her Ultimate also advancing her next
            turn by 20%. Hook’s skill also turns a Single Target ability into a
            Blast Attack, hitting multiple enemies while also triggering her
            Talent for extra damage and a 5% heal with her Innocence Trace.
          </li>
        </ul>
      )}
      {slug === 'huohuo' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="argenti" enablePopover /> loves
            Huohuo’s energy as it scales off maximum Ultimate cost, which at
            maximum level regenerates up to 36 Energy. She tops it off with up
            to a 40% ATK boost for his burst window Ultimate.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="kafka" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="black-swan" enablePopover /> -
            Huohuo provides an extremely nice ATK% buff on her Ultimate, is an
            Energy Battery, and with E1 also provides a speed boost which
            further pushes her as the best Sustain for DoT teams currently.
          </li>
        </ul>
      )}
      {slug === 'imbibitor-lunae' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="sparkle" enablePopover /> is the
            best partner for Imbibitor Lunae, being able to advance him while
            running a slow build, and giving the most skill points out of any
            character on a consistent 3-turn rotation.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="sunday" enablePopover /> is an
            upgrade to Sparkle, but only if you have Sunday's signature Light
            Cone. As only then he becomes fully SP positive, making the pairing
            possible.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="tingyun" enablePopover /> provides
            Energy and additional buffs.
          </li>
        </ul>
      )}
      {slug === 'jade' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="the-herta" enablePopover /> - Jade
            offers The Herta some of the most consistent and frequent stack and
            Energy generation of all her Erudition options; on top of also
            granting her 30 Speed thanks to her Skill. Jade can also dish out
            very respectable damage especially in Pure Fiction.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="lingsha" enablePopover /> - Jade
            can designate Lingsha as the Debt Collector, where Lingsha can then
            spam AoE with pretty much all of her attacks - from her Skill,
            Ultimate, and from Fuyuan (Ultimate Advance + Emergency Trigger),
            while she’s keeping the team healthy and away from debuffs. The
            synergy becomes especially strong with Sunday to constantly advance
            both Lingsha and Fuyuan to generate Charges for Jade.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="blade" enablePopover /> and Jade
            share great synergy. Blade benefits Jade as he possesses: a
            Follow-Up attack capable of hitting up to 5 enemies which triggers
            often, a relatively accessible Ultimate, a Basic Attack that always
            hits 3 targets and a teammate that doubles his total actions
            (Bronya) resulting in a good double damage partner and plenty of
            Charges for Jade. It doesn't stop there though as Jade also benefits
            Blade - her Debt Collector buff causes Blade to take an additional
            instance of damage when using all of his abilities, allowing him to
            gain 2 stacks of his Talent instead of 1 when using his Enhanced
            Basic Attack and Ultimate which feeds more into Jade. Basically,
            it's a feeding loop where both benefit a lot.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="herta" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="himeko" enablePopover /> - Jade is
            at home and completely dominates in Pure Fiction. What Jade wants
            most is allies that hit as many enemies as possible, as often as
            possible - and Herta and Himeko fit that bill to a tee being able to
            hit 3-5 enemies with all of their attacks while also launching a
            continuous barrage of Follow-Up attacks at every enemy with each
            hit, of course, granting Jade a stack of Charge. What makes the
            combo of Herta/Himeko so strong with Jade is they both require
            either a Break or initial source of damage to trigger their
            Follow-Ups - something that Jade can easily help in many
            circumstances. The result of this is an incredibly potent ping pong
            effect between Himeko and Herta's Follow-Up's and Jade's. You'll
            generally want to run either Himeko or Herta and not both but either
            will be incredibly potent.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="ruan-mei" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="robin" enablePopover /> - Jade is
            made to be used in double DPS teams and Ruan Mei and Robin are
            Supports that work well in those kinda of setups. Most of Jade's
            double DPS teams (with the exception of Blade) will benefit greatly
            from having either of these characters on the team.
          </li>
        </ul>
      )}
      {slug === 'jiaoqiu' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="acheron" enablePopover /> is
            Jiaoqiu's top teammate and the character he feels designed to
            support. Acheron thrives most with characters that consistently and
            frequently apply debuffs to enemies and Jiaoqiu has no equal in that
            regard. His Path also activates Acheron's bonus ability and his
            debuff of choice (vulnerability) is something Acheron lacks in her
            own kit making it very valuable.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="dr-ratio" enablePopover /> relies
            on his team to apply 3-5 debuffs to bring his kit fully online. This
            restrict his teammate options, but Jiaoqiu having 2 semi-permanent
            debuffs and a 3rd from his Cone can do a lot to help with this. With
            Jiaoqiu bringing tons of debuffs, Ratio has a lot more options to
            choose from for the Amplifier and Sustain slots.
          </li>
        </ul>
      )}
      {slug === 'jing-yuan' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="sunday" enablePopover /> is the
            best support for Jing Yuan who elevates him to new heights. Playing
            Jing Yuan without Sunday and with is such massive difference, that
            he goes from an underwhelming DPS to one of the strongest in the
            game. Make sure to play Sunday in the -1 Speed setup to make the
            synergy work well!
          </li>
          <li>
            <HSRCharacter mode="inline" slug="robin" enablePopover /> is the
            second best support for Jing Yuan that provides him with a lot of
            buffs and also gives him additional actions, further improving his
            performance.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="jiaoqiu" enablePopover /> is an
            interesting alternative to Robin. He focuses on debuffing enemies,
            rather than buffing Jing Yuan and is especially powerful if you snag
            yourself E1 Sunday. A good F2P alternative to the Fox is{' '}
            <HSRCharacter mode="inline" slug="pela" enablePopover /> who allows
            you abuse the DEF Ignore that comes with E1 Sunday.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="ruan-mei" enablePopover /> as
            always makes her appearance, being a great universal buffer. Just
            make sure her Speed boost doesn't mess with your Speed tuning.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="tingyun" enablePopover /> is an
            alternative to those lacking Robin who provides Jing Yuan with a
            plethora of buffs and Energy.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="sparkle" enablePopover /> is the
            former second-best partner for Jing Yuan and now only a way weaker
            option for those lacking Sunday. Make sure to play her in the
            Hyperspeed variant (160+ Speed while Jing Yuan uses ATK Boots).
          </li>
          <li>
            <HSRCharacter mode="inline" slug="hanya" enablePopover /> is an
            experimental tech that still allows you to run ATK% boots on Jing
            Yuan as she can provide him with enough Speed to make the Sunday -1
            Speed setup work. Just keep in mind you need a very, very fast Hanya
            for this to happen.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="huohuo" enablePopover /> is the
            best Sustain option for Jing Yuan as she provides him with great
            buffs and Energy.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="aventurine" enablePopover /> is an
            alternative option for the Sustain spot for stages where there's so
            much CC thrown your way that Huohuo struggles to keep up with
            cleansing.
          </li>
        </ul>
      )}
      {slug === 'jingliu' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="sunday" enablePopover /> &
            <HSRCharacter mode="inline" slug="bronya" enablePopover /> are both
            great Amplifier options that can be played in the -1 Speed setup
            with Jingliu.
          </li>
        </ul>
      )}
      {slug === 'kafka' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="black-swan" enablePopover /> -
            Kafka’s partner in crime for DoT, Black Swan synergises extremely
            well with Kafka triggering her Follow-Up Attacks every action or
            two, and DEF shred on enemies with more than 7 Arcana stacks, which
            also applies to Kafka’s DoT’s.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="ruan-mei" enablePopover /> - Being
            a Harmony Character who provides DMG% automatically makes her an
            obvious choice for Kafka. Her RES PEN from her Ultimate, DEF shred
            if she is E1, extra DMG% from S1 and her speed buffs are the most
            efficient scalers for Kafka, making Ruan Mei the BiS for her.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="robin" enablePopover /> - Much
            like Ruan Mei, Robin also provides insane buffs, upwards of 1000+
            ATK, DMG%. Combined with damage from Robin herself and with how fast
            Kafka is normally built, it racks up a decent amount of extra
            damage.
          </li>
        </ul>
      )}
      {slug === 'lingsha' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="firefly" enablePopover /> - As a
            break-themed support Lingsha is an outstanding partner for Firefly,
            offering her excellent assistance with whittling enemies Toughness
            bars down quickly - especially in AoE - and providing her a solid
            Break damage taken multiplier with her Ultimate. Thanks to Firefly's
            Fire Weakness implant, Lingsha wont have any concerns about being
            able to damage enemy Toughness bars either. While Lingsha is strong
            with Firefly and her teammates (Ruan Mei and Harmony Trailblazer
            especially), at E0 Firefly is notoriously SP hungry - something
            Lingsha can struggle to keep up with in longer fights without
            careful planning. When playing this combo pay specific attention to
            Skill Point economy and try to get avoid wasting skill points.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="feixiao" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="dr-ratio" enablePopover /> -
            Lingsha is an incredibly competent Follow-Up attacker - only beaten
            out by the dedicated Follow-Up sustain, Aventurine. Thanks to Fuyuan
            and her Ultimate, Lingsha has no problems launching a continuous
            stream of attacks at enemies making her a good compliment to Feixiao
            teams, granting them a constant supply of Flying Aureus stacks.
            Lingsha is also a strong choice when supporting Dr Ratio. thanks to
            her in built Ultimate debuff which she can keep up consistently on
            all but the fastest targets - if you have her Signature, her value
            only increases further in Ratio teams moving from 1 debuff to 2.
            Lingsha also has solid synergy with other characters that find their
            way onto Ratio and Feixiao teams, making her a good choice for
            Follow-up Enthusiasts.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="tingyun-fugue" enablePopover /> &
            <HSRCharacter
              mode="inline"
              slug="trailblazer-harmony"
              enablePopover
            />{' '}
            & <HSRCharacter mode="inline" slug="ruan-mei" enablePopover /> - As
            a Break-focused sustain who benefits from Break Effect% and deals a
            considerable amount of Toughness damage herself it's no surprise the
            Super Break trio are exceptional teammates for Lingsha.
          </li>
        </ul>
      )}
      {slug === 'luka' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="tingyun-fugue" enablePopover /> is
            a great partner for Luka, allowing for more consistent Breaks from
            our fighting champion,
          </li>
          <li>
            <HSRCharacter mode="inline" slug="kafka" enablePopover /> utilises
            the insane Break Bleeds that Luka can apply, able to trigger them
            over and over resulting in the strongest version of DoTs in Physical
            Weak content. Can also be applied to all content with Silver Wolf to
            destroy basically anything in the game.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="boothill" enablePopover /> and
            Luka may seem odd, but allowing Boothill (who has a higher Break
            effect stat than Luka) to be the one to Break allows Luka to focus
            on Speed and defensive stats instead. He can then use his Enhanced
            Basic Attack to trigger Boothill’s huge Bleeds.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="silver-wolf" enablePopover />{' '}
            allows Luka to be played in his most optimal state at all times,
            along with applying stupidly strong Physical Breaks with her
            Implant. However, you will require proper timing or to hold your
            Ultimate on Luka to not accidentally Break with Silver Wolf instead
            of him.
          </li>
        </ul>
      )}
      {slug === 'luocha' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="blade" enablePopover /> has a
            constant depletion of his HP which requires lots of attention;
            thankfully Luocha’s field applies all the healing Blade could ever
            need and more. He also features an emergency heal if Blade ever gets
            too low from enemy attacks or puts himself into the red.
          </li>
        </ul>
      )}
      {slug === 'lynx' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="blade" enablePopover /> has a
            constant depletion of his HP which requires lots of attention,
            thankfully Lynx’s HoT as well as her increased Taunt on Skill allows
            Blade to Follow-Up Attack a lot more often while also healing
            himself from it.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="clara" enablePopover /> - Lynx has
            a Taunt value increase on her skill which is very useful for
            characters like Clara who need to be hit to get the most out of
            their kit. Cleanse on her Ultimate allows Crowd Controls to be
            ignored in certain scenarios, with her E2 also providing a one time
            debuff ignore on Skill use.
          </li>
        </ul>
      )}
      {slug === 'march-7th' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="acheron" enablePopover /> is one
            of the few remaining synergies which exist for March, with using her
            Skill on herself with the Trend Light Cone to apply debuffs whenever
            an enemy attacks her.
          </li>
        </ul>
      )}
      {slug === 'march-7th-swordmaster' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="topaz" enablePopover /> is one of
            March 7th's best dual-damage teammates at the time of release for
            her CRIT RATE build and is a great choice of Shifu. Marking Topaz as
            Shifu allows March to grant Topaz some great buffs but more
            importantly also generate a lot more charge stacks for herself
            compared to other possible Shifu targets. What makes Topaz such a
            good Shifu target is that whenever Topaz OR Numby attacks they both
            grant her a stack of Charge allowing March to gain access to her
            enhanced skill much faster than with other Shifu's. What makes the
            combo even stronger is the fact that Numby will advance their own
            action whenever March lands her incredibly frequent follow-up attack
            accelerating the entire process even further.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="gallagher" enablePopover /> is one
            of the most potent healing characters in the game with especially
            powerful synergies in Break Teams. Gallagher is also a character who
            favors Speed and even has his own in built action advance in his
            kit. Both of these facts make him an ideal team mate and Shifu for
            Break March. By building high amounts of Speed and advancing himself
            Gallagher grants March more Charge stacks than other potential Shifu
            targets and with his Break Amplifiers and Good sustain capabilities
            he buffs up March and keeps her team options open.
          </li>
          <li>
            <HSRCharacter
              mode="inline"
              slug="trailblazer-harmony"
              enablePopover
            />{' '}
            - A mandatory teammate and synergy for any Break-focused March
            teams. March has no way to deal strong Break or Super Break damage
            on her own despite her incredibly high toughness damage forcing her
            to rely on external help in order to make the build work. Harmony
            Trailblazer's ultimate is currently one of the only ways to bestow
            Super Break capabilities to teammates making them an absolute must
            for any March Break teams.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="robin" enablePopover /> is an
            excellent teammate specifically for CRIT March thanks to her
            incredibly team-wide buffing capabilities buffing not only March 7th
            but any team mate you decide to bring alongside her. More
            specifically, Robin's Advance and Follow-Up attack on hit damage
            benefit March a lot thanks to March relying executing far more
            individual actions than most other characters in the game and
            gaining benefit the faster she but also her Shifu are able to
            attack.
          </li>
        </ul>
      )}
      {slug === 'misha' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="sunday" enablePopover /> &
            <HSRCharacter mode="inline" slug="sparkle" enablePopover /> &
            <HSRCharacter mode="inline" slug="bronya" enablePopover /> Allowing
            Misha’s Ultimate to be normally used off turn or right before
            gaining buffs puts Sparkle and Sunday as one of his BiS buffers,
            with Bronya being roughly the same, but lacking the skill point
            freedom of the two.
          </li>
        </ul>
      )}
      {slug === 'moze' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="feixiao" enablePopover /> is the
            queen of Follow-Up attacks. Every standard turn Feixiao gets, she
            launches a minimum of 3 actions but sometimes even more than that
            when factoring in her ultimate. Moze thrives in teams with allies
            that can trigger the charge of his Prey mark as frequently as
            possible. There is no better teammate for this than Feixiao. Feixiao
            also benefits from Moze as well though thanks to the 25% damage
            taken amplification debuff he applies to the target of his prey - as
            all the majority of Feixiao's damage is considered Follow-Up damage
            this is a substantial boost.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="dr-ratio" enablePopover /> relies
            on enemies being debuffed at least 3 times (but ideally 5) in order
            to unleash his maximum potential, Moze relies on being paired with
            teammates that can attack as frequently as possible. Each of these
            character's solve each others problems perfectly. Moze provides 2
            debuffs baseline thanks to his Prey mark; while Ratio attacks a
            minimum of two times per action triggering Moze's charges. On top of
            this Moze also provides Dr Ratio with a 25% damage taken
            amplification to all his follow-up damage as long as he hits his
            Prey, further more at E2 Moze also provides 40% Crit Dmg on top of
            that!
          </li>
          <li>
            <HSRCharacter mode="inline" slug="aventurine" enablePopover /> -
            Moze doesn't need much help in the way of sustain thanks to the fact
            he spends most of his time off the field BUT... Aventurine is the
            number one option when it comes to sustains that launches as many
            attacks as possible making him the top option for juicing up how
            many Follow-Up's you can expect when team building for Moze.
            Aventurine also synergizes greatly with the rest of the Follow-Up
            attacking units Moze would prefer to run alongside making the
            pairing of them even more effective.
          </li>
        </ul>
      )}
      {slug === 'natasha' && (
        <ul className="bigger-margin">
          <li>
            <strong>None</strong> - Natasha works fine as a F2P unit for
            basically everyone, however does fall behind on basically
            everything; heals, utility, and defensiveness when compared to her
            counterparts later on.
          </li>
        </ul>
      )}
      {slug === 'pela' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="acheron" enablePopover /> - Pela
            is one of the easiest to build debuffers for Acheron who pumps out
            debuffs extremely quickly. With the Pearls Light Cone equipped, she
            applies a debuff on every Basic Attack, E4 adds a debuff on her
            Skill, and lastly, another debuff on her Ultimate which can be
            chained back-to-back due to how low cost it is.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="jingliu" enablePopover /> takes
            advantage of E4+ Pela’s Ice RES down on top of the DEF shred she
            applies, and with how skill point positive she is, she’s a perfect
            match for Jingliu.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="imbibitor-lunae" enablePopover />{' '}
            likes the skill point generation of Pela a lot, but also enjoys the
            DEF shred that she applies from her Ultimate. This is further
            enhanced by Basic Attacks or her Skill adding more DEF shred with
            the Pearls Light Lone equipped, can provide a very strong boost to
            overall damage.
          </li>
        </ul>
      )}
      {slug === 'qingque' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="sparkle" enablePopover /> - Being
            extremely Skill Point hungry and normally built slow for maximum
            damage allows Sparkle be Qingque’s strongest supports. Sparkle is
            preferred over Bronya for the Skill Point generation as well as the
            Quantum damage% Trace from Sparkle.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="fu-xuan" enablePopover /> lets
            Qingque become less reliant on RNG thanks to her Crit Rate% buff
            from her Skill, while also providing the necessary sustain she
            needs. Fu Xuan also fulfils the count check for the Mono-Quantum
            version.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="silver-wolf" enablePopover />{' '}
            provides some disgustingly strong DEF shred numbers on top of being
            able to always implant Quantum in the Mono-Quantum team, which pairs
            extremely well with the Quantum set for additional 10 DEF shred for
            “free”.
          </li>
        </ul>
      )}
      {slug === 'rappa' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="tingyun-fugue" enablePopover /> -
            Rappa relies heavily on scoring as many Weakness Breaks as possible
            to fuel her energy needs. "Cloudflame Luster" and the double breaks
            it provides allows her to gain twice the Charges and as a result
            twice the Energy. This essentially doubles the DMG and Energy gain
            from her Talent while also scoring her some extra up front break
            damage as well.
          </li>
          <li>
            <HSRCharacter
              mode="inline"
              slug="trailblazer-harmony"
              enablePopover
            />{' '}
            - Rappa has some access to Super Break, but with their Ultimate,
            Harmony Trailblazer, can grant her close to double that amount (or
            more), skyrocketing her damage. Harmony Trailblazer also offers
            Rappa a very large boost to her Break Effect%, contributes a large
            amount of Imaginary Toughness (the same element as Rappa) and can
            soak up some of the spare skill points Rappa herself doesn't need.
            The combination of all of these points push Rappa and her teams
            damage to much greater heights, making Harmony Trailblazer a natural
            pick in most Rappa teams.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="ruan-mei" enablePopover /> - A
            strong damage and utility support for Rappa who shores up her
            weaknesses and pushes her strengths to even greater heights. Ruan
            Mei's 50% weakness Break Efficiency accelerates Rappa's entire game
            plan, granting her charges, Energy, more damage and greater
            consistency in non-Imaginary fights. It's no understatement to say
            that this buff alone improves her performance significantly across
            the board. Ruan also has more to offer Rappa in the damage
            department granting her some bonus Break Effect% and All Elemental
            Resistance Shred as well. If you're running Rappa, Ruan Mei should
            be one of your go-to choices in most team compositions and modes.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="lingsha" enablePopover /> - A
            sustain that outputs impressive AoE Break, grants a nice break
            damage Amplification debuff on enemies and has very nice healing and
            cleansing with the only downside of being somewhat Skill Point
            hungry compared to other sustains. Rappa almost completely mitigates
            that downside thanks to her Enhanced Basic Attacks, being completely
            SP neutral, leaving ample resources for Lingsha to use her skill as
            needed or even more than that for some extra break potential.
          </li>
        </ul>
      )}
      {slug === 'robin' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="sunday" enablePopover /> - The
            Charmony sibling is an extremely powerful Support duo for a lot of
            DMG dealers, providing them with an absurd amount of buffs and
            actions. Robin’s teamwide Action Advance will allow the DPS to act
            and then for Sunday to boost them up right after again, while she
            deals Additional DMG and regenerates Energy based on attack count -
            which is something Sunday can provide for her by advancing
            characters and their summons. Sunday can also use his Ultimate on
            Robin if she does not regenerate enough Energy.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="feixiao" enablePopover /> - Robin
            is the premiere and best support for Feixiao offering her an absurd
            combination of buffs, on hit damage and action advancement that
            isn't currently matched by any other character in the game. Robin's
            buffs include: a massive 50% DMG%, a truck load of flat ATK, and a
            total of 45% CRIT DMG for Follow-Up Attacks. Let's not forget about
            the on hit Physical damage for the entire team all alongside her
            signature full team boost. The buffs alone are spectacular for
            Feixiao and the other Follow-Up attackers she plays alongside but
            the full team advancement does wonders for granting you more total
            Ultimates in a fight.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="topaz" enablePopover /> is an
            exceptional partner for Robin with absurd two-way synergy between
            them. Topaz and Numby's frequent Follow-up Attacks grant Robin
            significantly higher amounts of energy while also triggering her
            Ultimate Concerto damage much more often. The majority of Topaz's
            damage, fortunately, is also from Follow-Up Attacks meaning she can
            take full advantage of a full 45% crit damage boost from Robin's
            kit!
          </li>
          <li>
            <HSRCharacter mode="inline" slug="huohuo" enablePopover /> Energy
            Regeneration tied to her Ultimate allows Robin to chain her own
            Ultimate leading to no downtime in her offensive state.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="aventurine" enablePopover />{' '}
            frequent Follow-up ATKs can trigger Robin's Additional DMG Proc and
            regenerate Energy for Robin, while Robin offers some buffs for his
            Follow-up ATKs. Aventurine can spam his Basic ATK to make up for
            Robin's inability to generate Skill Points during Concerto, and he
            can reliably shield her without requiring her to take action.
          </li>
        </ul>
      )}
      {slug === 'ruan-mei' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="firefly" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="boothill" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="rappa" enablePopover /> all love
            Ruan Mei as she provides everything they needs to perform well -
            Break Efficiency, Speed and Break Delay.
          </li>
          <li>
            <HSRCharacter
              mode="inline"
              slug="trailblazer-harmony"
              enablePopover
            />{' '}
            is the second Break-Centric Support we currently have, and it abuses
            every aspect of Ruan Mei except the DMG% boosts, while also allowing
            Ruan Mei herself to even do some damage from Harmony Trailblazers
            Super Break mechanic.
          </li>
        </ul>
      )}
      {slug === 'sampo' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="kafka" enablePopover /> is the
            de-facto DoT enabler and is the main synergy for Sampo currently.
            Sampo at E6 does increase his own Wind Shears damage, however Kafka
            can also re-trigger those stronger Wind Shears with her Skill and
            Ultimate, with her E1 and E2 increasing all members DoT on the
            enemy/self respectively.
          </li>
        </ul>
      )}
      {slug === 'seele' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="sparkle" enablePopover />
            is one of the best supports for Seele currently, providing very high
            CRIT DMG buffs from her Skill as well as Action Advance and plenty
            of Skill Points that Seele just loves to devour.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="silver-wolf" enablePopover />
            applies a Quantum implant for Seele, allowing her Trace to always be
            against negative Resistance enemies. Her DEF shred also pairs very
            well with the Quantum 4-piece set to always be against Quantum Weak
            enemies.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="fu-xuan" enablePopover /> has a
            strong crit rate buff when her Skill is up which helps fulfil
            Seele’s crit needs. Seele can also use Fu Xuan for Mono-Quantum
            teams with little to no hindrance over other Sustainers.
          </li>
        </ul>
      )}
      {slug === 'serval' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="the-herta" enablePopover /> -
            Serval can be played standard or alternatively built as a dedicated
            Stack and Energy generator for The Herta by using the Passkey Light
            Cone and the Eagle of Twilight Line set. This build allows Serval to
            move quickly and spam her Ultimate but at the cost of most of her
            damage potential.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="bronya" enablePopover /> &
            <HSRCharacter mode="inline" slug="sparkle" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="sunday" enablePopover /> - all
            provide Serval with buffs and additional actions, allowing her to
            perform better.
          </li>
          <li>
            <HSRCharacter
              mode="inline"
              slug="trailblazer-harmony"
              enablePopover
            />
            - Owing to her low cost Ultimate, Serval is able to deal a
            significant amount of Toughness Break very quickly by chaining them.
            Some Light Cones can further increase the amount of Ultimates she
            can output which makes her very solid in Super Break teams.
          </li>
        </ul>
      )}
      {slug === 'silver-wolf' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="acheron" enablePopover /> - Silver
            Wolf is a strong partner for Acheron against Boss contents, as she
            can apply extremely potent debuffs against a single enemy. All of
            her attacks can apply debuffs to generate Slashed Dream points for
            Acheron.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="dr-ratio" enablePopover /> - Dr.
            Ratio requires at least 3 debuffs on the target to activate his
            Talent’s Follow-up ATK, making Silver Wolf a strong debuffer for
            him. And she can apply up to 5-6 Debuffs to max out Dr. Ratio’s
            Deduction Trace for 50% DMG Bonus.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="sparkle" enablePopover /> -
            Sparkle reduces the chance of Silver Wolf implanting the wrong
            Weakness, and her ATK buff from the Trace becomes stronger with more
            Quantum-Type allies. Her Skill Point positivity can also allow
            Silver Wolf to use her Skill to implant new Weaknesses more often.
          </li>
        </ul>
      )}
      {slug === 'sparkle' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="imbibitor-lunae" enablePopover />{' '}
            - Being the most Skill Point generous unit in the game makes Sparkle
            the ideal candidate for Imbibitor Lunae. Her Ultimate provides
            enough Skill Points for him to use a fully Enhanced Basic Attack at
            all times and not worry about Skill Point generation, while her
            Skill’s buff also lingers till after his turn, which can affect his
            Ultimate when used outside of his turn.
          </li>
          <li>
            <strong>Other hypercarries</strong> - Sparkle can be played with any
            other Hypercarry in the 160+ setup where she 'shares' her Speed with
            them. But keep in mind that -1 Speed setups often are better, making
            Sparkle inferior to Sunday.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="fu-xuan" enablePopover />{' '}
            activates Sparkle's trace Quantum bonus without needing to
            compromise one of your damage-focused team slots. Additionally, Fu
            Xuan plays into Sparkle's kit perfectly, offering the entire team
            crit rate which synergises with the massive amount of crit damage
            Sparkle grants with her Skill, allowing the team to more fully take
            advantage of her buffs.
          </li>
        </ul>
      )}
      {slug === 'sunday' && (
        <>
          <p>
            <strong>
              Sunday is one of the most flexible and synergistic character's in
              the game
            </strong>{' '}
            - so much so there are far too many synergies to list. Instead below
            is a list of synergistic features Sunday offers and how they impact
            each archetype he can be played within.
          </p>
          <p>
            Sunday's buffs last multiple turns and can be maintained
            indefinitely,{' '}
            <strong>
              making him a suitable choice for characters with Summons but also
              Follow-Ups
            </strong>{' '}
            - something he holds as an advantage over his most comparable peer
            Bronya. This is relevant for characters such as:
          </p>
          <ul className="bigger-margin">
            <li>
              <HSRCharacter mode="inline" slug="feixiao" enablePopover /> (FUA)
            </li>
            <li>
              <HSRCharacter mode="inline" slug="dr-ratio" enablePopover /> (FUA)
            </li>
            <li>
              <HSRCharacter mode="inline" slug="yunli" enablePopover /> (FUA)
            </li>
            <li>
              <HSRCharacter mode="inline" slug="clara" enablePopover /> (FUA)
            </li>
            <li>
              <HSRCharacter mode="inline" slug="blade" enablePopover /> (FUA)
            </li>
            <li>
              <HSRCharacter mode="inline" slug="jing-yuan" enablePopover />{' '}
              (Lightning Lord)
            </li>
          </ul>
          <p>
            Sunday possesses no ATK buffs and instead is{' '}
            <strong>
              entirely focused on Action Advance, CRIT and DMG% buffs, making
              him a reasonable choice for any character no matter if they scale
              off HP, ATK or DEF.
            </strong>{' '}
            This type of scaling allows him to be played universally, but also
            make him strong with either characters that don't care much for
            additional main stat scaling as they have it covered with their own
            kit or other supporting characters that can offer a tremendous
            amount of main stat scaling themselves to complement Sunday.
          </p>
          <p>
            <strong>Sunday's Energy Generation is incredibly potent</strong> for
            most damaging characters but is very appealing to a few whose kits
            focus very heavily around Ultimates such as:
          </p>
          <ul className="bigger-margin">
            <li>
              <HSRCharacter mode="inline" slug="the-herta" enablePopover />{' '}
              (Ultimate Hungry)
            </li>
            <li>
              <HSRCharacter mode="inline" slug="argenti" enablePopover />{' '}
              (Ultimate Hungry)
            </li>
            <li>
              <HSRCharacter mode="inline" slug="yunli" enablePopover />{' '}
              (Ultimate Hungry)
            </li>
            <li>
              <HSRCharacter mode="inline" slug="boothill" enablePopover />{' '}
              (Ultimate Hungry)
            </li>
            <li>
              <HSRCharacter mode="inline" slug="jingliu" enablePopover />{' '}
              (Ultimate Hungry)
            </li>
            <li>
              <HSRCharacter mode="inline" slug="jing-yuan" enablePopover />{' '}
              (Ultimate Hungry)
            </li>
            <li>
              <HSRCharacter
                mode="inline"
                slug="imbibitor-lunae"
                enablePopover
              />{' '}
              (Ultimate offers nice SP sustain)
            </li>
          </ul>
          <p>
            <strong>
              Unlike Bronya, Sunday is Skill Point neutral and with certain Cone
              even slightly Skill Point positive.
            </strong>{' '}
            This opens up the -1 Speed playstyle to far more characters, while
            also making Sunday far more playable in teams that are tight on
            Skill Points than Bronya ever was. Here are just a few characters
            who can benefit from the less SP heavy -1 Speed playstyle (there are
            many more than this):
          </p>
          <ul className="bigger-margin">
            <li>
              <HSRCharacter mode="inline" slug="acheron" enablePopover />{' '}
              (Double Actions results in more ultimates)
            </li>
            <li>
              <HSRCharacter
                mode="inline"
                slug="imbibitor-lunae"
                enablePopover
              />{' '}
              (More Ults + SP Neutral allows DHIL to weave Double Action 3SP
              Basics into his rotation)
            </li>
            <li>
              <HSRCharacter mode="inline" slug="jingliu" enablePopover /> (Can
              much more comfortably play the Double Action playstyle and with
              greater team flexibility)
            </li>
          </ul>
          <p>
            <strong>
              Sunday is a Summon specialist due to his additional bonuses to
              summoners and his ability to advance their summons
            </strong>{' '}
            - for this reason he is uniquely synergistic with:
          </p>
          <ul className="bigger-margin">
            <li>
              <HSRCharacter mode="inline" slug="jing-yuan" enablePopover /> (Can
              advance Lightning Lord... Finally)
            </li>
            <li>
              <HSRCharacter mode="inline" slug="topaz" enablePopover /> (For the
              very niche Main DPS Topaz teams)
            </li>
            <li>
              <HSRCharacter mode="inline" slug="lingsha" enablePopover /> (For
              those playing main DPS Lingsha)
            </li>
          </ul>
          <p>
            Due to being Skill Point Neutral, Sunday can actually be played
            alongside his fellow action advancers - Bronya and Sparkle - to
            create some very interesting combinations. Here are some of the more
            potent ways to combine the characters:
          </p>
          <ul className="bigger-margin">
            <li>
              <HSRCharacter mode="inline" slug="sunday" enablePopover /> &{' '}
              <HSRCharacter mode="inline" slug="sparkle" enablePopover /> - Do
              not build Speed on your carry and instead set Sparkle's Speed to
              161 (or higher) and Sunday's to 1 less than that. Using this
              combo, Sparkle will move first, advancing you carry to her
              position in the Action Odrer for them to move, following that
              Sunday will move right after, dragging the carry back again for
              another action. This combo allows a carry with 0 Speed to perform
              an action twice at an effective 160 Speed, combining both the -1
              playstyle and Hyperspeed playstyle. The downside of this combo is
              that Sparkle's skill buff only lasts for 1 turn and will not be
              active for the turn you advance your carry with Sunday.
            </li>
            <li>
              <HSRCharacter mode="inline" slug="sunday" enablePopover /> &{' '}
              <HSRCharacter mode="inline" slug="bronya" enablePopover /> - Build
              your teams Speed as follows - Carry at 136 or higher, Sunday at
              least 1 Speed slower than that and Bronya at least 1 Speed slower
              than Sunday (for example, Carry: 136, Sunday: 135, Bronya: 134).
              With this setup, the carry will move first using their action,
              Sunday will move next buffing them and granting them another
              action, then right after that Bronya will move granting them yet
              another action and stacking her buffs on top of Sunday's. Unlike
              the Sparkle combo, Sunday and Bronya's buffs do overlap for a
              massive 3rd strike and you'll also be consistently outputting 3
              actions consecutively every time you carry move so long as you can
              keep up with Skill Point demands.
            </li>
          </ul>
        </>
      )}
      {slug === 'sushang' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="tingyun-fugue" enablePopover /> is
            a great partner for Sushang - much better than HMC - as
            Exo-Toughness helps a lot in improving her performance,
          </li>
          <li>
            <HSRCharacter
              mode="inline"
              slug="trailblazer-harmony"
              enablePopover
            />{' '}
            has allowed Sushang to be back to her glory days thanks to Super
            Break. Being able to be built full crit DPS or as a Hybrid, Harmony
            Trailblazer takes care of the awkwardness of when enemies are Broken
            and still provides a reason to invest in Break effect.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="ruan-mei" enablePopover /> delay
            on enemies recovering from the Broken status allows Sushang to get a
            few more hits in while the enemies are Broken, which allows
            Sushang’s E1 to refund her Skill Points making it technically free.
            Not to mention the Weakness Break efficiency synergy.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="silver-wolf" enablePopover />{' '}
            allows Sushang to always hit Physical Weak enemies and always have a
            way to put enemies into the Broken state.
          </li>
        </ul>
      )}
      {slug === 'tingyun' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="argenti" enablePopover /> - Having
            a very expensive Ultimate cos, Argenti loves Tingyun’s Energy
            Battery and damage% buff Ultimate along with attack% buff from her
            Skill, leading to short windows of burst damage - which Argenti’s
            Ultimate is all about.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="yunli" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="clara" enablePopover /> are two
            more DPS that are very Energy reliant, making Tingyun a great choice
            for them.
          </li>
        </ul>
      )}
      {slug === 'tingyun-fugue' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="rappa" enablePopover /> relies
            heavily on scoring as many Weakness Breaks as possible to fuel her
            energy needs. "Cloudflame Luster" and the double breaks it provides
            allows her to gain twice the Charges and as a result twice the
            Energy. This essentially doubles the DMG and Energy gain from her
            Talent while also scoring her some extra up front break damage as
            well.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="firefly" enablePopover /> - As one
            of the premiere Break damage dealers, it's no surprise that Firefly
            would be more than happy to have Fugue join their team. Fugue Super
            Break is excellent, Exo Toughness is a nice burst of additional
            damage and Fugue's buffs and debuffs are also a great addition.
            However unlike other characters like Boothill, Rappa and Himeko,
            Firefly doesn't gain transformational benefit from the ability to
            Break enemies twice in quick succession, making Fugue useable with
            her but not revolutionary when a free and very capable Harmony
            Trailblazer exists. When factoring in Eidolons on either Fugue or
            Firefly things can change.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="boothill" enablePopover /> - With
            Fugue, Boothill now has the potential to gain all 3 Pocket
            Trickshots in just 1 attack: 1 from the standard Break, 1 from the
            "Cloudflame Luster" Break and 1 from getting the kill. Pulling this
            off will require Boothill to be able to deal enough Toughness damage
            to Break through the standard Toughness bar and extra 40% bar but is
            definitely worth going for. Fugue also doubles his Weakness Break
            DMG as he gets 2 Breaks instead of 1 per target and allows him to
            deal with non-Physical weak enemies without having to rely on his
            Ultimate.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="himeko" enablePopover /> - Similar
            to Rappa, Fugue's "Cloudflame Luster" turbo charges Himeko's talent
            and Charge gain - so much so in fact it becomes difficult to make
            use of all the Charges she generates as Charges cannot exceed the
            limit. It's a funny but welcome consequence of too much success.
            Fugue also offers Himeko the break scaling she so depsperately needs
            via Superbreak on top of granting her the ability to finally deal
            with non-fire weak enemies all be it at 50% efficiency.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="acheron" enablePopover /> - Fugue
            only really has one major synergy with Acheron and that's to act as
            superpowered Debuff battery. Fugue herself can apply debuffs with
            Nihility Cone but can also grant a team member the ability to do the
            same with her Foxian Prayer - buffing an ally that attacks
            frequently with this like Lingsha or Aventurine leads to an
            incredible amount of charges for Acheron. With that said as Fugue
            isn't an intended support for Acheron you may have more success with
            supports who can directly buff her instead,
          </li>
          <li>
            <HSRCharacter mode="inline" slug="lingsha" enablePopover /> is a
            pseudo-Erudition units that can be played as main DPS and she also
            works with Fugue, turning her into an even bigger killing machine,
            especially in Pure Fiction,
          </li>
          <li>
            <HSRCharacter mode="inline" slug="xueyi" enablePopover />{' '}
            <HSRCharacter mode="inline" slug="sushang" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="luka" enablePopover /> will all
            benefit from the Exo-Toughness, drastically improving their
            potential and performance.
          </li>
        </ul>
      )}
      {slug === 'the-herta' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="jade" enablePopover /> offers The
            Herta some of the most consistent and frequent stack and Energy
            generation of all her Erudition options; on top of also granting her
            30 Speed thanks to her Skill. Jade can also dish out very
            respectable damage especially in Pure Fiction.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="herta" enablePopover /> - "Mini
            Herta" is a reasonable alternative to Jade in modes that feature
            weak and or respawnable enemies for her to trigger her Follow-Up
            talent against such as Pure Fiction. Unfortunately Mini Herta's
            follow-up's only count once for the purpose of The Herta's stacks
            and Energy generation no matter how many times she spins back to
            back, making her mostly suitable for Pure Fiction or for those
            without other erudition options.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="serval" enablePopover /> can be
            played standard or alternatively built as a dedicated Stack and
            Energy generator for The Herta by using the Passkey Light Cone and
            the Eagle of Twilight Line set. This build allows Serval to move
            quickly and spam her Ultimate but at the cost of most of her damage
            potential.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="argenti" enablePopover /> similar
            to Serval, can be used as Ult spammer to provide more stacks for The
            Herta (and he brings more personal damage than the Serval).
          </li>
          <li>
            <HSRCharacter
              mode="inline"
              slug="trailblazer-remembrance"
              enablePopover
            />{' '}
            - Unlike other Supports, the Remembrance Trailblazer's Ultimate, as
            well as their companion Mem, can attack enemies in an AoE to
            Regenerate Energy and apply "Interpretation" stacks for The Herta.
            The Herta's additional Energy Regeneration allows for faster Charges
            on Mem, and her Max Energy is high enough to max out the Additional
            True DMG Multiplier. Mem then provides CRIT, Action Advance, and
            Additional True DMG buffs for The Herta.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="sunday" enablePopover /> - Grants
            The Herta the -1 Speed or Hyper Speed playstyle alongside fantastic
            CRIT-related buffs and most importantly a constant flow of Energy.
            Sunday himself scales off CRIT DMG allowing him to grant the target
            of his Skill a % of his own CRIT DMG - when The Herta buffs him with
            her 80% CRIT DMG he receives it, then can give it right back to The
            Herta, allowing her to gain even more benefit from her own buffs.
            One of the best supports for The Herta but is Skill Point intensive
            without his Signature - this stacked on top of The Herta's skill
            point woes without her own signature, make using the pair more
            challenging without investment.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="robin" enablePopover /> -
            Excellent choice for The Herta and her teams. Robin is a team wide
            buffer, meaning both The Herta and her Erudition partner are buffed
            and capable of dealing more damage. Robin's Action advance is
            powerful for the same reason, but also even stronger because of the
            fact the additional actions it grants also grant The Herta even more
            Energy and Stacks, resulting in even more Ultimates.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="lingsha" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="aventurine" enablePopover /> - are
            two sustains that are capable of attacking each turn using their
            Basic ATK/Skill, have a damaging Ultimate and an additional source
            of attack outside of their standard actions. This is important as
            all of these actions will generate The Herta Stacks and Energy. This
            factor alone allows Aventurine and Lingsha to pull substantially
            ahead of most other sustain options for The Herta.
          </li>
        </ul>
      )}
      {slug === 'the-herta' && (
        <>
          <p>
            If you want to compare all the sub-dps options for The Herta, check
            this video:
          </p>
          <Row className="video-row">
            <Col xs={12} lg={4}>
              <YoutubeEmbed embedId="53rRXsH05Sc" />
            </Col>
          </Row>
        </>
      )}
      {slug === 'topaz' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="robin" enablePopover />{' '}
            introduction allowed Topaz to be run as a Main DPS in Fire Weak
            content and significantly increasing her output in Dual-DPS teams by
            almost doubling her damage. Topaz is also a very fast applicant of
            Robin's own damage through Numby’s turns.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="feixiao" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="dr-ratio" enablePopover /> are the
            two premium FUA damage dealers and they make full use of Topaz
            debuffs and Numby zooming around the battlefield.
          </li>
        </ul>
      )}
      {slug === 'trailblazer-preservation' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="acheron" enablePopover /> -
            Probably one of the last main synergies for Fire Trailblazer after
            Harmony Trailblazer has come out. Fire Trailblazer is able to
            provide the best taunt in the game as well as utilise the Trend
            Light Cone and stack an immense amount of debuffs for Acheron to use
            her Ultimate much more frequently.
          </li>
        </ul>
      )}
      {slug === 'trailblazer-harmony' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="ruan-mei" enablePopover /> - Being
            the sole Weakness Break efficiency buffer in the game makes Ruan Mei
            the BiS buffer for Harmony Trailblazer - and Super Break in general,
            due to how it scales based on the amount of Toughness damage it
            deals.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="firefly" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="boothill" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="rappa" enablePopover /> - are our
            premium Break damage dealers. While Firefly and Rappa need to be
            paired with Harmony MC, Boothill doesn't but he can use Harmony
            Trailblazer’s Super Break on elites and bosses to kill them faster.
          </li>
          <li>
            <strong>Any other DPS</strong> - in theory, you can build most
            Damage Dealers as Super Breakers; with better or worse results.
          </li>
        </ul>
      )}
      {slug === 'trailblazer-destruction' && (
        <ul className="bigger-margin">
          <li>
            <strong>None</strong> - Physical Trailblazer works fine as a F2P
            unit for basically anyone early on, however does fall behind on
            basically everything; Break, crit, DoT, etc and is better off using
            another Path.
          </li>
        </ul>
      )}
      {slug === 'trailblazer-remembrance' && (
        <>
          <p>
            Rememberance Trailblazer is one of the most generic supports in the
            game, thanks to their True DMG buff working with all damage and
            mechanic types. Because of this, you can play them with most
            damaging (and sometimes even supportive like Robin) characters. With
            that said they are most synergistic with characters which have
            higher total maximum energy such as{' '}
            <HSRCharacter mode="inline" slug="the-herta" enablePopover /> or{' '}
            <HSRCharacter mode="inline" slug="yunli" enablePopover /> as well as
            characters that generic more energy than usual like{' '}
            <HSRCharacter mode="inline" slug="huohuo" enablePopover /> (to
            assist with Mem's charge). With Eidolons Rememberance Trailblazer
            also gains significant and specific bonuses when another Memomaster
            is on the team making that something to watch out for as well.
          </p>
        </>
      )}
      {slug === 'welt' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="acheron" enablePopover /> - Being
            a Nihility character places Welt as one of Acheron’s potential
            partners, however Welt can also somewhat replace the Sustain in most
            of her teams. Going for a “Pseudo-Sustainer” position allows a
            Harmony buffer to push Acheron's damage to the moon, without having
            the need to worry about a Sustain if you can clear quick enough.
          </li>
        </ul>
      )}
      {slug === 'xueyi' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="tingyun-fugue" enablePopover /> is
            a great partner for Xueyi, finally fully boosting her kit and
            allowing her to break more consistenly - something she always
            wanted. So if you love Xueyi, Fugue will elevate her to new levels,
          </li>
          <li>
            <HSRCharacter
              mode="inline"
              slug="trailblazer-harmony"
              enablePopover
            />{' '}
            allows Xueyi to be fully built as a crit DPS while still having a
            sizable amount of Break for her Trace, which converts Break effect
            to DMG%. She also has the largest Toughness reducing Ultimate in the
            game which scales Harmony Trailblazer’s Super Break extremely well.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="ruan-mei" enablePopover />{' '}
            provides the best all-rounded buffs for Xueyi in both crit teams and
            Super Break teams, with her Break effect double dipping for Xueyi -
            converting Break effect into DMG % and Weakness Break efficiency
            activating more Follow-Up Attacks. RES PEN% from Ruan Mei buffs her
            DPS as well.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="sparkle" enablePopover /> has
            decent synergy with Xueyi, as her crit damage buff carrying over in
            between turns is very helpful for Xueyi’s Follow-Up Attacks to deal
            a large amount of damage. It also allows Xueyi to be run slow with
            ATK% boots for more damage.
          </li>
        </ul>
      )}
      {slug === 'yukong' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="imbibitor-lunae" enablePopover />{' '}
            - With Imbibitor Lunae being built on the slower side, Yukong can
            take advantage of her speed tuning to always go right before his
            turn and provide a lot of benefits, however, the Skill Point
            consumption can be an annoyance to get right for certain teams.
          </li>
        </ul>
      )}
      {slug === 'yanqing' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="aventurine" enablePopover /> &{' '}
            <HSRCharacter mode="inline" slug="gepard" enablePopover /> -
            Providing the necessary shields for Yanqing to function puts
            Aventurine and Gepard on top for his synergies, however Gepard may
            lack in some scenarios due to how long his Ultimate can take to get
            up and may run out of turns. Aventurine on the other hand provides
            consistent shields frequently enough to not fall off and allow
            Yanqing to keep his buff up.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="sunday" enablePopover /> &
            <HSRCharacter mode="inline" slug="bronya" enablePopover /> are both
            great Amplifier options that can be played in the -1 Speed setup
            with Yanqing.
          </li>
        </ul>
      )}
      {slug === 'yunli' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="robin" enablePopover /> provides
            the best buffs for all Follow-up characters - insanely high flat ATK
            boost, her Crit DMG% buffs, and DMG% buffs synergize with Yunli
            insanely well. Robin’s Crit DMG% to Follow-up attacks trace ability
            also applies to her ultimate - as Yunli’s Ultimate is considered a
            Follow-up attack. Robin’s team-wide advance is also particularly
            helpful to Yunli as she does not build any Speed of her own. Robin
            also supports dual damaging teams excellently of which Yunli has
            multiple incredibly viable options for.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="sunday" enablePopover /> provides
            Yunli with more actions, great buffs and Energy on top of that,
            improving her performance across the board.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="tingyun" enablePopover /> provides
            additional Energy for Yunli to access more Ultimates pushing her
            damage even higher than usual. Tingyun also provides a strong DMG%
            buff and ATK% buff to Yunli through her Ultimate and Skill
            respectively. Her skill's Benediction also does in fact trigger on
            Yunli’s Counter and Ultimate, leading to even more damage.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="huohuo" enablePopover /> Ultimate
            has excellent synergy with Yunli - providing 48 energy on use
            instead of the expected 24. The reason for this is Huohuo considers
            Yunli’s total energy not how much her ultimate actually costs,
            meaning Huohuo grants her over a third of an Ultimate each time.
            Huohuo also provides an Attack buff which will persist in-between
            Yunli’s counters, and a bunch of cleanses to ensure those Counter’s
            go off.
          </li>
          <li>
            <HSRCharacter mode="inline" slug="lynx" enablePopover /> is a
            critical Synergy for Yunli's that do not have access to their
            signature Light Cone to increase the chance of Yunli being hit. Much
            of Yunli's energy generation - and as a result damage - is tied to
            her constantly being hit in combat and launching counters; something
            that is only possible with a high taunt value. Yunli's baseline kit
            has no access to increasing the chance for her to be hit outside of
            the guaranteed 1 action taunt on her Ultimate, leaving her in dire
            need of assistance - assistance Lynx can provide with her skill.
            Lynx's skill provides Yunli a buff that drastically increases her
            chance to be hit.
          </li>
        </ul>
      )}
      {slug === 'test' && (
        <ul className="bigger-margin">
          <li>
            <HSRCharacter mode="inline" slug="firefly" enablePopover />{' '}
          </li>
          <li>
            <HSRCharacter mode="inline" slug="ruan-mei" enablePopover />
          </li>
          <li>
            <HSRCharacter mode="inline" slug="lingsha" enablePopover />
          </li>
        </ul>
      )}
    </>
  );
};
