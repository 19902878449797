export const HSRDPSRankingArrayNew = [
  {
    id: 0,
    name: 'Seele',
    slug: 'seele',
    element: 'quantum',
    body: 'CRIT Rate',
    feet: 'ATK%',
    sphere: 'Quantum DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (29.2%), CRIT DMG (93.3%), Speed (6.9)',
    relic_set_1: 'Genius of Brilliant Stars',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'cruising-in-the-stellar-sea',
    cone_s: '5',
    disclaimer:
      'Seeles reset mechanic is not included in her calculations. Seele`s E6 is being counted with 5 total strikes per Ultimate applying more strikes will raise her E6 damage substantially.',
    single_dmg: {
      damage: 320641,
      damage_e1: 351089,
      damage_e2: 418091,
      damage_e3: 455587,
      damage_e4: 455587,
      damage_e5: 469750,
      damage_e6: 584477,
      skillPoints: 8.3,
      skillPoints_e1: 8.3,
      skillPoints_e2: 9.9,
      skillPoints_e3: 9.9,
      skillPoints_e4: 9.9,
      skillPoints_e5: 9.9,
      skillPoints_e6: 9.9
    },
    aoe_dmg: {
      damage: 347727,
      damage_e1: 376296,
      damage_e2: 448108,
      damage_e3: 488828,
      damage_e4: 488828,
      damage_e5: 502260,
      damage_e6: 638254,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 14,
      skillPoints_e3: 14,
      skillPoints_e4: 14,
      skillPoints_e5: 14,
      skillPoints_e6: 14
    }
  },
  {
    id: 2,
    name: 'Yanqing',
    slug: 'yanqing',
    element: 'ice',
    body: 'CRIT DMG',
    feet: 'Speed',
    sphere: 'Ice DMG',
    rope: 'ATK%',
    substats: 'CRIT DMG (81.6%), ATK (54.4%)',
    relic_set_1: 'Scholar Lost in Erudition',
    relic_set_2: '',
    relic_set_3: 'Firmament Frontline: Glamoth',
    cone: 'cruising-in-the-stellar-sea',
    cone_s: '5',
    disclaimer:
      'Yanqing will deal more damage post E1 when placed in teams that can consistently Freeze. These calculations assume Yanqing is never hit in battle.',
    single_dmg: {
      damage: 286435,
      damage_e1: 303760,
      damage_e2: 303760,
      damage_e3: 317683,
      damage_e4: 355804,
      damage_e5: 374474,
      damage_e6: 374474,
      skillPoints: 8.2,
      skillPoints_e1: 8.2,
      skillPoints_e2: 8.2,
      skillPoints_e3: 8.2,
      skillPoints_e4: 8.2,
      skillPoints_e5: 8.2,
      skillPoints_e6: 8.2
    },
    aoe_dmg: {
      damage: 316307,
      damage_e1: 330621,
      damage_e2: 330621,
      damage_e3: 346061,
      damage_e4: 387588,
      damage_e5: 405992,
      damage_e6: 405992,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 30,
    name: 'Kafka',
    slug: 'kafka',
    element: 'lightning',
    body: 'ATK%',
    feet: 'Speed',
    sphere: 'Lightning DMG',
    rope: 'ATK%',
    substats: 'ATK (46.7%), Effect Hit Rate (11.66%), Speed (27.6)',
    relic_set_1: 'Prisoner in Deep Confinement',
    relic_set_2: '',
    relic_set_3: 'Firmament Frontline: Glamoth',
    cone: 'good-night-and-sleep-well',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 311905,
      damage_e1: 371612,
      damage_e2: 400644,
      damage_e3: 410247,
      damage_e4: 410247,
      damage_e5: 448012,
      damage_e6: 605975,
      skillPoints: 8,
      skillPoints_e1: 8,
      skillPoints_e2: 8,
      skillPoints_e3: 8,
      skillPoints_e4: 8,
      skillPoints_e5: 8,
      skillPoints_e6: 8
    },
    aoe_dmg: {
      damage: 628945,
      damage_e1: 727223,
      damage_e2: 789437,
      damage_e3: 803187,
      damage_e4: 803187,
      damage_e5: 877603,
      damage_e6: 1214214,
      skillPoints: 8,
      skillPoints_e1: 8,
      skillPoints_e2: 8,
      skillPoints_e3: 8,
      skillPoints_e4: 8,
      skillPoints_e5: 8,
      skillPoints_e6: 8
    }
  },
  {
    id: 1,
    name: 'Dan Heng',
    slug: 'dan-heng',
    element: 'wind',
    body: 'CRIT DMG',
    feet: 'Speed',
    sphere: 'Wind DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (46.7%), CRIT DMG (70.5%)',
    relic_set_1: 'Scholar Lost in Erudition',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'cruising-in-the-stellar-sea',
    cone_s: '5',
    disclaimer:
      'The simulation assumes a perfect rotation where you time his Talent on each of his Ultimates.',
    single_dmg: {
      damage: 238159,
      damage_e1: 247962,
      damage_e2: 263977,
      damage_e3: 279477,
      damage_e4: 279477,
      damage_e5: 293700,
      damage_e6: 293700,
      skillPoints: 14,
      skillPoints_e1: 14,
      skillPoints_e2: 14,
      skillPoints_e3: 14,
      skillPoints_e4: 14,
      skillPoints_e5: 14,
      skillPoints_e6: 14
    },
    aoe_dmg: {
      damage: 283382,
      damage_e1: 296213,
      damage_e2: 315821,
      damage_e3: 334122,
      damage_e4: 334122,
      damage_e5: 351467,
      damage_e6: 351467,
      skillPoints: 14,
      skillPoints_e1: 14,
      skillPoints_e2: 14,
      skillPoints_e3: 14,
      skillPoints_e4: 14,
      skillPoints_e5: 14,
      skillPoints_e6: 14
    }
  },
  {
    id: 3,
    name: 'Jing Yuan',
    slug: 'jing-yuan',
    element: 'lightning',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Lightning DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (35%), CRIT DMG (70.5%), Speed (11.5)',
    relic_set_1: 'The Ashblazing Grand Duke',
    relic_set_2: '',
    relic_set_3: 'The Wondrous BananAmusement Park',
    cone: 'today-is-another-peaceful-day',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 291131,
      damage_e1: 291131,
      damage_e2: 298498,
      damage_e3: 300692,
      damage_e4: 300692,
      damage_e5: 325606,
      damage_e6: 397379,
      skillPoints: 7.5,
      skillPoints_e1: 7.5,
      skillPoints_e2: 7.5,
      skillPoints_e3: 7.5,
      skillPoints_e4: 7.5,
      skillPoints_e5: 7.5,
      skillPoints_e6: 7.5
    },
    aoe_dmg: {
      damage: 515691,
      damage_e1: 591686,
      damage_e2: 613787,
      damage_e3: 620368,
      damage_e4: 620368,
      damage_e5: 666940,
      damage_e6: 791482,
      skillPoints: 7.5,
      skillPoints_e1: 7.5,
      skillPoints_e2: 7.5,
      skillPoints_e3: 7.5,
      skillPoints_e4: 7.5,
      skillPoints_e5: 7.5,
      skillPoints_e6: 7.5
    }
  },
  {
    id: 20,
    name: 'Blade',
    slug: 'blade',
    element: 'wind',
    body: 'CRIT DMG',
    feet: 'Speed',
    sphere: 'Wind DMG',
    rope: 'HP%',
    substats: 'Crit Rate (37.9%), CRIT DMG (64.1%), SPD (13.8)',
    relic_set_1: 'Longevous Disciple',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'ninja-record-sound-hunt',
    cone_s: '5',
    disclaimer:
      'Blade can gain and lose SUBSTANTIAL amounts of damage based on how many times he is hit. These calculations assume he is hit ONCE per every full action he takes for the purpose of his talent charges.',
    single_dmg: {
      damage: 223373,
      damage_e1: 254737,
      damage_e2: 285187,
      damage_e3: 296532,
      damage_e4: 341043,
      damage_e5: 366269,
      damage_e6: 442032,
      skillPoints: 3,
      skillPoints_e1: 3,
      skillPoints_e2: 3,
      skillPoints_e3: 3,
      skillPoints_e4: 3,
      skillPoints_e5: 3,
      skillPoints_e6: 3
    },
    aoe_dmg: {
      damage: 539260,
      damage_e1: 571713,
      damage_e2: 640053,
      damage_e3: 674290,
      damage_e4: 774710,
      damage_e5: 823584,
      damage_e6: 1065160,
      skillPoints: 3,
      skillPoints_e1: 3,
      skillPoints_e2: 3,
      skillPoints_e3: 3,
      skillPoints_e4: 3,
      skillPoints_e5: 3,
      skillPoints_e6: 3
    }
  },
  {
    id: 4,
    name: 'Hook',
    slug: 'hook',
    element: 'fire',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Fire DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (26.2%), CRIT DMG (81.6%), Speed (16.1)',
    relic_set_1: 'Pioneer Diver of Dead Waters',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'on-the-fall-of-an-aeon',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 213413,
      damage_e1: 217878,
      damage_e2: 217878,
      damage_e3: 230670,
      damage_e4: 230670,
      damage_e5: 238718,
      damage_e6: 265803,
      skillPoints: 8,
      skillPoints_e1: 8,
      skillPoints_e2: 8,
      skillPoints_e3: 8,
      skillPoints_e4: 8,
      skillPoints_e5: 8,
      skillPoints_e6: 8
    },
    aoe_dmg: {
      damage: 236733,
      damage_e1: 243750,
      damage_e2: 243750,
      damage_e3: 259129,
      damage_e4: 285224,
      damage_e5: 293272,
      damage_e6: 326370,
      skillPoints: 8,
      skillPoints_e1: 8,
      skillPoints_e2: 8,
      skillPoints_e3: 8,
      skillPoints_e4: 8,
      skillPoints_e5: 8,
      skillPoints_e6: 8
    }
  },
  {
    id: 5,
    name: 'Sushang',
    slug: 'sushang',
    element: 'phys',
    body: 'CRIT DMG',
    feet: 'Speed',
    sphere: 'Physical DMG',
    rope: 'Break Effect',
    substats: 'Crit Rate (17.5%), CRIT DMG (58.7%), Break Effect (81.3%)',
    relic_set_1: 'Champion of Streetwise Boxing',
    relic_set_2: '',
    relic_set_3: 'Talia: Kingdom of Banditry',
    cone: 'swordplay',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 442020,
      damage_e1: 442020,
      damage_e2: 442020,
      damage_e3: 447777,
      damage_e4: 485622,
      damage_e5: 499075,
      damage_e6: 526482,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    },
    aoe_dmg: {
      damage: 245778,
      damage_e1: 245778,
      damage_e2: 245778,
      damage_e3: 250360,
      damage_e4: 250360,
      damage_e5: 270259,
      damage_e6: 270259,
      skillPoints: 16,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 9,
    name: 'Arlan',
    slug: 'arlan',
    element: 'lightning',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Lightning DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (32.41%), CRIT DMG (93.3%), Speed (6.9)',
    relic_set_1: 'Scholar Lost in Erudition',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'on-the-fall-of-an-aeon',
    cone_s: '5',
    disclaimer:
      'The simulation assumes Arlan is at low HP, so you need to pair him with a Shielder to achieve the damage output.',
    single_dmg: {
      damage: 143136,
      damage_e1: 148204,
      damage_e2: 148204,
      damage_e3: 159700,
      damage_e4: 159700,
      damage_e5: 164992,
      damage_e6: 168646,
      skillPoints: 0,
      skillPoints_e1: 0,
      skillPoints_e2: 0,
      skillPoints_e3: 0,
      skillPoints_e4: 0,
      skillPoints_e5: 0,
      skillPoints_e6: 0
    },
    aoe_dmg: {
      damage: 176385,
      damage_e1: 181454,
      damage_e2: 181454,
      damage_e3: 192949,
      damage_e4: 192949,
      damage_e5: 201583,
      damage_e6: 249134,
      skillPoints: 0,
      skillPoints_e1: 0,
      skillPoints_e2: 0,
      skillPoints_e3: 0,
      skillPoints_e4: 0,
      skillPoints_e5: 0,
      skillPoints_e6: 0
    }
  },
  {
    id: 8,
    name: 'Himeko',
    slug: 'himeko',
    element: 'fire',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Fire DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (29.2%), CRIT DMG (81.6%), SPD (13.8)',
    relic_set_1: 'The Ashblazing Grand Duke',
    relic_set_2: '',
    relic_set_3: 'Inert Salsotto',
    cone: 'geniuses-repose',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 183934,
      damage_e1: 200056,
      damage_e2: 208284,
      damage_e3: 219815,
      damage_e4: 219815,
      damage_e5: 227539,
      damage_e6: 264659,
      skillPoints: 10,
      skillPoints_e1: 11,
      skillPoints_e2: 11,
      skillPoints_e3: 11,
      skillPoints_e4: 11,
      skillPoints_e5: 11,
      skillPoints_e6: 11
    },
    aoe_dmg: {
      damage: 545398,
      damage_e1: 620480,
      damage_e2: 645573,
      damage_e3: 667315,
      damage_e4: 667315,
      damage_e5: 705280,
      damage_e6: 744162,
      skillPoints: 10,
      skillPoints_e1: 11,
      skillPoints_e2: 11,
      skillPoints_e3: 11,
      skillPoints_e4: 11,
      skillPoints_e5: 11,
      skillPoints_e6: 11
    }
  },
  {
    id: 22,
    name: 'Luka',
    slug: 'luka',
    element: 'phys',
    body: 'ATK%',
    feet: 'Speed',
    sphere: 'Physical DMG',
    rope: 'ATK%',
    substats: 'ATK (62.2%), Effect Hit Rate (11.6%), Speed (11.5)',
    relic_set_1: 'Prisoner in Deep Confinement',
    relic_set_2: '',
    relic_set_3: 'Firmament Frontline: Glamoth',
    cone: 'eyes-of-the-prey',
    cone_s: '5',
    disclaimer:
      'Luka`s damage is reliant on the enemies actions - faster enemies will lead to him dealing substantially more damage while slower ones will lead to less.',
    single_dmg: {
      damage: 282005,
      damage_e1: 300991,
      damage_e2: 332232,
      damage_e3: 359734,
      damage_e4: 383497,
      damage_e5: 395584,
      damage_e6: 427584,
      skillPoints: 0,
      skillPoints_e1: 0,
      skillPoints_e2: -2,
      skillPoints_e3: -2,
      skillPoints_e4: -2,
      skillPoints_e5: -2,
      skillPoints_e6: -2
    },
    aoe_dmg: {
      damage: 0,
      damage_e1: 0,
      damage_e2: 0,
      damage_e3: 0,
      damage_e4: 0,
      damage_e5: 0,
      damage_e6: 0,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 14,
      skillPoints_e3: 14,
      skillPoints_e4: 14,
      skillPoints_e5: 14,
      skillPoints_e6: 14
    }
  },
  {
    id: 13,
    name: 'Silver Wolf',
    slug: 'silver-wolf',
    element: 'quantum',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Quantum DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (11.7%), CRIT DMG (46.7%), EHR (31.1%), Speed (9.2)',
    relic_set_1: 'Genius of Brilliant Stars',
    relic_set_2: '',
    relic_set_3: 'Pan-Cosmic Commercial Enterprise',
    cone: 'before-the-tutorial-mission-starts',
    cone_s: '5',
    disclaimer:
      'The rules of this calculation (enemies vulnerable to attackers element) disable a portion of Silver Wolfs skill. Against neutral enemies she will have a competitive advantage over other characters not shown here.',
    single_dmg: {
      damage: 174820,
      damage_e1: 209797,
      damage_e2: 245163,
      damage_e3: 259960,
      damage_e4: 292592,
      damage_e5: 308072,
      damage_e6: 499824,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    },
    aoe_dmg: {
      damage: 174820,
      damage_e1: 209797,
      damage_e2: 245163,
      damage_e3: 259960,
      damage_e4: 292592,
      damage_e5: 308072,
      damage_e6: 499824,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 7,
    name: 'Sampo',
    slug: 'sampo',
    element: 'wind',
    body: 'Effect Hit Rate',
    feet: 'ATK%',
    sphere: 'Wind DMG',
    rope: 'ATK%',
    substats: 'ATK% (46.7%), Effect Hit Rate (46.66%), Speed (13.8)',
    relic_set_1: 'Prisoner in Deep Confinement',
    relic_set_2: '',
    relic_set_3: 'Firmament Frontline: Glamoth',
    cone: 'good-night-and-sleep-well',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 189652,
      damage_e1: 214462,
      damage_e2: 214462,
      damage_e3: 225223,
      damage_e4: 267182,
      damage_e5: 283377,
      damage_e6: 324154,
      skillPoints: 10,
      skillPoints_e1: 10,
      skillPoints_e2: 10,
      skillPoints_e3: 10,
      skillPoints_e4: 10,
      skillPoints_e5: 10,
      skillPoints_e6: 10
    },
    aoe_dmg: {
      damage: 401577,
      damage_e1: 442532,
      damage_e2: 442532,
      damage_e3: 453292,
      damage_e4: 495252,
      damage_e5: 534908,
      damage_e6: 632806,
      skillPoints: 10,
      skillPoints_e1: 10,
      skillPoints_e2: 10,
      skillPoints_e3: 10,
      skillPoints_e4: 10,
      skillPoints_e5: 10,
      skillPoints_e6: 10
    }
  },
  {
    id: 10,
    name: 'Serval',
    slug: 'serval',
    element: 'lightning',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Lightning DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (35%), CRIT DMG (93.3%)',
    relic_set_1: 'Band of Sizzling Thunder',
    relic_set_2: '',
    relic_set_3: 'Firmament Frontline: Glamoth',
    cone: 'the-seriousness-of-breakfast',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 135178,
      damage_e1: 135178,
      damage_e2: 135178,
      damage_e3: 142580,
      damage_e4: 142580,
      damage_e5: 147997,
      damage_e6: 172023,
      skillPoints: 7.3,
      skillPoints_e1: 7.3,
      skillPoints_e2: 7.3,
      skillPoints_e3: 7.3,
      skillPoints_e4: 7.3,
      skillPoints_e5: 7.3,
      skillPoints_e6: 7.3
    },
    aoe_dmg: {
      damage: 343623,
      damage_e1: 343623,
      damage_e2: 392378,
      damage_e3: 408393,
      damage_e4: 408393,
      damage_e5: 428707,
      damage_e6: 498303,
      skillPoints: 7.3,
      skillPoints_e1: 7.3,
      skillPoints_e2: 7.3,
      skillPoints_e3: 7.3,
      skillPoints_e4: 7.3,
      skillPoints_e5: 7.3,
      skillPoints_e6: 7.3
    }
  },
  {
    id: 11,
    name: 'Trailblazer (Destruction)',
    slug: 'trailblazer-destruction',
    element: 'phys',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Physical DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (20.4%), CRIT DMG (75.8%), Speed (9.2)',
    relic_set_1: 'Scholar Lost in Erudition',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'on-the-fall-of-an-aeon',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 111824,
      damage_e1: 111824,
      damage_e2: 111824,
      damage_e3: 120243,
      damage_e4: 122537,
      damage_e5: 125626,
      damage_e6: 125626,
      skillPoints: 7.4,
      skillPoints_e1: 7.4,
      skillPoints_e2: 7.4,
      skillPoints_e3: 7.4,
      skillPoints_e4: 7.4,
      skillPoints_e5: 7.4,
      skillPoints_e6: 7.4
    },
    aoe_dmg: {
      damage: 399685,
      damage_e1: 399685,
      damage_e2: 399685,
      damage_e3: 437172,
      damage_e4: 447806,
      damage_e5: 454172,
      damage_e6: 454172,
      skillPoints: 7.4,
      skillPoints_e1: 7.4,
      skillPoints_e2: 7.4,
      skillPoints_e3: 7.4,
      skillPoints_e4: 7.4,
      skillPoints_e5: 7.4,
      skillPoints_e6: 7.4
    }
  },
  {
    id: 12,
    name: 'Herta',
    slug: 'herta',
    element: 'ice',
    body: 'CRIT Rate',
    feet: 'ATK%',
    sphere: 'Ice DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (32.1%), CRIT DMG (93.3%)',
    relic_set_1: 'The Ashblazing Grand Duke',
    relic_set_2: '',
    relic_set_3: 'Inert Salsotto',
    cone: 'geniuses-repose',
    cone_s: '5',
    single_dmg: {
      damage: 76153,
      damage_e1: 76153,
      damage_e2: 84831,
      damage_e3: 89263,
      damage_e4: 89263,
      damage_e5: 93103,
      damage_e6: 98056,
      skillPoints: 6,
      skillPoints_e1: 6,
      skillPoints_e2: 6,
      skillPoints_e3: 6,
      skillPoints_e4: 6,
      skillPoints_e5: 6,
      skillPoints_e6: 6
    },
    aoe_dmg: {
      damage: 289641,
      damage_e1: 289641,
      damage_e2: 322614,
      damage_e3: 335908,
      damage_e4: 341395,
      damage_e5: 356470,
      damage_e6: 373735,
      skillPoints: 6,
      skillPoints_e1: 6,
      skillPoints_e2: 6,
      skillPoints_e3: 6,
      skillPoints_e4: 6,
      skillPoints_e5: 6,
      skillPoints_e6: 6
    }
  },
  {
    id: 25,
    name: 'Imbibitor Lunae',
    slug: 'imbibitor-lunae',
    element: 'imaginary',
    body: 'CRIT Rate',
    feet: 'ATK^',
    sphere: 'Imaginary DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (35%), CRIT DMG (93.8%)',
    relic_set_1: 'Musketeer of Wild Wheat',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'on-the-fall-of-an-aeon',
    cone_s: '5',
    single_dmg: {
      damage: 326863,
      damage_e1: 369091,
      damage_e2: 553637,
      damage_e3: 603612,
      damage_e4: 657595,
      damage_e5: 682344,
      damage_e6: 795241,
      skillPoints: 13,
      skillPoints_e1: 13,
      skillPoints_e2: 17,
      skillPoints_e3: 17,
      skillPoints_e4: 17,
      skillPoints_e5: 17,
      skillPoints_e6: 17
    },
    aoe_dmg: {
      damage: 595519,
      damage_e1: 681719,
      damage_e2: 1022578,
      damage_e3: 1115481,
      damage_e4: 1195640,
      damage_e5: 1244959,
      damage_e6: 1448407,
      skillPoints: 13,
      skillPoints_e1: 13,
      skillPoints_e2: 17,
      skillPoints_e3: 17,
      skillPoints_e4: 17,
      skillPoints_e5: 17,
      skillPoints_e6: 17
    }
  },
  {
    id: 25,
    name: 'Clara',
    slug: 'clara',
    element: 'physical',
    body: 'CRIT Rate',
    feet: 'ATK%',
    sphere: 'Physical DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (35%), CRIT DMG (93.8%)',
    relic_set_1: 'Champion of Streetwise Boxing',
    relic_set_2: '',
    relic_set_3: 'Inert Salsotto',
    cone: 'something-irreplaceable',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 269218,
      damage_e1: 269218,
      damage_e2: 296074,
      damage_e3: 305270,
      damage_e4: 305270,
      damage_e5: 324235,
      damage_e6: 365927,
      skillPoints: 5.2,
      skillPoints_e1: 5.2,
      skillPoints_e2: 5.2,
      skillPoints_e3: 5.2,
      skillPoints_e4: 5.2,
      skillPoints_e5: 5.2,
      skillPoints_e6: 5.2
    },
    aoe_dmg: {
      damage: 737362,
      damage_e1: 737362,
      damage_e2: 810917,
      damage_e3: 838505,
      damage_e4: 838505,
      damage_e5: 888673,
      damage_e6: 1071045,
      skillPoints: 5.2,
      skillPoints_e1: 5.2,
      skillPoints_e2: 5.2,
      skillPoints_e3: 5.2,
      skillPoints_e4: 5.2,
      skillPoints_e5: 5.2,
      skillPoints_e6: 5.2
    }
  },
  {
    id: 26,
    name: 'Jingliu',
    slug: 'jingliu',
    element: 'ice',
    body: 'CRIT DMG',
    feet: 'Speed',
    sphere: 'Ice DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (29.2%), CRIT DMG (81.6%), ATK (23.3%)',
    relic_set_1: 'Scholar Lost in Erudition',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'on-the-fall-of-an-aeon',
    cone_s: '5',
    disclaimer:
      'Fall of Aeons conditional Break bonus is not included in the calculations.',
    single_dmg: {
      damage: 327016,
      damage_e1: 464891,
      damage_e2: 506545,
      damage_e3: 533013,
      damage_e4: 565771,
      damage_e5: 597786,
      damage_e6: 644613,
      skillPoints: 6,
      skillPoints_e1: 6,
      skillPoints_e2: 6,
      skillPoints_e3: 6,
      skillPoints_e4: 6,
      skillPoints_e5: 6,
      skillPoints_e6: 5
    },
    aoe_dmg: {
      damage: 613732,
      damage_e1: 654968,
      damage_e2: 714474,
      damage_e3: 756676,
      damage_e4: 804305,
      damage_e5: 865424,
      damage_e6: 937743,
      skillPoints: 6,
      skillPoints_e1: 6,
      skillPoints_e2: 6,
      skillPoints_e3: 6,
      skillPoints_e4: 6,
      skillPoints_e5: 6,
      skillPoints_e6: 5
    }
  },
  {
    id: 27,
    name: 'Topaz & Numby',
    slug: 'topaz',
    element: 'fire',
    body: 'CRIT DMG',
    feet: 'Speed',
    sphere: 'ATK%',
    rope: 'ATK%',
    substats: 'CRIT Rate (46.7%), CRIT DMG (70%)',
    relic_set_1: 'The Ashblazing Grand Duke',
    relic_set_2: '',
    relic_set_3: 'Duran, Dynasty of Running Wolves',
    cone: 'cruising-in-the-stellar-sea',
    cone_s: '5',
    disclaimer:
      'These calcs assume you always get the full 50% action advance from hitting Proof of Debt, in combat this will not always be the case which will lower Topaz`s damage and at E2 can impact her Energy Recharge.',
    single_dmg: {
      damage: 384154,
      damage_e1: 442477,
      damage_e2: 513312,
      damage_e3: 543351,
      damage_e4: 819754,
      damage_e5: 880810,
      damage_e6: 1102138,
      skillPoints: 0,
      skillPoints_e1: 0,
      skillPoints_e2: 0,
      skillPoints_e3: 0,
      skillPoints_e4: 0,
      skillPoints_e5: 0,
      skillPoints_e6: 0
    },
    aoe_dmg: {
      damage: 724871,
      damage_e1: 779280,
      damage_e2: 862924,
      damage_e3: 927688,
      damage_e4: 988228,
      damage_e5: 1061518,
      damage_e6: 1290386,
      skillPoints: 6,
      skillPoints_e1: 6,
      skillPoints_e2: 6,
      skillPoints_e3: 6,
      skillPoints_e4: 6,
      skillPoints_e5: 6,
      skillPoints_e6: 5
    }
  },
  {
    id: 28,
    name: 'Guinaifen',
    slug: 'guinaifen',
    element: 'fire',
    body: 'ATK%',
    feet: 'Speed',
    sphere: 'Fire DMG',
    rope: 'ATK%',
    substats: 'ATK% (54%), Speed (4.6), EHR (15.5%)',
    relic_set_1: 'Prisoner in Deep Confinement',
    relic_set_2: '',
    relic_set_3: 'Firmament Frontline: Glamoth',
    cone: 'eyes-of-the-prey',
    cone_s: '5',
    disclaimer:
      'Enemies Speed is set to 140 - against faster enemies, Guinaifen damage will increase.',
    single_dmg: {
      damage: 187536,
      damage_e1: 187536,
      damage_e2: 211964,
      damage_e3: 229850,
      damage_e4: 229850,
      damage_e5: 235214,
      damage_e6: 249771,
      skillPoints: 2,
      skillPoints_e1: 2,
      skillPoints_e2: 2,
      skillPoints_e3: 2,
      skillPoints_e4: 2,
      skillPoints_e5: 2,
      skillPoints_e6: 2
    },
    aoe_dmg: {
      damage: 486769,
      damage_e1: 486769,
      damage_e2: 560051,
      damage_e3: 606127,
      damage_e4: 637478,
      damage_e5: 649973,
      damage_e6: 690199,
      skillPoints: 2,
      skillPoints_e1: 2,
      skillPoints_e2: 2,
      skillPoints_e3: 2,
      skillPoints_e4: 2,
      skillPoints_e5: 2,
      skillPoints_e6: 2
    }
  },
  {
    id: 29,
    name: 'Argenti',
    slug: 'argenti',
    element: 'physical',
    body: 'CRIT DMG',
    feet: 'Speed',
    sphere: 'ATK%',
    rope: 'ATK%',
    substats: 'Crit Rate (46.7%), CRIT DMG (64.2%), Speed (6.9)',
    relic_set_1: 'Scholar Lost in Erudition',
    relic_set_2: '',
    relic_set_3: 'Inert Salsotto',
    cone: 'today-is-another-peaceful-day',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 231763,
      damage_e1: 264078,
      damage_e2: 264078,
      damage_e3: 277659,
      damage_e4: 293372,
      damage_e5: 310014,
      damage_e6: 351821,
      skillPoints: 7,
      skillPoints_e1: 7,
      skillPoints_e2: 7,
      skillPoints_e3: 7,
      skillPoints_e4: 7,
      skillPoints_e5: 7,
      skillPoints_e6: 7
    },
    aoe_dmg: {
      damage: 615174,
      damage_e1: 700949,
      damage_e2: 794258,
      damage_e3: 837517,
      damage_e4: 884912,
      damage_e5: 931995,
      damage_e6: 1052300,
      skillPoints: 7,
      skillPoints_e1: 7,
      skillPoints_e2: 7,
      skillPoints_e3: 7,
      skillPoints_e4: 7,
      skillPoints_e5: 7,
      skillPoints_e6: 7
    }
  },
  {
    id: 30,
    name: 'Xueyi',
    slug: 'xueyi',
    element: 'quantum',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'ATK',
    rope: 'Break Effect',
    substats:
      'Crit Rate (8.7%), CRIT DMG (46.64%), Break Effect (69.96%), Speed (2.3)',
    relic_set_1: 'Genius of Brilliant Stars',
    relic_set_2: '',
    relic_set_3: 'Talia: Kingdom of Banditry',
    cone: 'on-the-fall-of-an-aeon',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 353007,
      damage_e1: 362815,
      damage_e2: 362815,
      damage_e3: 367845,
      damage_e4: 398134,
      damage_e5: 410248,
      damage_e6: 444050,
      skillPoints: 5.6,
      skillPoints_e1: 5.6,
      skillPoints_e2: 5.6,
      skillPoints_e3: 5.6,
      skillPoints_e4: 5.6,
      skillPoints_e5: 5.6,
      skillPoints_e6: 5.6
    },
    aoe_dmg: {
      damage: 418381,
      damage_e1: 445678,
      damage_e2: 445678,
      damage_e3: 463597,
      damage_e4: 512495,
      damage_e5: 541365,
      damage_e6: 619460,
      skillPoints: 5.6,
      skillPoints_e1: 5.6,
      skillPoints_e2: 5.6,
      skillPoints_e3: 5.6,
      skillPoints_e4: 5.6,
      skillPoints_e5: 5.6,
      skillPoints_e6: 5.6
    }
  },
  {
    id: 31,
    name: 'Dr. Ratio',
    slug: 'dr-ratio',
    element: 'imaginary',
    body: 'CRIT DMG',
    feet: 'Speed',
    sphere: 'Imaginary DMG',
    rope: 'ATK',
    substats: 'Crit Rate (35%), CRIT DMG (81.6%), Speed (6.9)',
    relic_set_1: 'Pioneer Diver of Dead Waters',
    relic_set_2: '',
    relic_set_3: 'Duran, Dynasty of Running Wolves',
    cone: 'cruising-in-the-stellar-sea',
    cone_s: '5',
    disclaimer:
      'The calculations assume you are hitting a debuffed target (no value on the debuff we just calculated that it is there) to activate the Crit Rate/Crit DMG trace, so you need to pair Dr. Ratio with someone who can provide the debuffs as he can not do it by himself.',
    single_dmg: {
      damage: 505146,
      damage_e1: 574860,
      damage_e2: 691349,
      damage_e3: 696352,
      damage_e4: 832384,
      damage_e5: 891412,
      damage_e6: 1241512,
      skillPoints: 7.4,
      skillPoints_e1: 7.4,
      skillPoints_e2: 7.4,
      skillPoints_e3: 7.4,
      skillPoints_e4: 7.4,
      skillPoints_e5: 7.4,
      skillPoints_e6: 7.4
    },
    aoe_dmg: {
      damage: 556528,
      damage_e1: 630285,
      damage_e2: 759314,
      damage_e3: 763965,
      damage_e4: 1015532,
      damage_e5: 1087201,
      damage_e6: 1516295,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 32,
    name: 'Misha',
    slug: 'misha',
    element: 'ice',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Ice DMG',
    rope: 'ATK',
    substats: 'Crit Rate (23.3%), CRIT DMG (93.3%), SPD (13.8)',
    relic_set_1: 'Scholar Lost in Erudition',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'on-the-fall-of-an-aeon',
    cone_s: '5',
    disclaimer: 'Assumes allies consume 6 Skill Points per rotation.',
    single_dmg: {
      damage: 221806,
      damage_e1: 232594,
      damage_e2: 254363,
      damage_e3: 265362,
      damage_e4: 278339,
      damage_e5: 290521,
      damage_e6: 315397,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    },
    aoe_dmg: {
      damage: 310920,
      damage_e1: 343284,
      damage_e2: 375412,
      damage_e3: 388378,
      damage_e4: 403715,
      damage_e5: 425642,
      damage_e6: 455040,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 33,
    name: 'Black Swan',
    slug: 'black-swan',
    element: 'wind',
    body: 'Effect Hit Rate',
    feet: 'Speed',
    sphere: 'Wind DMG',
    rope: 'ATK',
    substats: 'ATK (23.3%), EHR (54.4%), SPD (9.2)',
    relic_set_1: 'Prisoner in Deep Confinement',
    relic_set_2: '',
    relic_set_3: 'Pan-Cosmic Commercial Enterprise',
    cone: 'its-showtime',
    cone_s: '5',
    disclaimer:
      'Assumes allies detonate Arcana 3 times per rotation (the detonations are not included in the damage as this is only used to inflict Arcana stacks on enemies).',
    single_dmg: {
      damage: 339431,
      damage_e1: 424289,
      damage_e2: 424289,
      damage_e3: 469588,
      damage_e4: 469588,
      damage_e5: 475037,
      damage_e6: 633490,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    },
    aoe_dmg: {
      damage: 1328183,
      damage_e1: 1660229,
      damage_e2: 1660229,
      damage_e3: 1836344,
      damage_e4: 1947486,
      damage_e5: 1973970,
      damage_e6: 2391046,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 34,
    name: 'Qingque',
    slug: 'qingque',
    element: 'quantum',
    body: 'CRIT Rate',
    feet: 'ATK%',
    sphere: 'Quantum DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (35%), CRIT DMG (93.3%)',
    relic_set_1: 'Genius of Brilliant Stars',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'today-is-another-peaceful-day',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 201882,
      damage_e1: 202571,
      damage_e2: 209980,
      damage_e3: 216789,
      damage_e4: 289742,
      damage_e5: 321769,
      damage_e6: 321769,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 4
    },
    aoe_dmg: {
      damage: 393736,
      damage_e1: 395804,
      damage_e2: 418031,
      damage_e3: 433922,
      damage_e4: 567670,
      damage_e5: 627131,
      damage_e6: 627131,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 4
    }
  },
  {
    id: 35,
    name: 'Welt',
    slug: 'welt',
    element: 'imaginary',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Imaginary DMG',
    rope: 'ATK',
    substats: 'CRIT Rate (17.5%), CRIT DMG (70%), EHR (42.77%), SPD (6.9)',
    relic_set_1: 'Pioneer Diver of Dead Waters',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'in-the-name-of-the-world',
    cone_s: '1',
    disclaimer: '',
    single_dmg: {
      damage: 218818,
      damage_e1: 234548,
      damage_e2: 253483,
      damage_e3: 266582,
      damage_e4: 266582,
      damage_e5: 278007,
      damage_e6: 392282,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    },
    aoe_dmg: {
      damage: 279643,
      damage_e1: 295372,
      damage_e2: 334583,
      damage_e3: 347681,
      damage_e4: 347681,
      damage_e5: 366315,
      damage_e6: 524744,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 36,
    name: 'March 7th - The Hunt',
    slug: 'march-7th-swordmaster',
    element: 'imaginary',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Imaginary DMG',
    rope: 'ATK',
    substats: 'CRIT Rate (29.2%), CRIT DMG (81.6%)',
    relic_set_1: 'Musketeer of Wild Wheat',
    relic_set_2: '',
    relic_set_3: 'Rutilant Arena',
    cone: 'cruising-in-the-stellar-sea',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 358494,
      damage_e1: 358494,
      damage_e2: 358494,
      damage_e3: 358494,
      damage_e4: 367285,
      damage_e5: 377717,
      damage_e6: 411957,
      skillPoints: 0,
      skillPoints_e1: 0,
      skillPoints_e2: 0,
      skillPoints_e3: 0,
      skillPoints_e4: 0,
      skillPoints_e5: 0,
      skillPoints_e6: 0
    },
    aoe_dmg: {
      damage: 355147,
      damage_e1: 374108,
      damage_e2: 424798,
      damage_e3: 440588,
      damage_e4: 440588,
      damage_e5: 465052,
      damage_e6: 666686,
      skillPoints: 12,
      skillPoints_e1: 12,
      skillPoints_e2: 12,
      skillPoints_e3: 12,
      skillPoints_e4: 12,
      skillPoints_e5: 12,
      skillPoints_e6: 12
    }
  },
  {
    id: 37,
    name: 'Moze',
    slug: 'moze',
    element: 'lightning',
    body: 'CRIT Rate',
    feet: 'ATK%',
    sphere: 'Lightning DMG',
    rope: 'ATK%',
    substats: 'CRIT Rate (32.1%), CRIT DMG (93.3%)',
    relic_set_1: 'Pioneer Diver of Dead Waters',
    relic_set_2: '',
    relic_set_3: 'Duran, Dynasty of Running Wolves',
    cone: 'cruising-in-the-stellar-sea',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 298590,
      damage_e1: 319349,
      damage_e2: 358337,
      damage_e3: 389516,
      damage_e4: 459230,
      damage_e5: 463344,
      damage_e6: 499267,
      skillPoints: 0,
      skillPoints_e1: 0,
      skillPoints_e2: 0,
      skillPoints_e3: 0,
      skillPoints_e4: 0,
      skillPoints_e5: 0,
      skillPoints_e6: 0
    },
    aoe_dmg: {
      damage: 724871,
      damage_e1: 779280,
      damage_e2: 862924,
      damage_e3: 927688,
      damage_e4: 988228,
      damage_e5: 1061518,
      damage_e6: 1290386,
      skillPoints: 6,
      skillPoints_e1: 6,
      skillPoints_e2: 6,
      skillPoints_e3: 6,
      skillPoints_e4: 6,
      skillPoints_e5: 6,
      skillPoints_e6: 5
    }
  },
  {
    id: 38,
    name: 'Gallagher',
    slug: 'gallagher',
    element: 'fire',
    body: 'Outgoing Healing',
    feet: 'Speed',
    sphere: 'HP%',
    rope: 'Energy Regen Rate',
    substats: 'Speed (32), Break Effect (58%)',
    relic_set_1: 'Iron Cavalry Against the Scourge',
    relic_set_2: '',
    relic_set_3: 'Forge of the Kalpagni Lantern',
    cone: 'quid-pro-quo',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 353010,
      damage_e1: 353010,
      damage_e2: 353010,
      damage_e3: 354540,
      damage_e4: 367150,
      damage_e5: 371275,
      damage_e6: 466419,
      skillPoints: -18,
      skillPoints_e1: -18,
      skillPoints_e2: -18,
      skillPoints_e3: -18,
      skillPoints_e4: -18,
      skillPoints_e5: -18,
      skillPoints_e6: -18
    },
    aoe_dmg: {
      damage: 465585,
      damage_e1: 465585,
      damage_e2: 465585,
      damage_e3: 467114,
      damage_e4: 483665,
      damage_e5: 489784,
      damage_e6: 614660,
      skillPoints: -18,
      skillPoints_e1: -18,
      skillPoints_e2: -18,
      skillPoints_e3: -18,
      skillPoints_e4: -18,
      skillPoints_e5: -18,
      skillPoints_e6: -18
    }
  },
  {
    id: 39,
    name: 'Lingsha',
    slug: 'lingsha',
    element: 'fire',
    body: 'Outgoing Healing',
    feet: 'Speed',
    sphere: 'HP%',
    rope: 'Energy Regen Rate',
    substats: 'Speed (32), Break Effect (58%)',
    relic_set_1: 'Iron Cavalry Against the Scourge',
    relic_set_2: '',
    relic_set_3: 'Forge of the Kalpagni Lantern',
    cone: 'quid-pro-quo',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 597483,
      damage_e1: 961159,
      damage_e2: 1061533,
      damage_e3: 1075321,
      damage_e4: 1075321,
      damage_e5: 1076936,
      damage_e6: 2050642,
      skillPoints: -5,
      skillPoints_e1: -5,
      skillPoints_e2: -5,
      skillPoints_e3: -5,
      skillPoints_e4: -5,
      skillPoints_e5: -5,
      skillPoints_e6: -5
    },
    aoe_dmg: {
      damage: 1088175,
      damage_e1: 1744434,
      damage_e2: 1925255,
      damage_e3: 1951554,
      damage_e4: 1951554,
      damage_e5: 1954017,
      damage_e6: 3014306,
      skillPoints: -5,
      skillPoints_e1: -5,
      skillPoints_e2: -5,
      skillPoints_e3: -5,
      skillPoints_e4: -5,
      skillPoints_e5: -5,
      skillPoints_e6: -5
    }
  },
  {
    id: 40,
    name: 'Rappa',
    slug: 'rappa',
    element: 'imaginary',
    body: 'ATK%',
    feet: 'Speed',
    sphere: 'ATK%',
    rope: 'Break Effect',
    substats: 'Speed (16), Break Effect (64%), ATK (23.3%)',
    relic_set_1: 'Iron Cavalry Against the Scourge',
    relic_set_2: '',
    relic_set_3: 'Talia: Kingdom of Banditry',
    cone: 'after-the-charmony-fall',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 1646045,
      damage_e1: 1810861,
      damage_e2: 2332483,
      damage_e3: 2356784,
      damage_e4: 2529887,
      damage_e5: 2554844,
      damage_e6: 3490877,
      skillPoints: 4.4,
      skillPoints_e1: 4.4,
      skillPoints_e2: 4.4,
      skillPoints_e3: 4.4,
      skillPoints_e4: 4.7,
      skillPoints_e5: 4.7,
      skillPoints_e6: 4.7
    },
    aoe_dmg: {
      damage: 3943112,
      damage_e1: 4334423,
      damage_e2: 4808626,
      damage_e3: 4906622,
      damage_e4: 5265802,
      damage_e5: 5318433,
      damage_e6: 8024812,
      skillPoints: 4.4,
      skillPoints_e1: 4.4,
      skillPoints_e2: 4.4,
      skillPoints_e3: 4.4,
      skillPoints_e4: 4.7,
      skillPoints_e5: 4.7,
      skillPoints_e6: 4.7
    }
  },
  {
    id: 41,
    name: 'Acheron',
    slug: 'acheron',
    element: 'lightning',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'ATK%',
    rope: 'ATK%',
    substats: 'CRIT Rate (35%), CRIT DMG (81.6%), Speed (9.2)',
    relic_set_1: 'Pioneer Diver of Dead Waters',
    relic_set_2: '',
    relic_set_3: 'Izumo Gensei and Takama Divine Realm',
    cone: 'good-night-and-sleep-well',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 701610,
      damage_e1: 784173,
      damage_e2: 865630,
      damage_e3: 908010,
      damage_e4: 963860,
      damage_e5: 997414,
      damage_e6: 1191414,
      skillPoints: 7.4,
      skillPoints_e1: 7.4,
      skillPoints_e2: 7.4,
      skillPoints_e3: 7.4,
      skillPoints_e4: 7.4,
      skillPoints_e5: 7.4,
      skillPoints_e6: 7.4
    },
    aoe_dmg: {
      damage: 1144373,
      damage_e1: 1299252,
      damage_e2: 1485646,
      damage_e3: 1568442,
      damage_e4: 1664534,
      damage_e5: 1707143,
      damage_e6: 2038279,
      skillPoints: 7.4,
      skillPoints_e1: 7.4,
      skillPoints_e2: 7.4,
      skillPoints_e3: 7.4,
      skillPoints_e4: 7.4,
      skillPoints_e5: 7.4,
      skillPoints_e6: 7.4
    }
  },
  {
    id: 42,
    name: 'Jade',
    slug: 'jade',
    element: 'quantum',
    body: 'CRIT Rate',
    feet: 'ATK%',
    sphere: 'Quantum DMG',
    rope: 'ATK%',
    substats: 'CRIT Rate (40.8%), CRIT DMG (81.6%)',
    relic_set_1: 'Genius of Brilliant Stars',
    relic_set_2: '',
    relic_set_3: 'Inert Salsotto',
    cone: 'today-is-another-peaceful-day',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 203078,
      damage_e1: 299160,
      damage_e2: 331878,
      damage_e3: 359296,
      damage_e4: 387120,
      damage_e5: 403307,
      damage_e6: 552157,
      skillPoints: 2,
      skillPoints_e1: 2,
      skillPoints_e2: 2,
      skillPoints_e3: 2,
      skillPoints_e4: 2,
      skillPoints_e5: 2,
      skillPoints_e6: 2
    },
    aoe_dmg: {
      damage: 828128,
      damage_e1: 888641,
      damage_e2: 985829,
      damage_e3: 1075607,
      damage_e4: 1158903,
      damage_e5: 1197968,
      damage_e6: 1647966,
      skillPoints: 2,
      skillPoints_e1: 2,
      skillPoints_e2: 2,
      skillPoints_e3: 2,
      skillPoints_e4: 2,
      skillPoints_e5: 2,
      skillPoints_e6: 2
    }
  },
  {
    id: 43,
    name: 'Firefly',
    slug: 'firefly',
    element: 'fire',
    body: 'ATK%',
    feet: 'Speed',
    sphere: 'ATK%',
    rope: 'Break Effect',
    substats: 'ATK (42.18%), Speed (11.5), Break Effect (81.6%)',
    relic_set_1: 'Iron Cavalry Against the Scourge',
    relic_set_2: '',
    relic_set_3: 'Forge of the Kalpagni Lantern',
    cone: 'on-the-fall-of-an-aeon',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 1457580,
      damage_e1: 1580007,
      damage_e2: 2244582,
      damage_e3: 2252246,
      damage_e4: 2252246,
      damage_e5: 2333605,
      damage_e6: 3590484,
      skillPoints: 13.2,
      skillPoints_e1: 4.4,
      skillPoints_e2: 4.4,
      skillPoints_e3: 4.4,
      skillPoints_e4: 4.4,
      skillPoints_e5: 4.4,
      skillPoints_e6: 4.4
    },
    aoe_dmg: {
      damage: 2795416,
      damage_e1: 3017785,
      damage_e2: 4225535,
      damage_e3: 4239206,
      damage_e4: 4239206,
      damage_e5: 4392558,
      damage_e6: 6703832,
      skillPoints: 13.2,
      skillPoints_e1: 4.4,
      skillPoints_e2: 4.4,
      skillPoints_e3: 4.4,
      skillPoints_e4: 4.4,
      skillPoints_e5: 4.4,
      skillPoints_e6: 4.4
    }
  },
  {
    id: 44,
    name: 'Yunli',
    slug: 'yunli',
    element: 'physical',
    body: 'CRIT Rate',
    feet: 'ATK%',
    sphere: 'Physical DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (40.8%), CRIT DMG (81.6%)',
    relic_set_1: 'The Wind-Soaring Valorous',
    relic_set_2: '',
    relic_set_3: 'Inert Salsotto',
    cone: 'on-the-fall-of-an-aeon',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 630501,
      damage_e1: 879662,
      damage_e2: 980236,
      damage_e3: 1076288,
      damage_e4: 1076288,
      damage_e5: 1086180,
      damage_e6: 1348345,
      skillPoints: 3,
      skillPoints_e1: 3,
      skillPoints_e2: 3,
      skillPoints_e3: 3,
      skillPoints_e4: 3,
      skillPoints_e5: 3,
      skillPoints_e6: 3
    },
    aoe_dmg: {
      damage: 1194885,
      damage_e1: 1538361,
      damage_e2: 1713040,
      damage_e3: 1843441,
      damage_e4: 1843441,
      damage_e5: 1872721,
      damage_e6: 2290931,
      skillPoints: 3,
      skillPoints_e1: 3,
      skillPoints_e2: 3,
      skillPoints_e3: 3,
      skillPoints_e4: 3,
      skillPoints_e5: 3,
      skillPoints_e6: 3
    }
  },
  {
    id: 45,
    name: 'Feixiao',
    slug: 'feixiao',
    element: 'wind',
    body: 'CRIT DMG',
    feet: 'ATK%',
    sphere: 'Wind DMG',
    rope: 'ATK%',
    substats: 'Crit Rate (46.7%), CRIT DMG (70%)',
    relic_set_1: 'The Wind-Soaring Valorous',
    relic_set_2: '',
    relic_set_3: 'Duran, Dynasty of Running Wolves',
    cone: 'cruising-in-the-stellar-sea',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 966347,
      damage_e1: 1215635,
      damage_e2: 1649034,
      damage_e3: 1766790,
      damage_e4: 1843775,
      damage_e5: 1920672,
      damage_e6: 2647888,
      skillPoints: 0,
      skillPoints_e1: 0,
      skillPoints_e2: 0,
      skillPoints_e3: 0,
      skillPoints_e4: 0,
      skillPoints_e5: 0,
      skillPoints_e6: 0
    },
    aoe_dmg: {
      damage: 1194885,
      damage_e1: 1538361,
      damage_e2: 1713040,
      damage_e3: 1843441,
      damage_e4: 1843441,
      damage_e5: 1872721,
      damage_e6: 2290931,
      skillPoints: 0,
      skillPoints_e1: 0,
      skillPoints_e2: 0,
      skillPoints_e3: 0,
      skillPoints_e4: 0,
      skillPoints_e5: 0,
      skillPoints_e6: 0
    }
  },
  {
    id: 46,
    name: 'Boothill',
    slug: 'boothill',
    element: 'physical',
    body: 'CRIT Rate',
    feet: 'Speed',
    sphere: 'Physical DMG',
    rope: 'Break Effect',
    substats: 'ATK (31.1%), Speed (18.4), Break Effect (81.6%)',
    relic_set_1: 'Thief of Shooting Meteor',
    relic_set_2: '',
    relic_set_3: 'Talia: Kingdom of Banditry',
    cone: 'shadowed-by-night',
    cone_s: '5',
    disclaimer: '',
    single_dmg: {
      damage: 1220297,
      damage_e1: 1340849,
      damage_e2: 1425120,
      damage_e3: 1438259,
      damage_e4: 1571022,
      damage_e5: 1700250,
      damage_e6: 2122817,
      skillPoints: 4.5,
      skillPoints_e1: 4.5,
      skillPoints_e2: 4.5,
      skillPoints_e3: 4.5,
      skillPoints_e4: 4.5,
      skillPoints_e5: 4.5,
      skillPoints_e6: 4.5
    },
    aoe_dmg: {
      damage: 2795416,
      damage_e1: 3017785,
      damage_e2: 4225535,
      damage_e3: 4239206,
      damage_e4: 4239206,
      damage_e5: 4392558,
      damage_e6: 6703832,
      skillPoints: 4.5,
      skillPoints_e1: 4.5,
      skillPoints_e2: 4.5,
      skillPoints_e3: 4.5,
      skillPoints_e4: 4.5,
      skillPoints_e5: 4.5,
      skillPoints_e6: 4.5
    }
  }
];
