import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const HSRGuidesGameModesPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Game modes</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_gamemodes.png"
            alt="Game modes"
          />
        </div>
        <div className="page-details">
          <h1>Game modes</h1>
          <h2>List of available game modes in Honkai: Star Rail.</h2>
          <p>
            Last updated: <strong>21/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Game modes" />
        <p>
          In addition to exploring the overworld and doing missions, Honkai:
          Star Rail also offers a number of other game modes for the player to
          enjoy as well.
        </p>
        <SectionHeader title="Resource/Boss Stages" />
        <p>
          Throughout the overworld in Honkai: Star Rail, you will encounter a
          number of dungeon type entrances that you can enter to farm resources
          and materials to progress your account. However, Trailblaze Power must
          be expended in order to obtain the rewards for completing these
          stages.
        </p>
        <p>The different stages are:</p>
        <h5>Calyx</h5>
        <StaticImage
          src="../../../images/starrail/generic/guide_calyx.jpg"
          alt="Guide"
        />
        <p>
          There are two types of Calyx:{' '}
          <strong>Calyx (Golden) and Calyx (Crimson)</strong>.
        </p>
        <p>
          Calyx (Golden) are your basic resource stages and provide Character
          EXP material, Light Cone EXP material, or Credits depending on which
          Calyx (Golden) stage you are farming. These Calyx also drop basic
          enemy materials based on the region that you are farming the Calyx
          (Golden) in. For example, if you are farming a Calyx (Golden) in
          Penacony, then you will obtain Penacony basic enemy materials.
        </p>
        <p>
          Calyx (Crimson) provide Character Trace and Light Cone Ascension
          materials. There are a number of different Calyx (Crimson) stages,
          each corresponding to one of the playable Paths. Each Character and
          Light Cone require a certain Trace material from one of these Calyx as
          an upgrade material. Make sure to check which one your Character and
          Light Cone need and farm the appropriate Calyx (Crimson) stage.
        </p>
        <p>It costs 10 Trailblaze Power to do one Calyx stage.</p>
        <p>
          You can queue up to 6 Calyx runs at a time. Essentially, this allows
          you to repeat the same Calyx stage without having to exit and
          re-enter. Each run will cost you 10 Trailblaze Power (60 Trailblaze
          Power total if you queue up 6 runs).
        </p>
        <h5>Stagnant Shadow</h5>
        <ul>
          <li>
            These stages reward Character ascension materials. Each stage drops
            a different ascension material, so make sure to check your Character
            page to see which one the Character that you are ascending needs.
          </li>
          <li>It costs 30 Trailblaze Power to do one Stagnant Shadow stage.</li>
        </ul>
        <h5>Cavern of Corrosion</h5>
        <ul>
          <li>These stages reward Relics.</li>
          <li>
            It costs 40 Trailblaze Power to do one Caven of Corrosion stage.
          </li>
        </ul>
        <h5>Echo of War</h5>
        <ul>
          <li>
            These are your weekly boss stages and reward Relics, Tracks of
            Destiny (Character skill level upgrade material), and Light Cones.
          </li>
          <li>
            You can only do 3 Echo of War runs every week. However, you can do
            the same Echo of War stage multiple times.
          </li>
          <li>It costs 30 Trailblaze Power to do one Echo of War stage.</li>
        </ul>
        <p>Here's a video showing the mode in action:</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="32LaKfdGve8" />
          </Col>
        </Row>
        <SectionHeader title="Forgotten Hall" />
        <p>
          Forgotten Hall is a game mode where the objective is to defeat a group
          of enemies in as few turns as possible. After completing a Forgotten
          Hall stage you will be awarded between 0 to 3 stars for that stage
          depending on how many turns it took you to defeat the enemies in that
          stage. The more stars that you are awarded, the better the rewards you
          receive.
        </p>
        <p>
          It is a more challenging game mode with the final stages being
          considered as end game content.
        </p>
        <p>
          Forgotten Hall is divided in two parts: Memory and Memory of Chaos.
        </p>
        <ul>
          <li>
            Memory consists of 15 stages and provides a one time reward of
            Stellar Jades upon completion.
          </li>
          <ul>
            <li>
              The later stages of Memory require the use of two teams of up to 4
              Characters.
            </li>
          </ul>
          <li>
            Memory of Chaos consists of 10 stages and reward Stellar Jades upon
            completion
          </li>
          <ul>
            <li>
              Memory of Chaos stages are more challenging than Memory stages.
            </li>
            <li>
              Memory of Chaos stages reset periodically, allowing the player to
              complete them again for additional Stellar Jades.
            </li>
          </ul>
        </ul>
        <p>Here's a video showing the mode in action:</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="ZvvGSK01_uc" />
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Pure Fiction" />
        <p>
          Pure Fiction is a wave clearing game mode consisting of 4 different
          stages where you assemble two different teams to fight respawning
          enemies with the goal of defeating as many enemies as possible within
          a time limit. You will receive a score rating based on how well both
          teams performed. This score will determine how many stars (0-3) and
          the rewards that you will receive for that stage. The more stars you
          have, the better the rewards.
        </p>
        <p>
          You can obtain Stellar Jades and other rewards for clearing Pure
          Fiction stages. These stages reset periodically, allowing you to play
          through them again for more Stellar Jades.
        </p>
        <SectionHeader title="Apocalyptic Shadow" />
        <p>
          Apocalyptic Shadow is a boss attack game mode in which you build two
          teams to take on two different bosses. The objective of this game mode
          is to defeat both bosses in as few turns as possible and there are 4
          difficulty levels for each boss. You will be awarded between 0 to 3
          stars for each difficulty level that you’ve completed which in turn
          determines the rewards that you will get. The more stars you have, the
          better the rewards.
        </p>
        <p>
          You can obtain Stellar Jades and other rewards for completing this
          game mode. Apocalyptic Shadow also reset periodically, allowing you to
          play it again for more Stellar Jades.
        </p>
        <SectionHeader title="Simulated Universe" />
        <StaticImage
          src="../../../images/starrail/generic/guide_sim.jpg"
          alt="Guide"
        />
        <p>
          Simulated Universe is a roguelike game mode where the player will
          select a team of four Characters to venture inside a simulated world
          to collect a random set of buffs that they can use to enhance their
          team and fight the enemies inside. The player will progress through a
          set of semi-randomized combat and non-combat stages with the goal of
          defeating the boss in the final stage.
        </p>
        <p>
          This game mode does not require the player to spend any Trailblaze
          Power to play it and there is no limit to how many times the player
          can start a run in the Simulated Universe. The player can however
          optionally spend Trailblaze Power to obtain Planetary Ornaments
          (Character equipment) while inside certain stages of the Simulated
          Universe.
        </p>
        <p>
          Players can also earn rewards such as Stellar Jades and Star Rail
          Passes on a weekly basis via this game mode.
        </p>
        <p>Learn more about this mode here:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Simulated Universe"
            link="/star-rail/guides/simulated-universe"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_sim.png"
                alt="Simulated Universe"
              />
            }
          />
        </Row>
        <SectionHeader title="Divergent Universe" />
        <p>
          Divergent Universe is a variant of the Simulated Universe and follows
          the same basic mechanics with some added twists to further emphasize
          the roguelike nature of the game mode.
        </p>
        <p>
          Like the Simulated Universe, this game mode will ask you to create a
          team of four Characters and use them to collect a random set of buffs
          while going through a set of semi-randomized combat and non-combat
          stages with the goal of defeating the boss in the final stage.
        </p>
        <p>
          One of the main differentiators between the Simulated Universe and
          Divergent Universe is the ability to quickly farm Planetary Ornaments
          in the Divergent Universe. After each Divergent Universe run, you have
          the option to save the set of buffs that you obtained during that run
          into a temporary save file, up to a maximum of 4 different save files.
          These save files last for one game version and will be deleted at the
          start of a new game version (typically every 6 weeks). Once you have
          at least one save file, you can use it to enter special Planetary
          Ornament farming stages in the Divergent Universe menu where you can
          farm for the Planetary Ornaments directly without having to play
          though a Simulated Universe run.{' '}
        </p>
        <p>
          Playing through the Divergent Universe will award you with rewards
          such as Stellar Jades and Star Rail Passes on a weekly basis. These
          weekly rewards are the same and are shared with the weekly Simulated
          Universe rewards, so you only need to play one of the two game modes
          to get the weekly rewards.
        </p>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesGameModesPage;

export const Head: React.FC = () => (
  <Seo
    title="Game modes | Honkai: Star Rail | Prydwen Institute"
    description="List of available game modes in Honkai: Star Rail."
  />
);
